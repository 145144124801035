export interface Contact {
  id: number;
  name: string;
  member_number: string;
  first_name: string;
  last_name: string;
}

export const newContact = (): Contact => ({
  id: 0,
  name: "",
  member_number: "",
  first_name: "",
  last_name: "",
});

export interface Member {
  id: number;
  member_number: string;
  first_name: string;
  last_name: string;
  name: string;

  accounting_number: string;
  employee: boolean;
  dob: Date;
  ssn: string;
  first_membership_date: Date;
  membership_from_date: Date;
  membership_to_date: Date;
  password: string;
  app_activated_at: Date;
  web_activated_at: Date;
  sex_id: number;

  emails: Email[];
  phones: Phone[];
  addresses: Address[];
}

export interface Email {
  id: number;
  name: string;
  primary: boolean;
}

export interface Phone {
  id: number;
  name: string;
  primary: boolean;
}

export interface Address {
  id: number;
  name: string;
  addressline1: string;
  addressline2: string;
  addressline3: string;
  postcode: string;
  postoffice: string;
  country_id: number;
  contact_address_id: number;
  primary: boolean;
}

export const newMember = (): Member => {
  let email = newEmail();
  email.primary = true;

  let phone = newPhone();
  phone.primary = true;

  let address = newAddress();
  address.primary = true;

  return {
    id: 0,
    member_number: "",
    first_name: "",
    last_name: "",
    name: "",

    accounting_number: "",
    employee: false,
    dob: null,
    ssn: "",
    first_membership_date: null,
    membership_from_date: null,
    membership_to_date: null,
    password: "",
    app_activated_at: null,
    web_activated_at: null,
    sex_id: 0,

    emails: [email],
    phones: [phone],
    addresses: [address],
  }
};

export const newEmail = (): Email => ({
  id: 0,
  name: "",
  primary: false,
});

export const newPhone = (): Phone => ({
  id: 0,
  name: "",
  primary: false,
});

export const newAddress = (): Address => ({
  id: 0,
  name: "",
  addressline1: "",
  addressline2: "",
  addressline3: "",
  postcode: "",
  postoffice: "",
  country_id: 0,
  contact_address_id: 0,
  primary: false,
});