import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import * as page from '../pages';
import * as admin from '../pages/admin';
import TopBarLayout from '../components/layouts/TopBarLayout';
import FullLayout from '../components/layouts/FullLayout';
import { authStore } from '../App';
import { createMenuitemHirarchy, Menuitem } from '../model/System';
import { AuthService } from '../services/AuthService';
import { getValidToken } from '../services/HttpService';
import { MenuitemStatus } from '../model/General';
import { Tree } from '../model';
import { DcpAuthProvider } from '../contexts/DcpAuthContext';
import * as poster from '../pages/public/poster/pagesNew';

type module = "" | "Admin" | "OIDC" | "Medlem" | "Timeføring" | "Plakat/Nyhetsbrev";

interface RoutePage {
  module: module; // A module is a group of pages that is included when it's owned by a tenant
  name?: string; // used when selecting from MenuSettings
  pathGroups?: string[]; // pages can be grouped under multiple Routes layers, this will help construct the full path when performing navigation
  path: string;
  page: JSX.Element;
  autoInclude?: boolean; // routes that are part of a module but does not have its own navigation menu
}

// DO NOT CHANGE PATH OF EXISTING PAGES
// if you do, remember to rename every occurance in sys_menuitem.url to mach the new name.
//
// all pages that require a valid access token from oidc
export const AuthRoutes: RoutePage[] = [
  { module: "", name: "Hjem", path: "/home", page: <page.Home /> },
  { module: "", name: "Profil", path: "/profile", page: <page.Profile /> },
  { module: "", name: "Bruker-innstillinger", path: "/Settings", page: <page.Settings /> },

  { module: "Admin", name: "Tabelloversikt", path: "/admin/table", page: <admin.TableOverview /> },
  { module: "Admin", name: "Medlems administrasjon", path: "/admin/manageMembers", page: <admin.ManageMembers /> },
  { module: "Admin", name: "Bruker administrasjon", path: "/admin/manageUsers", page: <admin.ManageUsers /> },
  { module: "Admin", name: "Side-innstillinger", path: "/admin/settings", page: <admin.AdminSettings /> },
  { module: "Admin", name: "Databasestatus", path: "/admin/databasestate", page: <admin.DatabaseState /> },

  { module: "Medlem", name: "Organisasjon", path: "/organisasjon", page: <page.Organisasjon /> },
  { module: "Medlem", name: "Medlem", path: "/medlem", page: <page.Medlem /> },
  { module: "Medlem", name: "Bedrift", path: "/bedrift", page: <page.Bedrift /> },
  { module: "Medlem", name: "Tillitsverv", path: "/tillitsverv", page: <page.Tillitsverv /> },
  { module: "Medlem", name: "Stipend", path: "/stipend", page: <page.Stipend /> },
  { module: "Medlem", name: "Tariff", path: "/tariff", page: <page.Tariff /> },
  { module: "Medlem", name: "Økonomi", path: "/økonomi", page: <page.Økonomi /> },
  { module: "Medlem", name: "Kommunikasjon", path: "/kommunikasjon", page: <page.Kommunikasjon /> },

  { module: "Timeføring", name: "Timeregistrering", path: "/hour/hourRegistration", page: <page.HourReg /> },
  { module: "Timeføring", name: "Mine timer", path: "/hour/myHours", page: <page.MyHours /> },
  { module: "Timeføring", name: "Aksepterte timer", path: "/hour/hourAccepted", page: <page.HourAccepted /> },
  { module: "Timeføring", name: "Historikk", path: "/hour/hourHistory", page: <page.HoursHistory /> },
  { module: "Timeføring", name: "Godkjente timer", path: "/hour/HourApproval", page: <page.HourApproval /> },
  { module: "Timeføring", name: "Oversikt", path: "/hour/HourOverview", page: <page.HourOverview /> },
  { module: "Timeføring", name: "Prosjektrapport", path: "/hour/ProjectReports", page: <page.ProjectReports /> },
  { module: "Timeføring", name: "Timeoverføring", path: "/hour/HourTransfer", page: <page.HourTransfer /> },
  { module: "Timeføring", name: "Synkroniser data", path: "/hour/HourSyncData", page: <page.HourSyncData /> },

  { module: "Plakat/Nyhetsbrev", name: "Plakat", pathGroups: ["/new/poster"], path: "/poster", page: <poster.PosterPoster /> },
  { module: "Plakat/Nyhetsbrev", name: "Nyhetsbrev", pathGroups: ["/new/poster"], path: "/newsletter", page: <poster.PosterNewsletter /> },
  { module: "Plakat/Nyhetsbrev", name: "Maler", pathGroups: ["/new/poster"], path: "/template", page: <poster.PosterTemplate /> },
  { module: "Plakat/Nyhetsbrev", pathGroups: ["/new/poster"], path: "/create-template/:pageType/:title", page: <poster.CreateTemplate />, autoInclude: true },
  { module: "Plakat/Nyhetsbrev", pathGroups: ["/new/poster"], path: "/create/:pageType/:title/:templateID", page: <poster.CreatePage />, autoInclude: true },
  { module: "Plakat/Nyhetsbrev", pathGroups: ["/new/poster"], path: "/edit/:type/:id", page: <poster.Edit />, autoInclude: true },
];

const checkForDuplicates = (items: RoutePage[]) => {
  const nameSet = new Set<string>();
  const pathSet = new Set<string>();

  for (const item of items) {
    if (item.name) {
      if (nameSet.has(item.name)) {
        throw new Error(`Duplicate name found in AuthRoutes: "${item.name}"`);
      }
    }
    if (pathSet.has(item.path)) {
      throw new Error(`Duplicate path found in AuthRoutes: "${item.path}"`);
    }

    nameSet.add(item.name);
    pathSet.add(item.path);
  }
};

checkForDuplicates(AuthRoutes);

export const createNavigationPath = (path: string): string => {
  const route = AuthRoutes.find(r => r.path === path);
  if (route) {
    if (route.pathGroups) {
      return route.pathGroups.join('') + route.path;
    }
    return route.path
  };
  return "/home";
}

export const AuthenticatedRoutes = () => {
  const { isLoggedin, initialAuthLoad, mainRoutes, profileRoutes } = authStore();
  const { authenticate, logout } = AuthService();
  const navigate = useNavigate();

  useEffect(() => {
    authenticate(true).then(success => {
      if (!success) {
        logout();
        // navigate("/", { replace: true }); // uncomment if '/' page don't automatically redirect to login
      }
    });
  }, []);

  useEffect(() => {
    const getMenuItems = async () => {
      let token = await getValidToken(false);

      if (!token) {
        authStore.setState({ mainRoutes: [], profileRoutes: [], mainRoutesTree: new Tree([]) });
        return;
      }

      // asume authenticated if token exisits
      const response = await fetch("/api/menuitems", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.ok) {
        let res = await response.json();
        const mainRoutes: Menuitem[] = res?.routes?.filter(route => route.status === MenuitemStatus.Main) ?? [];
        const profileRoutes: Menuitem[] = res?.routes?.filter(route => route.status === MenuitemStatus.Profile) ?? [];

        const mainRoutesTree = new Tree(mainRoutes);

        authStore.setState({
          mainRoutes: createMenuitemHirarchy(mainRoutes),
          profileRoutes: createMenuitemHirarchy(profileRoutes),
          mainRoutesTree: mainRoutesTree,
        });
      } else {
        authStore.setState({ mainRoutes: [], profileRoutes: [], mainRoutesTree: new Tree([]) });
      }

      authStore.setState({ initialAuthLoad: true })
    }

    getMenuItems();
  }, []);

  function constructAuthRoutes(menuitems: Menuitem[]): ReactElement[] {
    let availableRoutes: ReactElement[] = [];
    let posterRoutes: ReactElement[] = [];

    if (menuitems) {
      menuitems.sort((a, b) => a.priority - b.priority);

      menuitems.forEach(menuitem => {
        const RoutePage = AuthRoutes.find(r => r.path === menuitem.url);
        if (RoutePage) {
          if (RoutePage.module === "Plakat/Nyhetsbrev") {
            posterRoutes.push(<Route key={menuitem.id} path={menuitem.url} element={RoutePage.page} />)
          } else {
            availableRoutes.push(<Route key={menuitem.id} path={menuitem.url} element={RoutePage.page} />)
          }
        }

        if (menuitem.children.length > 0) {
          availableRoutes.push(...constructAuthRoutes(menuitem.children));
        }
      })
    }

    if (posterRoutes.length > 0) {
      // include auto routes
      const autoRoutes = AuthRoutes.filter(r => r.module === "Plakat/Nyhetsbrev").filter(r => r.autoInclude)
      let highestId = menuitems.reduce((max, item) => (item.id > max ? item.id : max), 0);
      autoRoutes.forEach(autoRoute => {
        highestId += 1;
        posterRoutes.push(<Route key={highestId} path={autoRoute.path} element={autoRoute.page} />)
      })

      // add poster routes under group
      availableRoutes.push(
        <Route key={highestId + 1} path="/new/poster/*" element={
          <DcpAuthProvider>
            <Routes>
              {posterRoutes}
              <Route path="/*" element={<page.NotFound />} />
            </Routes>
          </DcpAuthProvider>
        } />
      )
    }

    return availableRoutes;
  }

  if (!isLoggedin || !initialAuthLoad) {
    return null;
  }

  return (
    <>
      <TopBarLayout>
        <Routes>
          <Route path="/home" element={<page.Home />} />

          {constructAuthRoutes([...mainRoutes, ...profileRoutes])}

          {/* <Route path="/new/poster/*" element={
            <DcpAuthProvider>
              <Routes>
                <Route path="/poster" element={<poster.PosterPoster />} />
              </Routes>
            </DcpAuthProvider>
          } /> */}

          {/** @todo: on prod, replace these with <Route path="/*" element={<Navigate to='/' replace />} /> */}
          <Route path="/:path" element={<page.NotFound />} />
          <Route path="/*" element={<page.NotFound />} />
        </Routes>
      </TopBarLayout>
    </>
  );
};

const AdminRoutes = () => {
  const { userRoles } = authStore();
  return (
    <Routes>
      {userRoles.includes("admin") ? (
        <>
          <Route path="/table" element={<admin.TableOverview />} />
          <Route path="/manageMembers" element={<admin.ManageMembers />} />
          <Route path="/settings" element={<admin.AdminSettings />} />
          <Route path="/databasestate" element={<admin.DatabaseState />} />
          <Route path="/*" element={<Navigate to='table' replace />} />
        </>
      ) : (
        <Route path="/*" element={<page.MissingPermission />} />
      )}
    </Routes>
  );
};