import { GridColDef } from "@mui/x-data-grid";

export const highlightText = (text, searchText) => {
  if (!searchText) return text;

  const parts = String(text).split(new RegExp(`(${searchText})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === searchText.toLowerCase() ? (
      <span key={index} style={{ backgroundColor: 'orange' }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

// Add renderCell for columns to include highlighting
export const highlightedGridColumns = (columns: GridColDef<any, any, any>[], searchText: string, apiRef: any) => {
  return columns.map((column) => ({
    ...column,
    renderCell: (params) => {
      const value =
        column.valueGetter
          ? column.valueGetter(undefined as never, params.row, column, apiRef)
          : params.value;
  
      return <div>{highlightText(value, searchText)}</div>;
    },
  }));
}
