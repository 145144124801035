import { Box, Link } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  const { path } = useParams();

  return (
    <Box sx={{ height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <h3>404: Page not found</h3>
        {(path != null) ?
          <p>{path}</p> :
          null
        }
        <Link
          component="button"
          variant="body1"
          color="inherit"
          underline="always"
          onClick={() => navigate(-1)}
        >
          Gå tilbake
        </Link>
      </Box>
    </Box>
  )
}