import { Box, Button, Container, TextField, Typography, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { useApi } from '../../services/HttpService';

export const HourSyncData = () => {
  const theme = useTheme();
  const { api: syncDataApi, response: syncDataResponse, isLoading: syncDataIsLoading, error: syncDataError } = useApi();
  const { api: hideProjectApi, response: hideProjectResponse, isLoading: hideProjectIsLoading, error: hideProjectError } = useApi();
  const { api: showProjectApi, response: showProjectResponse, isLoading: showProjectIsLoading, error: showProjectError } = useApi();
  const [inputValue, setInputValue] = useState("");
  const [responseMessage, setResponseMessage] = useState("");


  const handleSyncData = () => {
    syncDataApi("/hour/SyncHourData", "POST", {})
      .then((res) => {
        if (res && res.statusCode === 200) {
          setResponseMessage(res.data);
        }else{
          setResponseMessage("")
        }
      });
  };

  const handleHide = (projNoInput: string) => {

    const projNo = parseInt(projNoInput, 10);

    hideProjectApi("/hour/HideProject", "POST", { "projectNo":projNo })
      .then((res) => {
        if (res && res.statusCode === 200) {
          setResponseMessage(res.data);
        }else{
          setResponseMessage("")
        }
      });
  };

  const handleShow = (projNoInput: string) => {
    const projNo = parseInt(projNoInput, 10);
    showProjectApi("/hour/ShowProject", "POST", { "projectNo": projNo })
      .then((res) => {
        if (res && res.statusCode === 200) {
          setResponseMessage(res.data); 
        }else{
          setResponseMessage("")
        }
      });
  };

  return (
    <Container maxWidth="sm">
      <Box textAlign="center" my={4}>
        <Button variant="contained" onClick={() => handleSyncData()}>Synkroniser data</Button>
        {syncDataIsLoading && <Typography>Loading...</Typography>}
        {syncDataError && <Typography color="error">Error fetching data</Typography>}

        <TextField
          label="Prosjektnummer"
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ }}
        />

        <Button variant="contained" sx={{ marginRight: "20px" }} color="primary" onClick={() => handleHide(inputValue)}>Skjul prosjekt</Button>
        {hideProjectIsLoading && <Typography>Laster...</Typography>}
        {hideProjectError && <Typography color="error">Error fetching data</Typography>}

        <Button variant="contained" color="primary" sx={{ }} onClick={() => handleShow(inputValue)}>Vis prosjekt</Button>
        {showProjectIsLoading && <Typography>Laster...</Typography>}
        {showProjectError && <Typography color="error">Error fetching data</Typography>}

        {responseMessage && (
          <Typography variant="body1" mt={2}>
            {responseMessage}
          </Typography>
        )}
      </Box>
    </Container>
  );
};