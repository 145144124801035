import { DataGrid, DataGridProps, GridColDef, GridEventListener, GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { styled, SxProps, Theme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useRef, useState } from 'react';

const StyledDataGrid = styled(DataGrid)(({ theme }: { theme: Theme }) => ({
  "& .MuiDataGrid-sortIcon, \
  & .MuiDataGrid-menuIconButton, \
  & .MuiDataGrid-columnSeparator": {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  "& .MuiDataGrid-columnHeader, \
  & .MuiDataGrid-scrollbarFiller, \
  & .MuiDataGrid-columnHeaders .MuiDataGrid-filler": {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

interface ScrollableDataGridProps {
  theme?: Theme;
  rows?: readonly GridValidRowModel[];
  columns: readonly GridColDef<GridValidRowModel, any, any>[];
  onPageRequest; // Custom prop for fetching data
  buffer?: number; // Number of rows from the bottom before fetching the next page
  pageSize?: number;
  loading?: boolean;
  dataGridProps?: DataGridProps;
  disableMultipleRowSelection?: boolean;
  checkboxSelection?: boolean;
  onRowSelectionModelChange?: (ids: any) => void;
}

const ScrollableDataGrid = (props: ScrollableDataGridProps) => {
  // const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const gridContainerRef = useRef(null);
  const dataGridRef = useRef(null);

  const {
    rows,
    columns,
    onPageRequest, // Custom prop for fetching data
    buffer = 5, // Number of rows from the bottom before fetching the next page
    pageSize = 20,
    ...dataGridProps // Spread other DataGrid props
  } = props;

  let theme: Theme = useTheme();
  if (props.theme) {
    theme = props.theme;
  }

  // Fetch data when the page changes
  // const fetchPage = useCallback(async () => {
  //   console.log("fetchPage")
  //   if (!hasMore) return;

  //   setLoading(true);
  //   const result = await onPageRequest(page, pageSize); // Use the custom prop for fetching data
  //   if (result.length === 0) {
  //     // setHasMore(false);
  //   } else {
  //     setRows((prevRows) => [...prevRows, ...result]);
  //   }
  //   setLoading(false);
  // }, [page, onPageRequest, pageSize, hasMore]);

  // useEffect(() => {
  //   fetchPage();
  // }, [fetchPage]);

  // const handleScroll = ({ top, bottom }) => {
  //   // Trigger next page load if we're near the bottom
  //   const buffer = 200; // Buffer zone in pixels
  //   const isNearBottom = bottom < buffer;

  //   if (isNearBottom) {
  //     setPage((prevPage) => prevPage + 1);
  //   }
  // };

  const handleScroll = (event) => {
    console.log("scroll", event)
    // const container = event.target;
    // const { scrollTop, scrollHeight, clientHeight } = container;
    // console.log("scrollTop", scrollTop, "scrollHeight", scrollHeight, "clientHeight", clientHeight);

    // // Trigger next page load if user scrolls near the bottom
    // const isNearBottom = scrollHeight - scrollTop - clientHeight < 200; // Buffer zone: 200px
    // if (isNearBottom) {
    //   setPage((prevPage) => prevPage + 1);
    // }
  };

  return (
    <Paper elevation={4} sx={{ height: 400, overflowY: "auto" }}>
      <ThemeProvider theme={theme}>
        <StyledDataGrid
          sx={{ border: 0 }}
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          paginationMode="server"

          hideFooter
          density='compact'
          rowHeight={35}

          loading={loading}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'linear-progress',
            },
          }}

          {...dataGridProps}
        />
      </ThemeProvider>
    </Paper>
  );
}

export default ScrollableDataGrid