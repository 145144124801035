import { FormEvent, useCallback, useState } from "react";
import { IconButton, InputBase, Paper, Tooltip, useTheme } from "@mui/material";
import { ClickableIcon } from "./Icon";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface SearchBoxProps {
  handleSearch: (query: string) => void;
  useDebounce?: boolean; // Flag to enable/disable debounce
  debounceMs?: number;   // Debounce delay in milliseconds
}

export function SearchBox(props: SearchBoxProps) {
  const { handleSearch, useDebounce = false, debounceMs = 500 } = props;
  const [searchText, setSearchText] = useState("");

  const notifyParent = useCallback(
    useDebounce ? debounce((query: string) => {
      if (handleSearch) {
        handleSearch(query);
      }
    }, debounceMs) : (query: string) => {
      if (handleSearch) {
        handleSearch(query);
      }
    }, [handleSearch, useDebounce, debounceMs]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchText(newValue);
    notifyParent(newValue);
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch(searchText);
  }

  const clearInput = () => {
    setSearchText('');
    if (handleSearch) {
      handleSearch('');
    }
  };

  return (
    <Paper
      component="form"
      onSubmit={submit}
      sx={{
        p: '2px 4px',
        display: 'flex',
        flexGrow: { sx: 1, md: 0 },
        alignItems: 'center',
        width: { xs: "100%", md: 400 },
        border: '2px solid transparent',
        borderRadius: '5px',
        transition: 'border-color 0.1s ease-in-out',
        '&:focus-within': {
          borderColor: '#1976d2',
        },
      }}
    >
      <IconButton title="søk" type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: '4px', mr: '4px', flexGrow: 1 }}
        placeholder="Søk..."
        inputProps={{ 'aria-label': 'search' }}
        value={searchText}
        onChange={handleInputChange}
      />
      <IconButton title="fjern" sx={{ p: '10px' }} aria-label="remove searech text" onClick={() => clearInput()} disabled={searchText.length === 0}>
        <ClearIcon />
      </IconButton>
    </Paper>
  )
}

function debounce<T extends (...args: any[]) => void>(func: T, wait: number): T {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return function (...args: Parameters<T>) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), wait);
  } as T;
}