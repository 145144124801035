import { Alert, Box, Button, CircularProgress, createTheme, Paper, styled, TextField, Typography, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useApi } from '../../../services/HttpService';
import { HexColorPicker } from "react-colorful";
import { themeStore } from '../../../components/themes/BaseTheme';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FallbackImage from '../../../components/file/FallbackImage';
import { useMultipartApi } from '../../../services/HttpService';
import { InfoArea } from '../../../components/ui/InfoArea';

interface Settings {
  name: string;
  displayName: string;
  logo: string;
  primary: string;
  secondary: string;
  success: string;
  loginBackground: string;
  loginText1: string;
  loginText2: string;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const PageSettings = () => {
  const theme = useTheme();
  const { api, error, isLoading } = useApi();
  const { multipartApi, mError } = useMultipartApi();
  const [settings, setSettings] = useState<Settings>({} as Settings);
  const [oldSettings, setOldSettings] = useState<Settings>({} as Settings);
  const [uploadedLogo, setUploadedLogo] = useState<string>('/api/static/logo');
  const [logo, setLogo] = useState<File | null>(null); // uploaded logo file
  const [loginBackground, setLoginBackground] = useState<File | null>(null); // uploaded login background file
  const [backgroundStyle, setBackgroundStyle] = useState({});

  useEffect(() => {
    const bgdStyle = loginBackground ? {
        // background: `url(${URL.createObjectURL(loginBackground)}) no-repeat center center/cover`,
        background: `url(${URL.createObjectURL(loginBackground)}) no-repeat left center / auto 100%`,
      } : {
        background: `url('/api/static/public/${settings.loginBackground}') no-repeat left center / auto 100%`,
      };

    setBackgroundStyle(bgdStyle);
  }, [settings]);
  

  const getTenantSettings = () => {
    api("/tenantSettings", "GET").then((res) => {
      if (res) {
        if (res.statusCode === 200) {
          let tenantSettings: Settings = {
            name: res.name,
            displayName: res.displayName,
            logo: res.logo,
            primary: theme.palette.primary.main,
            secondary: theme.palette.secondary.main,
            success: theme.palette.success.main,
            loginBackground: res.loginBackground,
            loginText1: res.loginText1,
            loginText2: res.loginText2,
          }
          setSettings(tenantSettings);
          setOldSettings(tenantSettings);
        }
      }
    });
  }

  useEffect(() => {
    getTenantSettings();
  }, []);

  const defaultColors = () => {
    let newColors = { ...settings };
    let defaultTheme = createTheme({});
    newColors.primary = defaultTheme.palette.primary.main;
    newColors.secondary = defaultTheme.palette.secondary.main;
    newColors.success = defaultTheme.palette.success.main;
    themeStore.getState().updateColors(newColors.primary, newColors.secondary, newColors.success);
    setSettings(newColors);
  }

  const reset = () => {
    setSettings({ ...oldSettings });
    setUploadedLogo('/api/static/logo');
    setLogo(null);
    setLoginBackground(null);
    themeStore.getState().updateColors(oldSettings.primary, oldSettings.secondary, oldSettings.success);
  }

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setLogo(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedLogo(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleBackgroundUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setLoginBackground(selectedFile);
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleColorChange = (value, colorName) => {
    let newColors = { ...settings, [colorName]: value };
    setSettings(newColors);
    themeStore.getState().updateColors(newColors.primary, newColors.secondary, newColors.success);
  }

  const saveSettings = () => {
    let reloadSettings = false;
    if (logo) {
      const formData = new FormData();
      formData.append("logo", logo);
      multipartApi("/setLogo", formData).then(res => {
        if (res && res.statusCode === 200) {
          setLogo(null);
          reloadSettings = true;
        }
      });
    }

    if (loginBackground) {
      const formData = new FormData();
      formData.append("loginBackground", loginBackground);
      multipartApi("/setLoginBackground", formData).then(res => {
        if (res && res.statusCode === 200) {
          setLoginBackground(null);
          reloadSettings = true;
        }
      });
    }

    const data = {
      name: settings.name,
      displayname: settings.displayName,
      logo: settings.logo,
      colorpalette: {
        primary: settings.primary,
        secondary: settings.secondary,
        success: settings.success,
      },
      login: {
        text1: settings.loginText1,
        text2: settings.loginText2,
      }
    }
    api("/setInfo", "POST", data).then((res) => {
      if (res && res.statusCode === 200) {
        reloadSettings = true;
      }
    }).finally(() => {
      if (reloadSettings) {
        setTimeout(() => { // @todo: fix wait on server side instead!
          getTenantSettings();
        }, 1000)
      }
    });
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
      <Typography variant='h5' sx={{ mt: 2 }}>Generelle innstillinger</Typography>
      <InfoArea title='Informasjon'>
        <TextField
          label="Navn"
          value={settings.name || ''}
          disabled
          variant="standard"
          InputLabelProps={{
            shrink: !!settings.name,
          }}
        />
        <TextField
          label="Visningsnavn"
          value={settings.displayName || ''}
          onChange={(e) => setSettings({ ...settings, displayName: e.target.value })}
          variant="standard"
          InputLabelProps={{
            shrink: !!settings.displayName,
          }}
        />
      </InfoArea>
      <InfoArea title='Logo'>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
          <Box>
            <Typography variant='body1'>256 x 256</Typography>
            <FallbackImage
              containerStyle={{ border: '1px solid black', width: '256px', height: '256px' }}
              primarySrc={uploadedLogo}
              secondarySrc="/default/logo.png"
              altText="logo"
              width='256'
              height='256'
            />
          </Box>
          <Box>
            <Typography variant='body1'>128 x 128</Typography>
            <FallbackImage
              containerStyle={{ border: '1px solid black', width: '128px', height: '128px' }}
              primarySrc={uploadedLogo}
              secondarySrc="/default/logo.png"
              altText="logo"
              width='128'
              height='128'
            />
          </Box>
          <Box>
            <Typography variant='body1'>64 x 64</Typography>
            <FallbackImage
              containerStyle={{ border: '1px solid black', width: '64px', height: '64px' }}
              primarySrc={uploadedLogo}
              secondarySrc="/default/logo.png"
              altText="logo"
              width='64'
              height='64'
            />
          </Box>
          <Box>
            <Typography variant='body1'>32 x 32</Typography>
            <FallbackImage
              containerStyle={{ border: '1px solid black', width: '32px', height: '32px' }}
              primarySrc={uploadedLogo}
              secondarySrc="/default/logo.png"
              altText="logo"
              width='32'
              height='32'
            />
          </Box>
        </Box>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Last opp logo
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={(event) => handleLogoUpload(event)}
          />
        </Button>
      </InfoArea>
      <InfoArea title='Sidefarger'>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 6 }}>
          <Box>
            <TextField
              label="Primærfarge"
              value={settings.primary || ''}
              onChange={(e) => handleColorChange(e.target.value, 'primary')}
              variant="standard"
              InputLabelProps={{
                shrink: !!settings.primary,
              }}
              sx={{ mb: 1 }}
            />
            <HexColorPicker color={settings.primary} onChange={(value) => handleColorChange(value, 'primary')} />
          </Box>
          <Box>
            <TextField
              label="Sekundærfarge"
              value={settings.secondary || ''}
              onChange={(e) => handleColorChange(e.target.value, 'secondary')}
              variant="standard"
              InputLabelProps={{
                shrink: !!settings.secondary,
              }}
              sx={{ mb: 1 }}
            />
            <HexColorPicker color={settings.secondary} onChange={(value) => handleColorChange(value, 'secondary')} />
          </Box>
          <Box>
            <TextField
              label="Suksessfarge"
              value={settings.success || ''}
              onChange={(e) => handleColorChange(e.target.value, 'success')}
              variant="standard"
              InputLabelProps={{
                shrink: !!settings.success,
              }}
              sx={{ mb: 1 }}
            />
            <HexColorPicker color={settings.success} onChange={(value) => handleColorChange(value, 'success')} />
          </Box>
        </Box>
      </InfoArea>
      <InfoArea title='Innlogging'>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
          <Box>
            <Typography variant='body1'>Desktop (16:9)</Typography>
            <Box sx={{ border: '1px solid black', width: '267px', height: '150px' }}>
              <div style={{ ...backgroundStyle, width: "100%", height: "100%" }}></div>
            </Box>
          </Box>
          <Box>
            <Typography variant='body1'>Tablet (3:2)</Typography>
            <Box sx={{ border: '1px solid black', width: '225px', height: '150px' }}>
              <div style={{ ...backgroundStyle, width: "100%", height: "100%" }}></div>
            </Box>
          </Box>
          <Box>
            <Typography variant='body1'>Telefon (9:19.5)</Typography>
            <Box sx={{ border: '1px solid black', width: '69px', height: '150px' }}>
              <div style={{ ...backgroundStyle, width: "100%", height: "100%" }}></div>
            </Box>
          </Box>
        </Box>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Last opp logg inn bakgrunnsbilde
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={(event) => handleBackgroundUpload(event)}
          />
        </Button>
        <TextField
          label="Tekst før brukernavn/passord"
          value={settings.loginText1 || ''}
          onChange={(e) => setSettings({ ...settings, loginText1: e.target.value })}
          variant="filled"
          multiline
          rows={4}
          InputLabelProps={{
            shrink: !!settings.loginText1,
          }}
        />
        <TextField
          label="Tekst etter brukernavn/passord"
          value={settings.loginText2 || ''}
          onChange={(e) => setSettings({ ...settings, loginText2: e.target.value })}
          variant="filled"
          multiline
          rows={4}
          InputLabelProps={{
            shrink: !!settings.loginText2,
          }}
        />
      </InfoArea>

      {error && <Alert severity="error">{error}</Alert>}
      {mError && <Alert severity="error">{mError}</Alert>}

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='contained' color='info' onClick={defaultColors}>Standard</Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
          <Button variant='contained' color='darkgrey' onClick={reset}>Tilbakestill</Button>
          <Button
            variant='contained'
            color='info'
            onClick={saveSettings}
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={22} />}
          >
            Lagre
          </Button>
        </Box>
      </Box>
    </Box>
  )
}