import { Contact, newContact, Organization, newOrganization, Company } from ".";

export interface Membership {
  id: number;
  name: string;
  status: number;
  contact_id: number;
  organization_id: number;
  member_type_id: number;
  company_id: number;
  from_date?: Date;
  to_date?: Date;

  contact?: Contact;
  organization?: Organization;
  member_type?: MemberType;
  company?: Company;
}

export const newMembership = (): Membership => {
  // let organization = newOrganization();
  // let contact = newContact();
  // let MemberType = newMemberType();

  return {
    id: 0,
    name: "",
    status: 0,
    contact_id: 0,
    organization_id: 0,
    member_type_id: 0,
    company_id: 0,

    // contact: contact,
    // organization: organization,
    // membership_type: MemberType,
  }
};

export interface MemberType {
  id: number;
  name: string;
  status: number;
}

export const newMemberType = (): MemberType => {
  return {
    id: 0,
    name: "",
    status: 0,
  }
};
