import { Alert, Box, Button, CircularProgress, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState, useRef } from 'react';
import { useApi } from '../../../../services/HttpService';
import { InfoArea } from '../../../../components/ui/InfoArea';
import ClearIcon from '@mui/icons-material/Clear';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Member, Membership, MemberType, newMembership, Organization, Company } from '../../../../model';
import { MembershipForm, MembershipFormRef } from './MembershipForm';
import { StickyAlert } from '../../../../components/ui/StickyAlert';
import { CenterSpinner } from '../../../../components/ui/CenterSpinner';
import { isNowBetweenDates, dayjsService } from '../../../../services/dayjsService';
import { ClipText } from '../../../../components/ui/ClipText';
import { ClickableIcon } from '../../../../components/ui/Icon';

interface MembershipOverviewProps {
  member: Member;
}

export const MembershipOverview = (props: MembershipOverviewProps) => {
  const theme = useTheme();
  const { api, error, isLoading } = useApi();
  const addMembershipRef = useRef<MembershipFormRef>(null);
  const [member, setMember] = useState<Member>({} as Member);
  const [membership, setMembership] = useState<Membership>(newMembership());
  const [memberships, setMemberships] = useState<Membership[]>([]);
  const [memberTypes, setMemberTypes] = useState<MemberType[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    setMember(props.member);
    getMemberships(props.member.id);
  }, [props.member]);

  useEffect(() => {
    getOrganizations();
    getMemberTypes();
    getCompanies();
  }, []);

  const getMemberships = (contact_id) => {
    api("/manage/contact/getMemberships", "POST", { contact_id: contact_id }).then(res => {
      if (res?.statusCode === 200) {
        setMemberships(res.memberships);
      }
    })
  }

  const getOrganizations = () => {
    api("/getOrganizations", "POST").then(res => {
      if (res?.statusCode === 200) {
        setOrganizations(res.organizations);
      }
    })
  }

  const getMemberTypes = () => {
    api("/getMemberTypes", "POST").then(res => {
      if (res?.statusCode === 200) {
        setMemberTypes(res.memberTypes);
      }
    })
  }

  const getCompanies = () => {
    api("/getCompanies", "POST").then(res => {
      if (res?.statusCode === 200) {
        setCompanies(res.companies);
      }
    })
  }

  const cancel = () => {
    setMembership(newMembership());
  }

  const save = () => {
    const membership = addMembershipRef.current.getMembership();
    console.log("submitting", membership, "valid", addMembershipRef.current.valid());

    if (addMembershipRef.current.valid()) {
      api("/manage/member/postMembership", "POST", { ...membership }).then(res => {
        if (res?.statusCode === 200) {
          // setMemberships(res.memberships);
          getMemberships(member.id);
        }
      });
    }
  }

  return (
    <>
      <Box sx={{ width: { xs: 512, md: 768 }, p: 2, margin: "0 auto" }}>
        <StickyAlert sx={{ width: { xs: 512, md: 768 } }} severity="error" message={error} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
        <Paper elevation={6} sx={{ width: '100%', height: 'fit-content', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant='h5' sx={{ mt: 2, textDecoration: 'underline' }}>{membership?.id > 0 ? "Rediger" : "Opprett"} medlemskap</Typography>
            <MembershipForm membership={membership} memberTypes={memberTypes} organizations={organizations} companies={companies} ref={addMembershipRef} />

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <Button variant='contained' color='info' onClick={setDefault}>Default</Button> */}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                <Button variant='contained' color='darkgrey' onClick={cancel}>Avbryt</Button>
                <Button
                  variant='contained'
                  color='info'
                  onClick={save}
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={22} />}
                >
                  {membership.id > 0 ? "Rediger" : "Opprett"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>

        <Paper elevation={6} sx={{ width: '100%', height: 'fit-content', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
            <Typography variant='h5' sx={{ mt: 2 }}>Medlemskap</Typography>

            <CenterSpinner loading={isLoading}>
              {memberships.length === 0 ? (
                <Alert severity="info">Denne kontakten har ingen medlemskp</Alert>
              ) : (
                <>
                  {memberships?.map((membership, index) => {
                    const isActive = isNowBetweenDates(membership.from_date, membership.to_date);
                    const org = membership.organization
                      ? membership.organization.name
                      : organizations.find(item => item.id === membership.organization_id)?.name;
                    const type = membership.member_type
                      ? membership.member_type.name
                      : memberTypes.find(item => item.id === membership.member_type_id)?.name;

                    return (
                      <Paper key={index} elevation={2} sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 1 }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                            <Tooltip title={isActive ? 'Aktiv' : 'Inaktiv'}>
                              <Typography variant='body1' sx={{ width: 20, backgroundColor: isActive ? theme.palette.success.main : theme.palette.error.main, textAlign: 'center' }}>{isActive ? 'A' : 'In'}</Typography>
                            </Tooltip>
                            <ClipText width={150}>{org}</ClipText>
                            <ClipText width={200} sx={{ backgroundColor: theme.palette.mode === "dark" ? "#3B3B3B" : "#dbdbdb", p: 0.5, borderRadius: 2, display: "inline-flex" }}>{type}</ClipText>
                            <ClipText width={200}>{`${dayjsService.toDateDisplayString(membership.from_date)} - ${dayjsService.toDateDisplayString(membership.to_date)}`}</ClipText>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', gap: 1 }}>
                            <ClickableIcon title="Rediger" onClick={() => setMembership(membership)}>
                              <ManageAccountsIcon />
                            </ClickableIcon>
                            <ClickableIcon title="Avslutt medlemskap" onClick={() => { }}>
                              <ClearIcon sx={{ color: theme.palette.error.main }} />
                            </ClickableIcon>
                          </Box>
                        </Box>
                      </Paper>
                    )
                  })}
                </>
              )}
            </CenterSpinner>

          </Box>
        </Paper>
      </Box>
    </>
  )
}