import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { FocusEventHandler, forwardRef, ReactNode, useCallback } from 'react';
import { TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

interface CustomPhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  autocomplete?: boolean;
  helperText?: ReactNode;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = (props: CustomPhoneInputProps) => {
  const theme = useTheme();
  const { value, onChange, error = false, autocomplete = true, helperText = undefined, onBlur = undefined } = props;

  // using useCallback to keep input mounted, otherwise it will loose focus when typing
  const CustomInput = useCallback(
    forwardRef(({ value, onChange, error, helperText, onBlur, ...rest }: any, ref) => (
      <TextField
        {...rest}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        inputRef={ref}
        value={value || ''}
        onChange={(event) => onChange(event.target.value)}
        fullWidth
        required
        label="Telefonnummer"
        variant="outlined"
        autoComplete={autocomplete ? "tel" : ""}
      />
    )),
    []
  );

  return (
    <PhoneInput
      value={value}
      onChange={onChange}
      error={error} 
      helperText={helperText}
      onBlur={onBlur}
      defaultCountry={'NO'}
      countrySelectProps={{
        style: {
          backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
          color: theme.palette.mode === 'dark' ? '#fff' : '#000',
          border: `1px solid ${theme.palette.divider}`,
          padding: '8px',
        },
      }}
      inputComponent={CustomInput}
      international
      limitMaxLength
    />
  );
};
export default CustomPhoneInput;