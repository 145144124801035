import { Alert, Box, Button, CircularProgress, Grid, IconButton, Paper, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useApi } from '../../../../services/HttpService';
import { InfoArea } from '../../../../components/ui/InfoArea';
import { Contact } from '../../../../model/User';
import { GridColDef } from '@mui/x-data-grid';
import BaseDataGridTable from '../../../../components/tables/BaseDataGridTable';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import { ContactsRounded } from '@mui/icons-material';
import { dayjsService } from '../../../../services/dayjsService';
import { SingleDatePicker } from '../../../../components/ui/SingleDatePicker';
import HardDeleteDialog from '../../../../components/modals/dialog/HardDeleteDialog';
import { Accessgroup } from '../../../../model/System';
import { MultipleSelectChip } from '../../../../components/ui/DropdownSelectChip';
import { CenterSpinner } from '../../../../components/ui/CenterSpinner';
import { ClickableIcon } from '../../../../components/ui/Icon';
import { highlightedGridColumns } from '../../../../components/tables/BaseTable';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { SearchBox } from '../../../../components/ui/SearchBox';
import { ClipText } from '../../../../components/ui/ClipText';

interface OidcUser {
  id: number;
  name: string;
  password_hash: string;
  pw_expiredate: Date;
  telephone: string;
  emailaddress: string;
  first_name: string;
  last_name: string;
  username: string;
  oidc_id: string;
  email_verified: boolean;
  phone_verified: boolean;
  is_admin: boolean;
}

function emptyOidcUser(): OidcUser {
  return {
    id: 0,
    name: "",
    password_hash: "",
    pw_expiredate: new Date,
    telephone: "",
    emailaddress: "",
    first_name: "",
    last_name: "",
    username: "",
    oidc_id: "",
    email_verified: false,
    phone_verified: false,
    is_admin: false,
  }
}

interface UserContact {
  id: number,
  name: string,
  oidc_user_id: number,
  contact_id: number,
  from_date: Date,
  to_date: Date,
}

interface UserAccessgroup {
  id: number,
  name: string,
  oidc_user_id: number,
  accessgroup_id: number,
  from_date: Date,
  to_date: Date,
}

const oidcUserCols: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Navn', width: 130 },
  { field: 'username', headerName: 'Brukernanv', width: 130 },
  { field: 'telephone', headerName: 'Telefon', width: 130 },
  { field: 'emailaddress', headerName: 'e-post', width: 130 },
  { field: 'is_admin', headerName: 'Admin', width: 70 },
];

function isNowBetweenDates(from, to) {
  const now = new Date();
  const fromDate = from ? new Date(from) : null;
  const toDate = to ? new Date(to) : null;

  if (!fromDate && !toDate) {
    return true;
  } else if (fromDate && !toDate) {
    return now >= fromDate;
  } else if (!fromDate && toDate) {
    return now <= toDate;
  } else if (fromDate && toDate) {
    return now >= fromDate && now <= toDate;
  }

  return false;
}

export const UserContact = (props: { contact: Contact }) => {
  const theme = useTheme();
  const { api, response, error, isLoading } = useApi();
  const { api: oidcApi, response: oidcResponse, error: oidcError, isLoading: oidcIsLoading } = useApi(); // @todo: can be done once
  const { api: accessgroupApi, response: accessgroupResponse, error: accessgroupError, isLoading: accessgroupIsLoading } = useApi(); // @todo: can be done once
  const { api: userAccessApi, response: userAccessResponse, error: userAccessError, isLoading: userAccessIsLoading } = useApi();
  const [contact, setContact] = useState<Contact>({} as Contact);
  const [oidcUser, setOidcUser] = useState<OidcUser>(emptyOidcUser());
  const [oidcUsers, setOidcUsers] = useState<OidcUser[]>([]);
  const [filteredOidcUsers, setFilteredOidcUsers] = useState<OidcUser[]>([]);
  const [userContact, setUserContact] = useState<UserContact>({} as UserContact);
  const [userContacts, setUserContacts] = useState<UserContact[]>([]);
  const [deleteUserContactId, setDeleteUserContactId] = useState(0);
  const [deleteOidcUserId, setDeleteOidcUserId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [accessgroups, setAccessgroups] = useState<Accessgroup[]>([]); // available accessgroup
  const [userAccessgroup, setUserAccessgroup] = useState<UserAccessgroup[]>([]); // connected accessgroup to oidcUser
  const [userAccessgroupCopy, setUserAccessgroupCopy] = useState<UserAccessgroup[]>([]);

  const apiRef = useRef<GridApiCommunity>(null);

  useEffect(() => {
    if (props?.contact?.id) {
      setContact(props.contact);
      getUserContact();
      oidcApi("oidcUsers", "GET");
      accessgroupApi("accessgroup", "get")
    }
  }, [props.contact]);

  useEffect(() => {
    if (response?.statusCode === 200) {
      setUserContacts(response.user_contacts);
      setUserContact({} as UserContact);
    }
  }, [response]);

  useEffect(() => {
    if (accessgroupResponse?.statusCode === 200) {
      setAccessgroups(accessgroupResponse.accessgroups);
      console.log("res", accessgroupResponse.accessgroups)
    }
  }, [accessgroupResponse]);

  const getUserContact = () => {
    api("userContact/" + props.contact.id, "GET");
  }

  const postUserContact = () => {
    api("/userContact", "POST", userContact);
  }

  const deleteUserContact = () => {
    api("/userContact/" + deleteUserContactId, "DELETE", userContact).then(res => {
      if (res?.statusCode === 200) {
        setDeleteUserContactId(0);
      }
    });
  }

  useEffect(() => {
    if (oidcResponse?.statusCode === 200) {
      if (oidcResponse.oidc_user) {
        setOidcUser(oidcResponse.oidc_user);
        setUserAccessgroup(oidcResponse.user_accessgroups);
        setUserAccessgroupCopy(oidcResponse.user_accessgroups);
      } else {
        resetOidcUser();
      }
      setOidcUsers(oidcResponse.oidc_users);
      setFilteredOidcUsers(oidcResponse.oidc_users);
    }
  }, [oidcResponse]);

  useEffect(() => {
    handleSearch(searchText);
  }, [oidcUser]);

  const selectOidcUser = () => {
    const data = {
      name: contact.name + " - " + oidcUser.name,
      oidc_user_id: oidcUser.id,
      contact_id: contact.id,
    }
    api("/userContact", "POST", data);
  }

  const postOidcUser = () => {
    const data = {
      oidc_user: oidcUser,
      user_accessgroups: userAccessgroup,
    }
    oidcApi("/oidcUsers", "POST", data)
  }

  const resetOidcUser = () => {
    setOidcUser(emptyOidcUser());
    setUserAccessgroup([]);
    setUserAccessgroupCopy([]);
  }

  const deleteOidcUser = () => {
    oidcApi("/oidcUsers/" + deleteOidcUserId, "DELETE").then(res => {
      if (res?.statusCode === 200) {
        setDeleteOidcUserId(0);
        getUserContact();
      }
    });
  }

  const getUserAccessgroups = (user_id: number) => {
    userAccessApi("/userAccessgroups/" + user_id, "GET").then(res => {
      if (res?.statusCode === 200 && res.user_accessgroups) {
        setUserAccessgroup(res.user_accessgroups);
        setUserAccessgroupCopy(res.user_accessgroups);
      }
    })
  }

  const handleRowSelectionModelChange = (ids) => {
    const newSelectedSet = new Set(ids);
    const selected = oidcUsers?.filter(row => newSelectedSet.has(row.id));
    if (selected?.length > 0) {
      setOidcUser(selected[0]);
      getUserAccessgroups(selected[0].id);
    }
  }

  const handleUserContactEditClick = (user: UserContact) => {
    setUserContact(user);
    handleRowSelectionModelChange([user.oidc_user_id]);
  }

  const handleUserAccessgroupChange = (ids: number[]) => {
    let selectedAccessgroups = accessgroups.filter(v => ids.includes(v.id));
    let newUserAccessgroups: UserAccessgroup[] = [];

    // loop over selectedAccessgroups, check if exists in userAccessgroupCopy
    selectedAccessgroups.forEach(v => {
      let item = userAccessgroupCopy?.find(u => u.accessgroup_id === v.id);
      if (!item) {
        item = {
          id: 0,
          name: oidcUser.name + " - " + v.name,
          oidc_user_id: oidcUser.id,
          accessgroup_id: v.id,
          from_date: null,
          to_date: null,
        }
      }
      newUserAccessgroups.push(item);
    });

    setUserAccessgroup(newUserAccessgroups);
  }

  const handleSearch = (queryText: string) => {
    const query = queryText.toLowerCase();
    setSearchText(query);

    const filteredData = oidcUsers.filter((row) =>
      oidcUserCols.some((column) => {

        // Use valueGetter if defined, otherwise use the field directly
        const value = column.valueGetter
          ? column.valueGetter(undefined as never, row, column, apiRef)
          : row[column.field];

        return String(value).toLowerCase().includes(query);
      })
    );

    setFilteredOidcUsers(filteredData);
  };

  return (
    <>
      <HardDeleteDialog
        open={deleteUserContactId > 0}
        loading={isLoading}
        errorMessage={error}
        onClose={() => setDeleteUserContactId(0)}
        onConfirm={deleteUserContact}
      />
      <HardDeleteDialog
        open={deleteOidcUserId > 0}
        bodyText={"Er du sikker på at du vil utføre denne slettingen? Alle kontakter tilknyttet denne brukeren vil bli berørt."}
        loading={oidcIsLoading}
        errorMessage={oidcError}
        onClose={() => setDeleteOidcUserId(0)}
        onConfirm={deleteOidcUser}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
        <Paper elevation={6} sx={{ width: '100%', height: 'fit-content', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

            {oidcError && <Alert sx={{ width: '100%' }} severity="error">{oidcError}</Alert>}
            {oidcUser.id > 0 && <Alert sx={{ width: '100%' }} severity="warning">Redigering av bruker vil berøre alle tilknyttede kontakter</Alert>}

            <Typography variant='h5' sx={{ mt: 2, textDecoration: 'underline' }}>{oidcUser.id > 0 ? 'Rediger' : 'Opprett'} bruker</Typography>
            <Grid container spacing={1.5} sx={{ pt: 2, justifyContent: "center", alignItems: "center" }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Navn"
                  fullWidth
                  value={oidcUser.name}
                  onChange={(e) => setOidcUser({ ...oidcUser, name: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  label="Fornavn"
                  fullWidth
                  value={oidcUser.first_name}
                  onChange={(e) => setOidcUser({ ...oidcUser, first_name: e.target.value })}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Etternavn"
                  fullWidth
                  value={oidcUser.last_name}
                  onChange={(e) => setOidcUser({ ...oidcUser, last_name: e.target.value })}
                  variant="outlined"
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Brukernavn"
                  fullWidth
                  value={oidcUser.username}
                  onChange={(e) => setOidcUser({ ...oidcUser, username: e.target.value })}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            {userAccessError && <Alert sx={{ width: '100%' }} severity="error">{userAccessError}</Alert>}
            <MultipleSelectChip
              label={"Tilgangsgrupper"}
              inputs={accessgroups}
              selectField={"name"}
              selected={userAccessgroup.map((ug) => ug.accessgroup_id)}
              onChange={handleUserAccessgroupChange}
            />
            <CenterSpinner loading={userAccessIsLoading} />
            {!userAccessIsLoading && userAccessgroup?.length > 0 ? (
              <>
                {userAccessgroup?.map((userAccess, index) => {
                  const isActive = isNowBetweenDates(userAccess.from_date, userAccess.to_date);
                  const accessGroupName = accessgroups.find(ug => ug.id === userAccess.accessgroup_id)?.name || "";

                  return (
                    <Paper key={index} elevation={2}>
                      <Typography variant='h6' sx={{ p: 1, pb: 0, textDecoration: 'underline' }}>{accessGroupName}</Typography>
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 1 }}>
                        <Grid container spacing={1.5} sx={{ pt: 2, justifyContent: "center", alignItems: "center" }}>
                          <Grid item xs={2} md={1}>
                            {/* <Typography variant='body1' sx={{ width: 30 }}>{userAccess.id}</Typography> */}
                            <Tooltip title={isActive ? 'Aktiv' : 'Inaktiv'}>
                              <Typography variant='body1' sx={{ backgroundColor: isActive ? theme.palette.success.main : theme.palette.error.main, textAlign: 'center' }}>{isActive ? 'A' : 'In'}</Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={5} md={4}>
                            <SingleDatePicker
                              label="Fra dato"
                              date={userAccess.from_date}
                              onChange={(date) => setUserAccessgroup((prev) => prev.map((ug, i) => i === index ? { ...ug, from_date: date } : ug))}
                            />
                          </Grid>
                          <Grid item xs={5} md={4}>
                            <SingleDatePicker
                              label="Til dato"
                              date={userAccess.to_date}
                              onChange={(date) => setUserAccessgroup((prev) => prev.map((ug, i) => i === index ? { ...ug, to_date: date } : ug))}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              label="Navn"
                              fullWidth
                              value={userAccess.name}
                              onChange={(e) => setUserAccessgroup((prev) => prev.map((ug, i) => i === index ? { ...ug, name: e.target.value } : ug))}
                              variant="standard"
                              size='small'
                            />
                          </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}>
                          <ClickableIcon title="Fjern" size="small" onClick={() => setUserAccessgroup((prev) => prev.filter((ug) => ug.accessgroup_id !== userAccess.accessgroup_id))}>
                            <ClearIcon sx={{ color: theme.palette.error.main }} />
                          </ClickableIcon>
                        </Box>
                      </Box>
                    </Paper>
                  )
                })}
              </>
            ) : (
              <>
                {(!userAccessIsLoading && oidcUser.id > 0) && <Alert sx={{ width: '100%' }} severity="info">Denne brukeren har ingen tilgangsgrupper</Alert>}
              </>
            )}

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 1 }}>
              <Box sx={{ display: 'flex', gap: 2, p: 1 }}>
                <Button variant='contained' color='success' disabled={oidcUser.id === 0 || isLoading} onClick={selectOidcUser}>Link bruker til kontakt</Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 2, p: 1 }}>
                {oidcUser.id > 0 && (
                  <Button variant='contained' color='error' onClick={() => setDeleteOidcUserId(oidcUser.id)}>Slett</Button>
                )}
                <Button variant='contained' color='darkgrey' onClick={resetOidcUser}>Avbryt</Button>
                <Button
                  variant='contained'
                  color='info'
                  onClick={postOidcUser}
                  disabled={oidcIsLoading}
                  endIcon={oidcIsLoading && <CircularProgress size={22} />}
                >
                  {oidcUser.id > 0 ? "Rediger" : "Opprett"}
                </Button>
              </Box>
            </Box>

            <InfoArea title={'Tilgjengelige brukere'}>
              <SearchBox handleSearch={handleSearch} useDebounce={true} />
              <Box sx={{ height: 400, width: "100%" }}>
                <BaseDataGridTable
                  rows={filteredOidcUsers}
                  columns={oidcUserCols ? highlightedGridColumns(oidcUserCols, searchText, apiRef) : []}
                  loading={oidcIsLoading}
                  disableMultipleRowSelection
                  onRowSelectionModelChange={(ids) => handleRowSelectionModelChange(ids)}
                />
              </Box>
            </InfoArea>

          </Box>
        </Paper>

        <Paper elevation={6} sx={{ width: '100%', height: 'fit-content', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
            <Typography variant='h5' sx={{ mt: 2 }}>Tilknyttede brukere</Typography>

            {error && <Alert sx={{ width: '100%' }} severity="error">{error}</Alert>}

            <CenterSpinner loading={oidcIsLoading}>
              {userContacts?.length > 0 ? (
                <>
                  {userContacts?.map((user, index) => {
                    let isActive = isNowBetweenDates(user.from_date, user.to_date);

                    return (
                      <Paper key={index} elevation={2} sx={{ width: '100%', p: 1 }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                            <Typography variant='body1' sx={{ width: 70, backgroundColor: isActive ? theme.palette.success.main : theme.palette.error.main, textAlign: 'center' }}>{isActive ? 'Aktiv' : 'Inaktiv'}</Typography>
                            {user.id !== userContact.id ? (
                              <>
                                <Typography variant='body1' sx={{ width: 200 }}>{`${dayjsService.toDateDisplayString(user.from_date)} - ${dayjsService.toDateDisplayString(user.to_date)}`}</Typography>
                                <ClipText width={300}>{user.name}</ClipText>
                              </>
                            ) : (
                              <>
                                <Typography variant='body1' sx={{ width: 200 }}>{`${dayjsService.toDateDisplayString(user.from_date)} - ${dayjsService.toDateDisplayString(user.to_date)}`}</Typography>
                                <TextField
                                  label="Navn"
                                  value={userContact.name}
                                  onChange={(e) => setUserContact({ ...userContact, name: e.target.value })}
                                  variant="standard"
                                  sx={{ width: { xs: '100%', md: 200 } }}
                                />
                                <SingleDatePicker
                                  label="Fra dato"
                                  date={userContact.from_date}
                                  onChange={(date) => setUserContact({ ...userContact, from_date: date })}
                                />
                                <SingleDatePicker
                                  label="Til dato"
                                  date={userContact.to_date}
                                  onChange={(date) => setUserContact({ ...userContact, to_date: date })}
                                />
                              </>
                            )}
                          </Box>
                          {user.id !== userContact.id && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', gap: 2 }}>
                              <ClickableIcon title="Rediger" size="small" onClick={() => handleUserContactEditClick(user)}>
                                <ManageAccountsIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
                              </ClickableIcon>
                              <ClickableIcon title="Slett" size="small" onClick={() => setDeleteUserContactId(user.id)}>
                                <DeleteIcon sx={{ color: theme.palette.error.main }} />
                              </ClickableIcon>
                            </Box>
                          )}
                        </Box>
                        {user.id === userContact.id && (
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', flexWrap: 'wrap', gap: 2, p: 1 }}>
                            <Button variant='contained' color='darkgrey' onClick={() => setUserContact({} as UserContact)}>Avbryt</Button>
                            <Button
                              variant='contained'
                              color='info'
                              onClick={postUserContact}
                              disabled={isLoading}
                              endIcon={isLoading && <CircularProgress size={22} />}
                            >
                              Lagre
                            </Button>
                          </Box>
                        )}
                      </Paper>
                    )
                  })}
                </>
              ) : (
                <Alert sx={{ width: '100%' }} severity="info">Denne kontakten har ingen bruker</Alert>
              )}
            </CenterSpinner>
          </Box>
        </Paper >
      </Box >
    </>
  )
}