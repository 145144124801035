import React from 'react';
import { TextField, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/nb';
import { themeStore } from '../themes/BaseTheme';

dayjs.locale('nb');

interface SingleDatePickerProps {
  label: string;
  date: Date | null;
  required?: boolean;
  fullWidth?: boolean;
  maxDate?: Date;
  onChange: (date: Date | null) => void;
}

export const SingleDatePicker: React.FC<SingleDatePickerProps> = (props: SingleDatePickerProps) => {
  const theme = themeStore().theme;
  const { label, date, onChange,
    required = false,
    fullWidth = false,
    maxDate = "2099-12-31"
  } = props;

  const max = dayjs(maxDate);

  const handleDateChange = (selectedDate: Dayjs | null) => {
    onChange(selectedDate ? selectedDate.toDate() : null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          sx={{
            width: fullWidth ? "100%" : "inherit",
            '& .MuiSvgIcon-root': { // not working
              color: theme.palette.mode === "dark" ? "white" : "black",
            },
          }}
          label={`${label}${required ? " *" : ""}`}
          value={date ? dayjs(date) : null}
          onChange={handleDateChange}
          maxDate={max}
          slotProps={{
            field: { clearable: true },
            // openPickerButton: {
            //   sx: {
            //     color: "red",
            //   },
            // },
            // openPickerIcon: {
            //   sx: {
            //     color: "green",
            //   },
            // },
            // clearButton: {
            //   sx: {
            //     color: "blue",
            //   },
            // },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};