import { useState, useEffect, useRef } from 'react';
import { Alert, Box, SxProps, Theme } from '@mui/material';

interface StickyAlertProps {
  message: string;
  severity: "success" | "info" | "warning" | "error";
  sx?: SxProps;
}

export const StickyAlert: React.FC<StickyAlertProps> = ({ message, severity, sx }) => {
  const [dismissed, setDismissed] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const isFixedRef = useRef(false);
  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const originalPositionRef = useRef(0);
  const alertRef = useRef(null);
  
  const initValues = () => {
    if (alertRef.current) {
      originalPositionRef.current = alertRef.current.offsetTop;
      const scrollPosition = window.scrollY;

      if (scrollPosition >= originalPositionRef.current) {
        setPlaceholderHeight(alertRef.current.offsetHeight);
        setIsFixed(true);
        isFixedRef.current = true;
      } else if (scrollPosition < originalPositionRef.current) {
        setIsFixed(false);
        isFixedRef.current = false; 
      }
    }
  }

  useEffect(() => {
    setDismissed(false);
    initValues();
  }, [message]);

  useEffect(() => {
    if (alertRef.current) {
      originalPositionRef.current = alertRef.current.offsetTop;
    }

    const handleScroll = () => {
      if (alertRef.current) {
        const scrollPosition = window.scrollY;

        // console.log("scrollPosition", scrollPosition, "originalPositionRef", originalPositionRef.current, "isFixedRef", isFixedRef.current)

        // Stick the alert when scrolled past its original position
        // Place at original possition when scrolled back
        if (scrollPosition >= originalPositionRef.current && !isFixedRef.current) {
          setPlaceholderHeight(alertRef.current.offsetHeight);
          setIsFixed(true);
          isFixedRef.current = true;
        } else if (scrollPosition < originalPositionRef.current && isFixedRef.current) {
          setIsFixed(false);
          isFixedRef.current = false;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClose = () => {
    setDismissed(true);
  }

  if (dismissed || !message) {
    return null
  }

  return (
    <>
      {isFixed && <Box sx={{ height: `${placeholderHeight}px` }} />}
      <Box
        ref={alertRef}
        sx={{
          // width: 'initial',
          position: isFixed ? 'fixed' : 'relative',
          top: isFixed ? 0 : 'auto',
          zIndex: 1000,
          ...sx,
        }}
      >
        <Alert severity={severity} onClose={handleClose}>
          {message}
        </Alert>
      </Box>
    </>
  )
}