import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendDataToBackend } from "../lib/ConvertImage";
import { useDcpAuth } from "../../../../contexts/DcpAuthContext";
import { useDcpApi, availablePaths } from "../../../../services/DcpService";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import "../styles/browse-image.css";
import { Saving } from "../components/Saving";
import { DMEditorWithPreview } from "./DMEditorWithPreview";

export const CreatePage: React.FC = () => {
  const { templateID, title: customTitle, pageType } = useParams();

  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");

  const navigate = useNavigate();
  const { accessToken } = useDcpAuth();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { dcpApi, dcpResponse, dcpError } = useDcpApi();
  const { t } = useTranslation("EditPage");

  const saveContent = async (data: any) => {
    setIsSaving(true);

    try {
      const fileName: string = "MyPoster.png";
      const payloadKey: string = "file";
      let previewUrl = "";
      const reqMethod = "POST";
      const reqURL = availablePaths.imageUpload.uploadImageFile();
      console.log("sendDataToBackend");
      const responseData = await sendDataToBackend(
        "dmeditor-view",
        fileName,
        payloadKey,
        reqURL,
        reqMethod
      );
      if (!responseData.error) previewUrl = responseData.data;

      const payload = {
        body: data.data,
        title: data.page.title,
        preview: previewUrl,
        pdf: "",
      };

      let url = "";
      if (pageType === "poster") {
        url = availablePaths.posterApi.createPoster();
      } else {
        url = availablePaths.newsletterApi.createNewsLetter();
      }

      await dcpApi(url, "POST", payload);
      toast.success("Saved successfully");
      setIsSaving(false);
      navigate(-1);
    } catch {
      toast.error(t("There was an error saving the content"));
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      if (templateID === "0") {
        return;
      }
      const path = availablePaths.templateApi.getTemplateById(
        parseInt(templateID)
      );
      if (!path) {
        console.error("Invalid type or id");
        return;
      }
      dcpApi(path, "GET")
        .catch((e) => {
          throw Error("Can't get template data");
        })
        .then((data) => {
          if (data.error === false) {
            const contentData = data.data;
            setData(contentData.body);
            if (customTitle) {
              setTitle(customTitle);
            } else {
              setTitle(contentData.title);
            }
          } else {
            throw Error("Can't get template data");
          }
        });
    }
  }, [accessToken]);

  useEffect(() => {
    if (dcpError) {
      console.error("Save failed:", dcpError);
      toast.error(t("There was an error saving the content"));
      setIsSaving(false);
    }
  }, [dcpError]);

  return (
    <div>
      {isSaving && <Saving />}
      <DMEditorWithPreview
        data={data}
        title={title}
        onSave={saveContent}
        onCancel={() => {
          navigate(-1);
        }}
      />
    </div>
  );
};

