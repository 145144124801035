import React, { useEffect, useMemo, useState } from 'react';
import { useApi } from '../../services/HttpService';
import { AdminTable } from '../../components/tables/AdminTable';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Alert, CircularProgress, TextField } from '@mui/material';
import { SearchBox } from '../../components/ui/SearchBox';
import { SingleSelectSearch } from '../../components/ui/DropdownSelectChip';

interface Tables {
  id: number;
  table_name: string;
  table: string;
  count: number;
  display: string;
}

export const TableOverview = () => {
  const { api, response, isLoading, error } = useApi();
  const { api: tableApi, response: tableResponse, isLoading: tableIsLoading, error: tableError } = useApi();

  const [tables, setTables] = useState<Tables[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [columnTypes, setColumnTypes] = useState<any>({});
  const [selectedTable, setSelectedTable] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  const memoizedRows = useMemo(() => rows, [rows]);
  const memoizedFilteredRows = useMemo(() => filteredRows, [filteredRows]); // passes these to AdminTable to prevent multiple resets of pagination and selected

  useEffect(() => {
    async function getTables() {
      await tableApi("tables", "GET");
    }
    getTables();
  }, [])

  useEffect(() => {
    if (tableResponse) {
      tableResponse.tableInfo.forEach((item, i) => {
        item.id = i + 1;
        item.display = item.table + " (" + item.count + ")";
      });
      setTables(tableResponse.tableInfo)
    }
  }, [tableResponse])

  const getTableData = (tableName: string, reload: boolean = false) => {
    setSelectedTable(tableName);

    if (!tableName) {
      setRows([]);
      setFilteredRows([]);
      setColumns([]);
      return;
    }

    setLoadingData(true);

    api("/tables/pagination", "POST", {
      table_name: tableName,
      page_index: pageIndex,
      items_per_page: itemsPerPage
    }).then(res => {
      if (res?.statusCode === 200) {
        setRows(res.data); // all rows, uses these to apply filters
        setFilteredRows(res.data); // Initialize filteredRows with all rows
        setColumns(res.columns);
        setColumnTypes(res.column_types);
      } else {
        setRows([]);
        setFilteredRows([]);
        setColumns([]);
      }
    }).finally(() => {
      setLoadingData(false);
    })
  }

  // applies filters to rows whenever changed, will eventually handle multiple
  const applyFilters = () => {
    // Filter rows based on searchQuery
    const filtered = rows.filter(row =>
      Object.values(row).some(value => {
        return String(value).toLowerCase().includes(searchQuery.toLowerCase());
      })
    );

    setFilteredRows(filtered);
  }

  useEffect(() => {
    applyFilters();
  }, [memoizedRows, searchQuery])

  const addFilter = ((filter: string) => {

  });

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      {/* {tables.map((tableInfo, index) => (
          <Button
            key={index}
            variant={selectedTable === tableInfo.table_name ? "contained" : "outlined"}
            style={{ minWidth: "100px", textTransform: 'none', wordBreak: 'break-all', overflow: "hidden", textOverflow: "ellipsis" }}
            sx={{ pl: { xs: 0, sm: 1 }, pr: { xs: 0, sm: 1 } }}
            onClick={() => getTableData(tableInfo.table_name)}
          >
            {tableInfo.table} ({tableInfo.count})
          </Button>
        ))} */}
      {error && (
        <Alert variant='outlined' severity='error' sx={{ m: 2, mt: 0 }} >{error}</Alert>
      )}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: { xs: 1, md: 2 }, m: 2, mb: 1, }}>
        <Box sx={{ width: { xs: "100%", md: 350 } }}>
          <SingleSelectSearch
            label='Velg tabell'
            inputs={tables}
            selectField='display'
            selected={tables?.find(x => x.table_name === selectedTable)?.id}
            onChange={(tabellId) => getTableData(tables.find(x => x.id === tabellId)?.table_name || "")}
          />
        </Box>
        <Box
          sx={{
            mb: 1,
            display: 'flex',
            position: { xs: "static", md: "absolute" },
            left: { xs: "auto", md: "50%" },
            transform: { xs: "none", md: "translateX(-50%)" },
            flexGrow: { xs: 1, md: 0 }
          }}
        >
          <SearchBox handleSearch={(query) => setSearchQuery(query)} useDebounce={true} />
        </Box>
      </Box>
      <Box sx={{ ml: 2, mr: 2 }}>
        <AdminTable
          tableName={selectedTable}
          rows={memoizedFilteredRows}
          columns={columns}
          columnTypes={columnTypes}
          addFilter={addFilter}
          loadingData={loadingData}
          searchQuery={searchQuery}
          reloadTable={() => getTableData(selectedTable)}
        />
      </Box>
    </Box>
  )
}