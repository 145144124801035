import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, Grid, Radio, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { Member, newMember, Email, Phone, Address, newEmail, newPhone, newAddress } from "../../../../model";
import { ClickableIcon } from "../../../../components/ui/Icon";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import CustomPhoneInput from "../../../../components/ui/CustomPhoneInput";
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { SingleSelectChip } from "../../../../components/ui/DropdownSelectChip";
import { SingleDatePicker } from "../../../../components/ui/SingleDatePicker";

interface MemberFormProps {
  member: Member;
  error?: string;
  autocomplete?: boolean; // toggle autocomplete, nice for managers
}

export interface MemberFormRef {
  valid: () => boolean;
  getMember: () => Member;
}

export const MemberForm = forwardRef((props: MemberFormProps, ref) => {
  const { error, autocomplete = true } = props;
  const [member, setMember] = useState<Member>(newMember());
  const [checksum, setChecksum] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setMember(props.member);
  }, [props.member]);

  async function generateChecksum(obj: any): Promise<string> {
    const encoder = new TextEncoder();
    const jsonString = JSON.stringify(obj, Object.keys(obj).sort()); // Ensure consistent order
    const data = encoder.encode(jsonString);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  const [touched, setTouched] = useState<{
    base: Record<string, boolean>;
    arrays: Record<
      'emails' | 'phones' | 'addresses',
      Record<number, Record<string, boolean>>
    >;
  }>({
    base: {},
    arrays: {
      emails: {},
      phones: {},
      addresses: {},
    },
  });

  const valid = () => {
    let hasErrors = false;

    // Validate all base fields
    for (const key of Object.keys(member) as (keyof Member)[]) {
      if (typeof member[key] !== "object" && validateBaseField(key)) {
        hasErrors = true;
      }
    }

    // Validate all array fields
    Object.keys(member).forEach((listKey) => {
      if (Array.isArray(member[listKey as keyof Member])) {
        const list = member[listKey as keyof typeof touched.arrays] as any[];
        list.forEach((_, index) => {
          Object.keys(list[index] || {}).forEach((field) => {
            if (validateArrayField(listKey as any, index, field)) {
              hasErrors = true;
            }
          });
        });
      }
    });

    // validate primaries if list contains items
    if (member.emails?.length > 0) {
      const hasEmailPrimary = member.emails?.some((item) => item.primary);
      if (!hasEmailPrimary) {
        alert("At least one email must be marked as primary.");
        return false;
      }
    }
    if (member.phones?.length > 0) {
      const hasPhonePrimary = member.phones?.some((item) => item.primary);
      if (!hasPhonePrimary) {
        alert("At least one phone must be marked as primary.");
        return false;
      }
    }
    if (member.addresses?.length > 0) {
      const hasAddressPrimary = member.addresses?.some((item) => item.primary);
      if (!hasAddressPrimary) {
        alert("At least one address must be marked as primary.");
        return false;
      }
    }

    setSubmitted(true);
    return !hasErrors;
  }

  const getMember = () => {
    return member;
  }

  // Expose the valid function to the parent, and a method to get the whole member object
  useImperativeHandle(ref, () => ({
    valid,
    getMember,
  }));

  const validateBaseField = (field: keyof Member): string => {
    if (field === "first_name") {
      if (!member.first_name?.trim()) return "Fornavn kan ikke være tom";
    } else if (field === "last_name") {
      if (!member.last_name?.trim()) return "Etternavn kan ikke være tom";
    } else if (field === "sex_id") {
      if (member.sex_id === 0) return "Kjønn må velges";
    }
    return ""; // No error
  };

  const validateArrayField = (
    listKey: 'emails' | 'phones' | 'addresses',
    index: number,
    field: string
  ): string => {
    const list = member[listKey];
    if (!list || list.length === 0) {
      return ""
    }
    const item = list[index];

    if (listKey === "emails") {
      const emailItem = item as Email;
      if (field === "name") {
        if (!emailItem.name.trim()) {
          return "e-post kan ikke være tom";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailItem.name)) {
          return "Ugyldig e-post format";
        }
      }
    }

    if (listKey === "phones") {
      const phoneItem = item as Phone;
      if (field === "name") {
        if (!phoneItem.name) {
          return "Telefonnummer kan ikke være tom";
        } else if (!isPossiblePhoneNumber(phoneItem.name)) {
          return 'Ugyldig telefonnummer';
        }
        // Much stricter phone number validation, but can be changed in future
        // if (!isValidPhoneNumber(item.name)) {
        //   return 'Invalid phone number';
        // }
      }
    }

    if (listKey === "addresses") {
      const addressItem = item as Address;
      if (field === "addressline1") {
        if (!addressItem.addressline1.trim()) {
          return "Adresse kan ikke være tom";
        }
      } else if (field === "postcode") {
        if (!addressItem.postcode.trim()) {
          return "Postnummner kan ikke være tom";
        }
      } else if (field === "postoffice") {
        if (!addressItem.postoffice.trim()) {
          return "Poststed kan ikke være tom";
        }
      }
    }

    return "";
  };

  const handleBaseChange = (field: keyof Member, value: any) => {
    setMember((prev) => ({ ...prev, [field]: value }));

    // setTouched((prev) => ({
    //   ...prev,
    //   base: { ...prev.base, [field]: true },
    // }));
  };

  const handleBaseBlur = (field: keyof Member) => {
    setTouched((prev) => ({
      ...prev,
      base: { ...prev.base, [field]: true },
    }));
  };

  const handleItemChange = (
    index: number,
    value: any, // The new value
    listKey: 'emails' | 'phones' | 'addresses',
    itemKey: string // The key to update within each item (e.g., "name" for emails)
  ) => {
    setMember((prevMember) => ({
      ...prevMember,
      [listKey]: prevMember[listKey]?.map((item, i) =>
        i === index ? { ...item, [itemKey]: value } : item
      ),
    }));

    // setTouched((prev) => ({
    //   ...prev,
    //   arrays: {
    //     ...prev.arrays,
    //     [listKey]: {
    //       ...prev.arrays[listKey],
    //       [index]: { ...prev.arrays[listKey][index], [itemKey]: true },
    //     },
    //   },
    // }));
  };

  const handleArrayBlur = (
    listKey: 'emails' | 'phones' | 'addresses',
    index: number,
    field: string
  ) => {
    setTouched((prev) => ({
      ...prev,
      arrays: {
        ...prev.arrays,
        [listKey]: {
          ...prev.arrays[listKey],
          [index]: { ...prev.arrays[listKey][index], [field]: true },
        },
      },
    }));
  };

  const handlePrimaryChange = (
    index: number,
    listKey: 'emails' | 'phones' | 'addresses',
  ) => {
    const primaryKey = 'primary';

    setMember((prevMember) => ({
      ...prevMember,
      [listKey]: prevMember[listKey]?.map((item, i) => ({
        ...item,
        [primaryKey]: i === index, // Mark the item at the given index as primary
      })),
    }));
  };

  const handleAddItem = (
    newItemFn: () => any, // Function to create the new item
    listKey: 'emails' | 'phones' | 'addresses',
  ) => {
    let item = newItemFn();
    if ((member[listKey] || []).length === 0) {
      item.primary = true;
    }

    setMember((prevMember) => ({
      ...prevMember,
      [listKey]: [...(prevMember[listKey] || []), item],
    }));

    // Initialize touched state for the new item
    setTouched((prev) => ({
      ...prev,
      arrays: {
        ...prev.arrays,
        [listKey]: {
          ...prev.arrays[listKey],
          [(member[listKey] || []).length]: {},
        },
      },
    }));
  };

  const handleRemoveItem = (
    index: number,
    listKey: 'emails' | 'phones' | 'addresses',
  ) => {
    const primaryKey = 'primary';
    const list = member[listKey];

    setMember((prevMember) => {
      const updatedList = prevMember[listKey].filter((_, i) => i !== index);

      // Ensure that there is always at least one primary item
      if (updatedList.length > 0 && updatedList.filter((item) => item[primaryKey]).length === 0) {
        updatedList[0][primaryKey] = true;
      }

      return {
        ...prevMember,
        [listKey]: updatedList,
      };
    });

    setTouched((prev) => {
      const updatedTouched = { ...prev.arrays[listKey] };
      delete updatedTouched[index];
      return {
        ...prev,
        arrays: {
          ...prev.arrays,
          [listKey]: updatedTouched,
        },
      };
    });
  };

  return (
    <form>
      <Grid container spacing={2}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        {error && <Alert sx={{ width: '100%' }} severity="error">{error}</Alert>}

        {member.id > 0 && (
          <>
            <Grid item xs={12} sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
              <Typography variant="body1">Medlemsnummer</Typography>
              <TextField
                fullWidth
                value={member.member_number}
                disabled
                variant="outlined"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={6}>
          <TextField
            label="Fornavn"
            required
            fullWidth
            autoComplete={autocomplete ? "given-name" : ""}
            value={member.first_name}
            onChange={(e) => handleBaseChange("first_name", e.target.value)}
            variant="outlined"
            error={validateBaseField("first_name") !== "" && (submitted || touched.base.first_name)}
            helperText={(submitted || touched.base.first_name) ? validateBaseField("first_name") : ""}
            onBlur={() => handleBaseBlur("first_name")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Etternavn"
            required
            fullWidth
            autoComplete={autocomplete ? "family-name" : ""}
            value={member.last_name}
            onChange={(e) => handleBaseChange("last_name", e.target.value)}
            variant="outlined"
            error={validateBaseField("last_name") !== "" && (submitted || touched.base.last_name)}
            helperText={(submitted || touched.base.last_name) ? validateBaseField("last_name") : ""}
            onBlur={() => handleBaseBlur("last_name")}
          />
        </Grid>
        <Grid item xs={6}>
          <SingleDatePicker
            label="Fødselsdag"
            fullWidth
            // autocomplete={autocomplete ? "bday" : ""}
            date={member.dob}
            maxDate={new Date()}
            onChange={(date) => handleBaseChange("dob", date)}
          // error={validateBaseField("dob") !== "" && (submitted || touched.base.dob)}
          // helperText={(submitted || touched.base.dob) ? validateBaseField("dob") : ""}
          // onBlur={() => handleBaseBlur("dob")}
          />
        </Grid>
        <Grid item xs={3}>
          <SingleSelectChip
            label="Kjønn"
            inputs={[
              { "id": 1, "name": "Mann" },
              { "id": 2, "name": "Dame" },
              { "id": 3, "name": "Annet" },
            ]}
            selectField={"name"}
            selected={member.sex_id}
            onChange={(id) => handleBaseChange("sex_id", id)}
          // error={validateBaseField("sex_id") !== "" && (submitted || touched.base.sex_id)}
          // onBlur={() => handleBaseBlur("sex_id")}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={member.employee}
                onChange={(e) => handleBaseChange("employee", e.target.checked)}
              />}
            label="Ansatt"
          />
        </Grid>
      </Grid>

      {member.emails?.map((email, index) => {
        const error = validateArrayField("emails", index, "name");
        return (
          <Grid container spacing={1}
            sx={{ pt: 2, justifyContent: "center", alignItems: "center" }}
            key={index}
          >
            <Grid item xs={10}>
              <TextField
                label="e-post"
                required
                fullWidth
                type="email"
                autoComplete={autocomplete ? "email" : ""}
                value={email.name}
                onChange={(e) => handleItemChange(index, e.target.value, 'emails', 'name')}
                variant="outlined"
                error={error !== "" && (submitted || touched.arrays.emails[index]?.name)}
                helperText={(submitted || touched.arrays.emails[index]) ? error : ""}
                onBlur={() => handleArrayBlur("emails", index, "name")}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Sett til primær">
                <Radio checked={email.primary} onChange={() => handlePrimaryChange(index, 'emails')} />
              </Tooltip>
            </Grid>
            <Grid item xs={1}>
              <ClickableIcon title="Fjern" onClick={() => handleRemoveItem(index, 'emails')}>
                <ClearIcon color="error" />
              </ClickableIcon>
            </Grid>
          </Grid>
        );
      })}
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ width: 200 }}
          variant='outlined'
          color='info'
          onClick={() => handleAddItem(newEmail, 'emails')}
          endIcon={<AddIcon color="info" />}
        >
          Legg til e-post
        </Button>
      </Box>

      {member.phones?.map((phone, index) => {
        const error = validateArrayField("phones", index, "name");
        return (
          <Grid container spacing={1}
            sx={{ pt: 2, justifyContent: "center", alignItems: "center" }}
            key={index}
          >
            <Grid item xs={10}>
              <CustomPhoneInput
                value={phone.name}
                onChange={(value) => handleItemChange(index, value, 'phones', 'name')}
                autocomplete={autocomplete}
                error={error !== "" && (submitted || touched.arrays.phones[index]?.name)}
                helperText={(submitted || touched.arrays.phones[index]) ? error : ""}
                onBlur={() => handleArrayBlur("phones", index, "name")}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Sett til primær">
                <Radio checked={phone.primary} onChange={() => handlePrimaryChange(index, 'phones')} />
              </Tooltip>
            </Grid>
            <Grid item xs={1}>
              <ClickableIcon title="Fjern" onClick={() => handleRemoveItem(index, 'phones')}>
                <ClearIcon color="error" />
              </ClickableIcon>
            </Grid>
          </Grid>
        );
      })}
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ width: 200 }}
          variant='outlined'
          color='info'
          onClick={() => handleAddItem(newPhone, 'phones')}
          endIcon={<AddIcon color="info" />}
        >
          Legg til telefon
        </Button>
      </Box>

      {member.addresses?.map((address, index) => {
        const addDivider = index !== member.addresses.length - 1;
        const addressError = validateArrayField("addresses", index, "addressline1");
        const postcodeError = validateArrayField("addresses", index, "postcode");
        const postofficeError = validateArrayField("addresses", index, "postoffice");

        return (
          <>
            <Grid container spacing={1.5}
              sx={{ pt: 2, justifyContent: "center", alignItems: "center" }}
              key={index}
            >
              <Grid item xs={10}>
                <TextField
                  label="Adresselinje 1"
                  required
                  fullWidth
                  type="text"
                  autoComplete={autocomplete ? "street-address address-line1" : ""}
                  value={member.addresses[index].addressline1}
                  onChange={(e) => handleItemChange(index, e.target.value, 'addresses', 'addressline1')}
                  variant="outlined"
                  error={addressError !== "" && (submitted || touched.arrays.addresses[index]?.addressline1)}
                  helperText={(submitted || touched.arrays.addresses[index]) ? addressError : ""}
                  onBlur={() => handleArrayBlur("addresses", index, "addressline1")}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Sett til primær">
                  <Radio checked={address.primary} onChange={() => handlePrimaryChange(index, 'addresses')} />
                </Tooltip>
              </Grid>
              <Grid item xs={1}>
                <ClickableIcon title="Fjern" onClick={() => handleRemoveItem(index, 'addresses')}>
                  <ClearIcon color="error" />
                </ClickableIcon>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Adresselinje 2"
                  fullWidth
                  type="text"
                  autoComplete={autocomplete ? "address-line2" : ""}
                  value={member.addresses[index].addressline2}
                  onChange={(e) => handleItemChange(index, e.target.value, 'addresses', 'addressline2')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Adresselinje 3"
                  fullWidth
                  type="text"
                  autoComplete={autocomplete ? "address-line3" : ""}
                  value={member.addresses[index].addressline3}
                  onChange={(e) => handleItemChange(index, e.target.value, 'addresses', 'addressline3')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Postnummer"
                  required
                  fullWidth
                  type="texte"
                  autoComplete={autocomplete ? "postal-code" : ""}
                  value={member.addresses[index].postcode}
                  onChange={(e) => handleItemChange(index, e.target.value, 'addresses', 'postcode')}
                  variant="outlined"
                  error={postcodeError !== "" && (submitted || touched.arrays.addresses[index]?.postcode)}
                  helperText={(submitted || touched.arrays.addresses[index]) ? postcodeError : ""}
                  onBlur={() => handleArrayBlur("addresses", index, "postcode")}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Poststed"
                  required
                  fullWidth
                  type="text"
                  autoComplete={autocomplete ? "address-level2" : ""}
                  value={member.addresses[index].postoffice}
                  onChange={(e) => handleItemChange(index, e.target.value, 'addresses', 'postoffice')}
                  variant="outlined"
                  error={postofficeError !== "" && (submitted || touched.arrays.addresses[index]?.postoffice)}
                  helperText={(submitted || touched.arrays.addresses[index]) ? postofficeError : ""}
                  onBlur={() => handleArrayBlur("addresses", index, "postoffice")}
                />
              </Grid>
            </Grid>
            {addDivider && <Divider sx={{ pt: 2, mt: 2, mb: 2, borderColor: 'lightgrey' }} />}
          </>
        );
      })}
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ width: 200 }}
          variant='outlined'
          color='info'
          onClick={() => handleAddItem(newAddress, 'addresses')}
          endIcon={<AddIcon color="info" />}
        >
          Legg til adresse
        </Button>
      </Box>
    </form>
  )
});