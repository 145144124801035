import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthenticatedRoutes } from "./AuthenticatedRoutes";
import { authStore } from "../App";
import { Login, NotFound, SaleryStatistics } from "../pages";
import { MemberStatistic } from "../pages/public/statistic/member/MemberStatistic";
import { PersonalData } from "../pages";
import { OidcLogin } from "../pages";
import { PosterPoster } from "../pages/public/poster/pagesOld/Poster";
import { PosterNewsletter } from "../pages/public/poster/pagesOld/Newsletter";
import { PosterTemplate } from "../pages/public/poster/pagesOld/Template";
import { DcpAuthProvider } from "../contexts/DcpAuthContext";
import { CreatePage } from "../pages/public/poster/pagesOld/CreatePage";
import { Edit } from "../pages/public/poster/pagesOld/Edit";
import { CreateTemplate } from "../pages/public/poster/pagesOld/CreateTemplate";
import { WorkingCondition } from "../pages";
import { OidcCallback } from "../pages/public/OidcCallback";
import { HourReg } from "../pages/osloentrep/HourRegistration";
import { MyHours } from "../pages/osloentrep/MyHours";
import { HourAccepted } from "../pages/osloentrep/HoursAccepted";
import { HoursHistory } from "../pages/osloentrep/HoursHistory";
import { HourApproval } from "../pages/osloentrep/HourApproval";
import { HourOverview } from "../pages/osloentrep/HourOverview";

import { ProjectReports } from '../pages/osloentrep/ProjectReports';
import { HourTransfer } from "../pages/osloentrep/HourTransfer";
import { HourSyncData } from "../pages/osloentrep/HourSyncData";


import { SalaryApproval } from "../pages/osloentrep/SalaryApproval";

type Status = "checking" | "authenticated" | "not-authenticated";
let status: Status = "not-authenticated";
// let status: Status = 'authenticated'

export const AppRouter = () => {
  const { isLoggedin, initialLoad, userRoles } = authStore();
  if (!initialLoad) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/oidcLogin/*" element={<OidcLogin />} />
        <Route path="/callback" element={<OidcCallback />} />
        
        <Route path="/HourRegistration/*" element={<HourReg />} />
        <Route path="/MyHours/*" element={<MyHours />} />
        <Route path="/HoursAccepted/*" element={<HourAccepted />} />
        <Route path="/HoursHistory/*" element={<HoursHistory />} />
        <Route path="/HourApproval/*" element={<HourApproval />} />
        <Route path="/HourOverview/*" element={<HourOverview />} />
        <Route path="/ProjectReports/*" element={<ProjectReports />} />
        <Route path="/HourTransfer/*" element={<HourTransfer />} />
        <Route path="/HourSyncData/*" element={<HourSyncData />} />
        <Route path="/SalaryApproval/*" element={<SalaryApproval />} />
        
        

        {/** display only routes that are available when logged in */}
        <Route path="/*" element={<AuthenticatedRoutes />} />

        {/** Routes that should be available all the time */}
        <Route path="/personaldata/*" element={<PersonalData />} />
        <Route path="/personaldata/:uid" element={<PersonalData />} />
        <Route path="/workingCondition/*" element={<WorkingCondition />} />
        <Route path="/workingCondition/:uid" element={<WorkingCondition />} />
        <Route path="/salerystatistics/*" element={<SaleryStatistics />} />
        <Route path="/salerystatistics/:uid" element={<SaleryStatistics />} />
        <Route path="/membersalerystatistics/:uid" element={<MemberStatistic />} />

        {/** Poster routes */}
        <Route path="/poster/*" element={<PosterRoutes />} />

        {/** NOT WORKING, triggers the NotFound path in AuthenticatedRoutes, causing login session to start */}
        {/* <Route path="/:path" element={<NotFound />} />
        <Route path="/*" element={<NotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

const PosterRoutes = () => {
  return (
    <DcpAuthProvider>
      <Routes>
        <Route path="/poster/:uid" element={<PosterPoster />} />{" "}
        {/** [url]/poster/poster/:uid */}
        <Route path="/newsletter/:uid" element={<PosterNewsletter />} />
        <Route path="/template/:uid" element={<PosterTemplate />} />
        <Route path="/create-template/:pageType/:title" element={<CreateTemplate />} />
        <Route path="/create/:pageType/:title/:templateID" element={<CreatePage />} />
        <Route path="/edit/:type/:id" element={<Edit />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </DcpAuthProvider>
  );
};
