import { Alert, Box, Button, Checkbox, CircularProgress, Fade, FormControlLabel, IconButton, Paper, SxProps, TextField, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de';
import { useEffect, useState } from "react";
import { useApi } from "../../services/HttpService";
import CloseIcon from '@mui/icons-material/Close';

const defaultModalStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: '10px 10px 20px 0px rgba(0,0,0,0.7)',
  // padding: '10px 0 0 10px',
  overflowY: 'auto',
  maxHeight: '90vh',
};

const headerStyle: SxProps = {
  fontSize: '1.4rem',
  p: 3,
  marginBottom: '1rem',
  borderBottom: '1px solid grey',
};

const contentStyle: SxProps = {
  p: 2,
};

const footerStyle: SxProps = {
  backgroundColor: 'background.paper',
  padding: '10px',
  position: 'sticky',
  bottom: -1, // keep text from klipping at bottom
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 1,
  zIndex: 1000,
};

interface BaseModalProps {
  open: boolean;
  title?: string;
  isLoading?: boolean;
  children: JSX.Element;
  modalStyle?: SxProps;
  closeOnEscape?: boolean; // close when escape key is pressed
  closeOnBackdropClick?: boolean; // close when backdrop is clicked
  onClose: () => void;
  onSubmit: () => void;
}

export interface changedColumns {
  column: string;
  value: string;
}


// base component for displaying the modal header, body and footer
const BaseModal = (props: BaseModalProps) => {
  let customModalStyle = { ...defaultModalStyle };
  if (props.modalStyle) {
    customModalStyle = { ...customModalStyle, ...props.modalStyle };
  }

  let closeOnEscape = props.closeOnEscape !== undefined ? props.closeOnEscape : true;
  let closeOnBackdropClick = props.closeOnBackdropClick !== undefined ? props.closeOnBackdropClick : true;

  // will not close the modal if a request is being processed.
  const handleSubmit = () => {
    if (props.isLoading) {
      return;
    }
    props.onSubmit();
  }

  const handleClose = (reason?: "backdropClick" | "escapeKeyDown") => {
    if (props.isLoading) {
      return;
    }

    if (reason) {
      if (reason === "backdropClick" && closeOnBackdropClick) {
        props.onClose();
      } else if (reason === "escapeKeyDown" && closeOnEscape) {
        props.onClose();
      }
      return;
    }

    props.onClose();
  }

  return (
    <Modal
      open={props.open}
      onClose={(_, reason) => handleClose(reason)}
    >
      <Fade in={props.open}>
        <Box sx={customModalStyle}>
          <Box sx={headerStyle}>
            {props.title}
            <IconButton
              aria-label="lukk"
              onClick={() => handleClose()}
              sx={(theme) => ({
                position: 'absolute',
                right: 16,
                top: 20,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={contentStyle}>
            {props.children}
          </Box>

          <Paper elevation={6} sx={footerStyle}>
            <Button
              color="darkgrey"
              type="submit"
              variant="contained"
              size="medium"
              disabled={props.isLoading}
              onClick={() => handleClose()}
            >
              Avbryt
            </Button>
            <Button
              title="Submit"
              color="primary"
              type="submit"
              variant="contained"
              size="medium"
              onClick={handleSubmit}
              disabled={props.isLoading}
              endIcon={props.isLoading && <CircularProgress size={22} />}
            >
              Submit
            </Button>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  );
}

export default BaseModal