import { authStore } from "../App";
import localStorageService, { LocalStorageKeys } from "./LocalStorageService";
import { getValidToken } from "./HttpService";

export const AuthService = () => {

  const login = async (token: any): Promise<boolean> => {
    localStorageService.setItem(LocalStorageKeys.OIDC_TOKEN, token);

    return true;
  }

  const authenticate = async (redirect: boolean): Promise<boolean> => {
    let token = await getValidToken(redirect);

    if (!token) {
      return false;
    }

    const response = await fetch("/api/me", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response?.ok) {
      let res = await response.json();

      let username = res.user?.username ? res.user.username :
        res.contact?.first_name ? res.contact.first_name : "";

      authStore.setState({
        isLoggedin: true,
        userRoles: res.roles,
        username: username,
      });
      return true;
    }

    return false;
  }

  const logout = async (): Promise<boolean> => {
    try {
      localStorageService.removeItem(LocalStorageKeys.DCP_TOKENS);
      localStorageService.removeItem(LocalStorageKeys.DCP_USER);

      let token = localStorageService.getItem(LocalStorageKeys.OIDC_TOKEN);
      if (!token) {
        return true;
      }

      const res = await fetch("/api/logout", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        localStorageService.removeItem(LocalStorageKeys.OIDC_TOKEN);
        authStore.setState({ isLoggedin: false, userRoles: [], username: '' });
        console.log("logout success")
        return true;
      } else {
        console.log("logout request failed:", res.statusText);
        return false;
      }
    } catch (err) {
      console.log("logout error:", err);
      return false;
    }
  }

  return { login, authenticate, logout }
};
