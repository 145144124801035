import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Image from 'mui-image';
// import Logo from '../../../images/parat-logo.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from '../../../services/AuthService';
import { DarkModeSwitch } from '../../ui/DarkModeSwitch';
import { authStore, tenantStore } from '../../../App'
import FallbackImage from '../../file/FallbackImage';
import { Backdrop, Divider, Drawer, ListItemText, Paper, Popper, useMediaQuery } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { create } from 'zustand';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menuitem } from '../../../model/System';
import { ClipText } from '../../ui/ClipText';
import MenuList from '@mui/material/MenuList';
import { ClickableIcon } from '../../ui/Icon';
import { ArrowBack } from '@mui/icons-material';
import { createNavigationPath } from '../../../routes/AuthenticatedRoutes';

function Header2() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = decodeURIComponent(location.pathname);
  const { mainRoutes, mainRoutesTree, profileRoutes, username } = authStore();
  const { displayName } = tenantStore();
  const { logout } = AuthService();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState(null); // Tracks which main path is hovered
  const [openMenu, setOpenMenu] = React.useState(false); // Tracks if the menu is open
  const [menuRef, setMenuRef] = React.useState(null); // Ref for the currently active menu
  const [activeSubPaths, setActiveSubPaths] = React.useState<Menuitem[]>([]); // Ref for the currently active sub paths
  const [openPopper, setOpenPopper] = React.useState(false);
  const [disableTouchRouting, setDisableTouchRouting] = React.useState(false);
  const popperRef = React.useRef(null); // Ref for the popper element
  const mainPathRefs = React.useRef([]); // Array of refs for each main path
  const [openMainSideMenu, setOpenMainSideMenu] = React.useState(false);
  const [currentRootMenu, setCurrentRootMenu] = React.useState<Menuitem>(null);

  // set active main menuitem
  React.useEffect(() => {
    const currentMenuitem = mainRoutesTree.flatten().find((menuitem) => createNavigationPath(menuitem.url) === currentPath);
    if (currentMenuitem) {
      setCurrentRootMenu(mainRoutesTree.getRootOfNode(currentMenuitem.id));
    }
  }, [currentPath, mainRoutesTree])

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: '5px',
    // width: 'auto',
    // [theme.breakpoints.up('sm')]: {
    ml: theme.spacing(1),
    width: 'auto',
    // },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      // width: '100%',
      // [theme.breakpoints.up('sm')]: {
      width: '0ch',
      '&:focus': {
        width: '20ch',
      },
      // },
    },
  }));

  // hover with mouse over main navigation buttons
  const handleMouseEnterMain = (event, index, page) => {
    // don't close main menu on mobile
    if (!openPopper) {
      // console.log("closing from mouse enter")
      closeMenu();
    } else if (openMenu) {
      // console.log("closing sub menues")
      setOpenMenu(false);
      return;
    }

    // let mainPath: string = mainPathRefs.current[index].innerHTML.split("<")[0];
    // console.log("entered", mainPath, "pagename", page.name, "openMenu", openMenu);

    if (page.children?.length > 0) {
      setActiveSubPaths(page.children);
      setAnchorEl(event.currentTarget);
      setOpenMenu(true);
      setMenuRef(mainPathRefs.current[index]);
    }
  };

  // Handle navigation on main or sub path click
  const handleNavigationClick = (path, subPath = false) => {
    if (disableTouchRouting && !subPath) {
      return;
    }
    const navigationPath = createNavigationPath(path);
    navigate(navigationPath);
    closeMenu();
  };

  const handleMainSideMenuNavigation = (path: string) => {
    const navigationPath = createNavigationPath(path);
    navigate(navigationPath);
    setOpenMainSideMenu(false);
  }

  // handle main buttons touch on desktop view
  const handleTouchStartMain = (event, index, page) => {
    let subRoutes = page.children || [];
    if (subRoutes.length > 0) {
      setDisableTouchRouting(true);
      if (openMenu && anchorEl === mainPathRefs.current[index]) {
        setDisableTouchRouting(false);
        handleNavigationClick(page.url);
      } else {
        setActiveSubPaths(page.children);
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
        setMenuRef(mainPathRefs.current[index]);
      }
    } else {
      setDisableTouchRouting(false);
      handleNavigationClick(page.url);
    }
  };

  const closeMenu = () => {
    setOpenMenu(false);
    setAnchorEl(null);
    setMenuRef(null);
    setAnchorElNav(null);
    setOpenPopper(false);
  };

  // Store refs dynamically for multiple main paths
  const addMainPathRef = (element, index) => {
    mainPathRefs.current[index] = element;
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setOpenPopper(!openPopper);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const closePopper = () => {
    setOpenPopper(false);
    setAnchorEl(null);
  };

  const handleProfileNavigation = (page: string) => {
    if (page === 'Logout') {
      logout().then(success => {
        if (success) {
          navigate('/?authenticate=true');
        }
      });
    } else {
      handleCloseUserMenu();
      const navigationPath = createNavigationPath(page);
      navigate(navigationPath);
    }
  }

  const renderTree = (node: Menuitem, level: number = 0) => {
    const paddingLeft = 10 * level;
    const isActive = decodeURIComponent(location.pathname) === node.url;
    const darkmode = theme.palette.mode === "dark";

    let bg = "inherit";
    if (isActive) {
      bg = darkmode ? "rgba(255, 255, 255, 0.21)" : "rgba(0, 0, 0, 0.2)"
    }

    return (
      <Box key={node.id}>
        <MenuItem sx={{
          pl: { paddingLeft },
          minHeight: 32,
          backgroundColor: bg,
          boxShadow: isActive ? "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" : "none",
          "&:hover": {
            backgroundColor: darkmode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
          },
        }}
          onClick={() => handleMainSideMenuNavigation(node.url)}
        >
          <ListItemText sx={{ pl: { paddingLeft } }}>{node.name}</ListItemText>
        </MenuItem>
        {node.children?.map((child: Menuitem) => renderTree(child, level + 1))}
      </Box>
    )
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main, backgroundImage: 'none' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* style={{ marginRight: "5px", display: Boolean(anchorElUser) ? 'block' : 'none' }} */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', height: "34px", cursor: 'pointer' }} onClick={() => navigate('/home')}>
            <Box sx={{ mr: 1, width: "26px", height: "26px" }}>
              <FallbackImage
                primarySrc="/api/static/logo"
                secondarySrc="/default/logo.png"
                altText="logo"
              />
              {/* <Image src={Logo} width="26px" height="34px" duration={0} shiftDuration={0} /> */}
            </Box>
            <Typography
              variant="h6"
              noWrap
              // component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: displayName.length > 6 ? '.1rem' : '.3rem',
                color: theme.palette.getContrastText(theme.palette.primary.main),
                textDecoration: 'none',
              }}
            >
              {displayName.toUpperCase()}
            </Typography>
          </Box>

          {/* Mobile navbar */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <ClickableIcon
              title="open menu"
              size="large"
              onClick={() => setOpenMainSideMenu(true)}
            >
              <MenuIcon sx={{ color: theme.palette.getContrastText(theme.palette.primary.main) }} />
            </ClickableIcon>
            <Drawer
              anchor="left"
              open={openMainSideMenu}
              onClose={() => setOpenMainSideMenu(false)}
              PaperProps={{ sx: { width: "80vw", minWidth: 300, position: "relative" } }}
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenMainSideMenu(false);
                }}
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(255, 255, 255, 0.8)",
                  border: theme.palette.mode === "light" ? "1px solid black" : "none",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <ArrowBack sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }} />
              </IconButton>
              <Box sx={{ py: 2, width: "100%" }}>
                <MenuList>
                  {mainRoutes.map((item) => renderTree(item, 1))}
                </MenuList>
              </Box>
            </Drawer>
            {openMainSideMenu && (
              <Backdrop
                open={openMainSideMenu}
                onClick={() => setOpenMainSideMenu(false)}
                sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
              />
            )}
            {/* <Popper
              id="menu-appbar"
              anchorEl={anchorElNav}
              open={openPopper}
              ref={anchorElNav}
            >
              <Paper elevation={6} sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#10151a' : '#eaeaea', }}>
                {mainRoutes.map((page, index) => {
                  return (
                    <Box key={index} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <MenuItem key={index} sx={{ width: "100%" }} onClick={() => handlePopperNavigationClick(page.url)}>
                        <Typography textAlign="center">{page.name}</Typography>
                      </MenuItem>
                      {page.children && page.children.length > 0 &&
                        <IconButton onClick={(event) => handleMouseEnterMain(event, index, page)}>
                          <ChevronRightIcon sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }} />
                        </IconButton>
                      }
                    </Box>
                  )
                })}
              </Paper>
            </Popper> */}
          </Box>

          {/* <Box onClick={() => navigate('/home')}> */}
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
            onClick={() => navigate('/home')}
          >
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
              <Box sx={{ width: "26px", height: "26px" }}>
                <FallbackImage
                  primarySrc="/api/static/logo"
                  secondarySrc="/default/logo.png"
                  altText="logo"
                />
              </Box>
            </Box>
            <Typography
              variant="h5"
              noWrap
              onClick={() => navigate('/home')}
              sx={{
                mr: { xs: 0, md: 2 },
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: { xs: 500, sm: 700 },
                fontSize: { xs: 16, sm: 24 },
                letterSpacing: { xs: 1, sm: 1.6 },
                color: theme.palette.getContrastText(theme.palette.primary.main),
                textDecoration: 'none',
                whiteSpace: "nowrap",
                maxWidth: { xs: "100px", sm: "450px" },
                transition: "max-width 0.3s ease-in-out, font-size 0.3s ease-in-out, letter-spacing 0.3s ease-in-out",
              }}
            >
              {displayName.toUpperCase()}
            </Typography>
          </Box>

          {/* Desktop navbar */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {mainRoutes.map((page, index) => {
              let isActive = false;
              if (currentRootMenu) {
                isActive = page.id === currentRootMenu.id;
              }

              return (
                <Button
                  key={index}
                  aria-haspopup="true"
                  onMouseOver={(event) => handleMouseEnterMain(event, index, page)}
                  onTouchStart={(event) => handleTouchStartMain(event, index, page)}
                  onClick={() => handleNavigationClick(page.url)}
                  ref={(element) => addMainPathRef(element, index)} // Dynamic ref assignment
                  sx={{
                    my: 2, display: 'flex', cursor: 'pointer',
                    "& .MuiButton-endIcon": { m: 0 },
                    color: theme.palette.getContrastText(theme.palette.primary.main),
                    textTransform: "none",
                    transition: "all 0.3s ease",
                    backgroundColor: isActive ? theme.palette.primary.light : "inherit",
                    boxShadow: isActive ? "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" : "none",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                  }}
                  endIcon={page.children && page.children.length > 0 && <ArrowDropDownIcon />}
                >
                  {page.name}
                </Button>
              )
            })}
          </Box>
          <Popper
            open={openMenu}
            anchorEl={anchorEl}
            onMouseLeave={() => {
              // console.log("leave popper"); 
              closeMenu()
            }}
            placement="bottom-start"
            ref={(element) => setMenuRef(element)}
            sx={{ minWidth: 200, zIndex: '999' }}
          >
            <Paper>
              {activeSubPaths.map((page, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => handleNavigationClick(page.url, true)}
                    sx={{
                      display: 'block',
                      cursor: 'pointer',
                      "&:hover": {
                        backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "inherit",
                      },
                    }}
                  >
                    {page.name}
                  </MenuItem>
                )
              })}
            </Paper>
          </Popper>

          {/* <Box>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box> */}
          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} size='small'>
                  {/* @todo: add profile pic eks: src="/static/public/avatar/2.png" */}
                  <Avatar alt={username + "-avatar"} sx={{ width: 32, height: 32 }} />
                </IconButton>
              </Tooltip>
              <ClipText width={useMediaQuery(theme.breakpoints.up("md")) ? 100 : 150} sx={{ color: theme.palette.getContrastText(theme.palette.primary.main), fontSize: "11px", textAlign: "right", letterSpacing: 0.4 }}>
                {username}
              </ClipText>
            </Box>
            <Menu
              sx={{ mt: '45px', minHeight: '200px', zIndex: '999' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key='darkMode'>
                <DarkModeSwitch />
              </MenuItem>
              {profileRoutes.map((page, index) => (
                <MenuItem key={index} onClick={() => handleProfileNavigation(page.url)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem key={'Logout'} onClick={() => handleProfileNavigation('Logout')}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container >
    </AppBar >
  );
}
export default Header2;