import { ReactNode } from "react";
import { Box, Paper, SxProps, Theme, Typography, useTheme } from "@mui/material";

interface ClipTextProps {
  children?: ReactNode;
  width: number;
  sx?: SxProps<Theme>;
}

export function ClipText(props: ClipTextProps) {
  const { children, width, sx } = props;

  return (
    <Box sx={{ width: width }}>
      <Typography variant='body1'
        sx={{
          maxWidth: width,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          ...sx,
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}