import { Alert, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useApi } from '../../../../services/HttpService';
import { InfoArea } from '../../../../components/ui/InfoArea';


export const MemberAccess = () => {
  const { api, error, isLoading } = useApi();

  useEffect(() => {

  }, []);

  const setDefault = () => {
  }

  const reset = () => {

  }

  const saveSettings = () => {

  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
      <InfoArea title='Access'>
        <Typography variant='body1' sx={{ mt: 2 }}>Will display the access of the selected member</Typography>
      </InfoArea>

      {error && <Alert severity="error">{error}</Alert>}

      {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant='contained' color='info' onClick={setDefault}>Default</Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
          <Button variant='contained' color='darkgrey' onClick={reset}>Reset</Button>
          <Button
            variant='contained'
            color='info'
            onClick={saveSettings}
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={22} />}
          >
            Save
          </Button>
        </Box>
      </Box> */}
    </Box>
  )
}