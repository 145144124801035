import React, { useState, useRef, useEffect } from 'react';
import { Alert, AlertColor, CircularProgress, Link, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { Box, useTheme } from '@mui/material';
import localStorageService, { LocalStorageKeys } from '../../services/LocalStorageService';
import { AuthService } from '../../services/AuthService';
import { authStore } from '../../App';

const sleep = ms => new Promise(r => setTimeout(r, ms));

export const OidcCallback = () => {
  const [isloading, setIsloading] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { login, authenticate, logout } = AuthService();

  useEffect(() => {
    const getToken = async () => {
      // Parse the URL to get the access_token
      const params = new URLSearchParams(window.location.search);
      const token = params.get("access_token");
      const path = params.get("path");
      if (!token) {
        setMessage("Access token not found in response");
        return;
      }

      await login(token);

      // await authenticate(false).then(success => {
      //   if (!success) {
      //     logout();
      //     navigate("/", { replace: true });
      //   } else {

      // navigate back to where login was instantiated from
      if (path && path !== "") {
        navigate(path, { replace: true });
      } else {
        navigate("/home", { replace: true });
      }
      //   }
      // });

      setIsloading(false);
    }

    getToken();
  }, [navigate]);

  return (
    <>
      <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {isloading ? (
          <CircularProgress color="info" />
        ) : (
          <>
            {message && (
              <Box sx={{ width: 400, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Alert severity='error'>{message}</Alert>
                <Link href="/">Tilbake</Link>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  )
}