import { ProjectContact, YearWeek, DayHours } from "../../model/Hour";
import { CenterSpinner } from "../../components/ui/CenterSpinner";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  AlertColor,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Alert,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useApi } from "../../services/HttpService";
import { NumberFormat } from "../../components/ui/NumberFormat";

export const SalaryApproval = () => {
  const { api: hourRegistrationsApi, response: hourRegistrationsResponse, isLoading: hourRegistrationsIsLoading, error: hourRegistrationsError } = useApi();
  const { api: transferHourApi, response: transferHourApiResponse, isLoading: transferHourApiIsLoading, error: transferHourApiError } = useApi();
  const [data, setData] = useState<ProjectContact[]>([]);
  const [filteredData, setFilteredData] = useState<ProjectContact[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedHours, setSelectedHours] = useState<DayHours[]>([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [messageType, setMessageType] = useState<AlertColor>("error");
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false); // Dialog open state
  const [message, setMessage] = useState<string>("");

  const getHours = () => {
    hourRegistrationsApi("/hour/getSalaryApprovalData", "GET")
      .then((res) => {
        if (res && res.statusCode === 200) {
          setData(res.data.salary_hours);
          setFilteredData(res.data.salary_hours); 
        }
      });
  };

  const transferHours = async () => {
    setConfirmOpen(false); // Close confirmation dialog
    await transferHourApi("/hour/postHourAddToSalary", "POST").then((res) => {
      if (res && res.statusCode === 200) {
        getHours();
        setDisplayMessage("Timene er overført!", "success");
      } else {
        if (res.error) {
          if (res.error.code === "1") {
            setDisplayMessage("Feil under overføring av timer. Venligst prøv igjen.", "error");
          } else if (res.error.code === "2") {
            setDisplayMessage("Feil under overføring av timer. Venligst prøv igjen.", "error");
          } else if (res.error.code === "3") {
            setDisplayMessage("Feil under overføring av timer. Venligst prøv igjen.", "error");
          }
        }
      }
    });
  };

  useEffect(() => {
    getHours();
  }, []);

  
  const setDisplayMessage = (message: string, type: AlertColor) => {
    setMessage(message);
    setMessageType(type);
    // setTimeout(() => setMessage(""), 5000); // Clear message after 5 seconds
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredData(data);
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      const filtered = data.filter(
        (entry) =>
          entry.hour_project.toLowerCase().includes(lowerQuery) ||
          entry.contact.toLowerCase().includes(lowerQuery)
      );
      setFilteredData(filtered);
    }
  }, [searchQuery, data]);

  const handleOpen = (registrations: DayHours[], project: string, contact: string) => {
    setSelectedHours(registrations);
    setSelectedProject(project);
    setSelectedContact(contact);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const allWeeks = Array.from(new Set(
    filteredData.flatMap((entry) =>
      entry.year_week.map((week) => `${week.hour_year}-${String(week.hour_week).padStart(2, '0')}`)
    )
  )).sort();

  const totalHoursPerWeek: Record<string, number> = {};
  let grandTotal = 0;

  filteredData.forEach((entry) => {
    entry.year_week.forEach((week) => {
      const key = `${week.hour_year}-${String(week.hour_week).padStart(2, '0')}`;
      const weekTotal = week.days.reduce((sum, day) => sum + day.hours, 0);
      totalHoursPerWeek[key] = (totalHoursPerWeek[key] || 0) + weekTotal;
      grandTotal += weekTotal;
    });
  });

  return (
    <Box>
    <TableContainer component={Paper} sx={{ mt: 2, p: 2 }}>
      <Box display="flex" justifyContent="center" mb={2}>
        <TextField
          label="Søk på prosjekt/ansatt"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>
      {/* Success/Error Message */}
      {message && <Alert severity={messageType}>{message}</Alert>}

      <CenterSpinner loading={hourRegistrationsIsLoading || transferHourApiIsLoading} />
      {!hourRegistrationsIsLoading && (
        data ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Prosjekt</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Ansatt</TableCell>
              {allWeeks.map((week) => (
                <TableCell key={week} sx={{ fontWeight: "bold" }}>{week}</TableCell>
              ))}
              <TableCell sx={{ fontWeight: "bold" }}>Sum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((entry, index) => {
              const totalHours = entry.year_week.reduce(
                (sum, week) => sum + week.days.reduce((wSum, day) => wSum + day.hours, 0), 0
              );

              return (
                <TableRow key={index}>
                  <TableCell>{entry.hour_project}</TableCell>
                  <TableCell>{entry.contact}</TableCell>
                  {allWeeks.map((weekKey) => {
                    const week = entry.year_week.find(
                      (w) => `${w.hour_year}-${String(w.hour_week).padStart(2, '0')}` === weekKey
                    );
                    const weekHours = week ? week.days.reduce((sum, day) => sum + day.hours, 0) : 0;
                    return (
                      <TableCell key={weekKey} align="center">
                        {weekHours > 0 ? (
                          <Button
                            variant="text"
                            color="inherit"
                            size="medium"
                            onClick={() => handleOpen(week?.days || [], entry.hour_project, entry.contact)}
                          >
                            {NumberFormat(weekHours)}
                          </Button>
                        ) : "-"}
                      </TableCell>
                    );
                  })}
                  <TableCell align="center">{NumberFormat(totalHours)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow sx={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
              <TableCell colSpan={2}>Totalt</TableCell>
              {allWeeks.map((weekKey) => (
                <TableCell key={weekKey} align="center">{NumberFormat(totalHoursPerWeek[weekKey] || 0)}</TableCell>
              ))}
              <TableCell align="center">{NumberFormat(grandTotal)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        ) : (
          <Typography variant="body1" align="center">
            Ingen timer tilgjengelig for overføring.
          </Typography>
        ))
      }

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            {selectedProject} - {selectedContact}
          </Typography>
          {selectedHours.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Dato</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Timer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedHours.map((hour) => (
                    <TableRow key={hour.id}>
                      <TableCell>{new Date(hour.hour_date).toLocaleDateString()}</TableCell>
                      <TableCell>{NumberFormat(hour.hours)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : <Typography>Ingen timeregistrering tilgjengelig.</Typography>}
        </DialogContent>
      </Dialog>
    </TableContainer>

    <Box display="flex" justifyContent="center" mb={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setConfirmOpen(true)}
        disabled={!data}
        sx={{ mt: 2}}
      >
        Overfør timer
      </Button>
    </Box>
 
    {/* Confirmation Dialog */}
    <Dialog
    open={confirmOpen}
    onClose={() => setConfirmOpen(false)}
  >
    <DialogTitle>Bekreft overføring av timer</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Er du sikker du vil overføre alle godkjente Timer?
        {/* : {currentWeek?.Week}, {currentWeek?.Year}? */}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setConfirmOpen(false)} color="secondary">
        Avbryt
      </Button>
      <Button onClick={transferHours} color="primary" autoFocus>
        Bekreft
      </Button>
    </DialogActions>
  </Dialog>
  </Box>
  );
};
