import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Alert, Autocomplete, Box, Button, Checkbox, Divider, FormControlLabel, Grid, Radio, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { ClickableIcon } from "../../../../components/ui/Icon";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { SingleSelectChip, SingleSelectSearch, VirtualizedAutocomplete } from "../../../../components/ui/DropdownSelectChip";
import { SingleDatePicker } from "../../../../components/ui/SingleDatePicker";
import { Member, Membership, MemberType, Organization, Tree, Company } from "../../../../model";
import { createHierarchy } from "../../../../model/System";

interface MembershipFormProps {
  membership: Membership;
  memberTypes: MemberType[];
  organizations: Organization[];
  companies: Company[];
  error?: string;
}

export interface MembershipFormRef {
  valid: () => boolean;
  getMembership: () => Membership;
}

export const MembershipForm = forwardRef((props: MembershipFormProps, ref) => {
  const { error } = props;
  const [membership, setMembership] = useState<Membership>({} as Membership);
  const [memberTypes, setMemberTypes] = useState<MemberType[]>([]);
  const [orgTree, setOrgTree] = useState<Tree<Organization>>(new Tree([]));
  const [companies, setCompanies] = useState<Company[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const [touched, setTouched] = useState<{
    base: Record<string, boolean>;
  }>({
    base: {},
  });

  useEffect(() => {
    setMembership(props.membership);
    setSubmitted(false);
    setTouched((prev) => ({
      ...prev,
      base: {},
    }));
  }, [props.membership])

  useEffect(() => {
    setMemberTypes(props.memberTypes);
  }, [props.memberTypes])

  useEffect(() => {
    setOrgTree(new Tree(props.organizations));
  }, [props.organizations])

  useEffect(() => {
    setCompanies(props.companies);
  }, [props.companies])

  const valid = () => {
    let hasErrors = false;

    // Validate all base fields
    for (const key of Object.keys(membership) as (keyof Membership)[]) {
      if (typeof membership[key] !== "object" && validateBaseField(key)) {
        hasErrors = true;
      }
    }

    setSubmitted(true);
    return !hasErrors;
  }

  const getMembership = () => {
    return membership;
  }

  // Expose the valid function to the parent, and a method to get the whole membership object
  useImperativeHandle(ref, () => ({
    valid,
    getMembership,
  }));

  const validateBaseField = (field: keyof Membership): string => {
    if (field === "organization_id") {
      if (!membership.organization_id) return "Organisasjon må velges";
    }
    if (field === "member_type_id") {
      if (!membership.member_type_id) return "Medlemsskap type må velges";
    }
    if (field === "company_id") {
      if (!membership.company_id) return "Bedrift må velges";
    }
    return "";
  };

  const handleBaseChange = (field: keyof Membership, value: any) => {
    setMembership((prev) => ({ ...prev, [field]: value }));
  };

  const handleBaseBlur = (field: keyof Membership) => {
    setTouched((prev) => ({
      ...prev,
      base: { ...prev.base, [field]: true },
    }));
  };

  return (
    <form>
      {error && <Alert sx={{ width: '100%' }} severity="error">{error}</Alert>}

      <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
        <Grid item xs={12}>
          <SingleSelectSearch
            label="Organisasjon"
            inputs={orgTree.flatten()}
            selectField="name"
            required
            selected={membership.organization_id}
            onChange={(id) => handleBaseChange("organization_id", id)}
            error={validateBaseField("organization_id") !== "" && (submitted || touched.base.organization_id)}
            onBlur={() => handleBaseBlur("organization_id")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SingleSelectSearch
            label="Medlemsskap"
            inputs={memberTypes}
            selectField="name"
            required
            selected={membership.member_type_id}
            onChange={(id) => handleBaseChange("member_type_id", id)}
            error={validateBaseField("member_type_id") !== "" && (submitted || touched.base.member_type_id)}
            onBlur={() => handleBaseBlur("member_type_id")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SingleSelectSearch
            label="Bedrift"
            inputs={companies}
            selectField="name"
            required
            selected={membership.company_id}
            onChange={(id) => handleBaseChange("company_id", id)}
            error={validateBaseField("company_id") !== "" && (submitted || touched.base.company_id)}
            onBlur={() => handleBaseBlur("company_id")}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <VirtualizedAutocomplete
            label="test"
            selected={membership.company_id}
            options={companies}
            selectField="name"
            onSelect={(id) => handleBaseChange("company_id", id)}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <SingleDatePicker
            label="Fra dato"
            fullWidth
            date={membership.from_date}
            onChange={(date) => handleBaseChange("from_date", date)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SingleDatePicker
            label="Til dato"
            fullWidth
            date={membership.to_date}
            onChange={(date) => handleBaseChange("to_date", date)}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
              // checked={membership.employee}
              // onChange={(e) => handleBaseChange("employee", e.target.checked)}
              />}
            label="Direkte medlem"
          />
        </Grid> */}

      </Grid>
    </form>
  )
});