import { ReactNode } from "react";
import { IconButton, SxProps, Theme, Tooltip, useTheme } from "@mui/material";

interface ClickableIconProps {
  title: string;
  children?: ReactNode;
  disabled?: boolean;
  disableInteractive?: boolean;
  size?: "small" | "medium" | "large";
  outerSX?: SxProps<Theme>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ClickableIcon(props: ClickableIconProps) {
  const { title, children, disabled, disableInteractive, size = "medium", outerSX, onClick } = props;
  const theme = useTheme();
  const padding = size === "large" ? 1.5 : size === "medium" ? 1 : 0.2;

  return (
    <Tooltip title={title} disableInteractive={disableInteractive} sx={{...outerSX}}>
      <span>
        <IconButton
          disabled={disabled}
          onClick={(event) => onClick(event)}
          edge="end"
          color='inherit'
          size={size}
          sx={{ p: padding, "&:hover": { backgroundColor: theme.palette.mode === "dark" ? "#232323" : "" } }}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  )
}