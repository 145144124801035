import { Alert, AlertTitle, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useApi } from '../../../services/HttpService';
import { InfoArea } from '../../../components/ui/InfoArea';
import { Accessgroup, flattenHierarchy, newAccessgroup } from '../../../model/System';
import { DashedTreeItem } from '../../../components/ui/DashedTreeItem';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { SimpleTreeView } from '@mui/x-tree-view';
import DeleteIcon from '@mui/icons-material/Delete';
import HardDeleteDialog from '../../../components/modals/dialog/HardDeleteDialog';
import { CheckBox } from '@mui/icons-material';
import { ClickableIcon } from '../../../components/ui/Icon';
import { SingleSelectChip } from '../../../components/ui/DropdownSelectChip';


export const RoleSettings = () => {
  const theme = useTheme();
  const { api, response, error, isLoading } = useApi();
  const [flatAccessgroups, setFlatAccessgroups] = useState<Accessgroup[]>([]);
  const [editAccessgroup, setEditAccessgroup] = useState<Accessgroup>(newAccessgroup);
  const [nameError, setNameError] = useState(false);
  const [accessgroups, setAccessgroups] = useState<Accessgroup[]>([]);
  const [expandedAccessgroups, setExpandedAccessgroups] = useState<string[]>([]);
  const [selected, setSelected] = useState<Accessgroup>({} as Accessgroup);
  const [openDelete, setOpenDelete] = useState(false);

  const editRef = useRef(null); // scroll to edit on edit click
  const executeScroll = () => editRef.current.scrollIntoView();

  useEffect(() => {
    api("/accessgroup");
    clearAccessgroup();
  }, []);

  useEffect(() => {
    if (response?.statusCode === 200) {
      setFlatAccessgroups(response?.accessgroups);
      createAccessgroupHirarchy(response);
    }
  }, [response])

  const createAccessgroupHirarchy = (res) => {
    const accessgroupMap = new Map<number, Accessgroup>();

    res?.accessgroups?.forEach(item => {
      let children: Accessgroup[] = [];
      item.children = children;
      item.isAccessgroup = true;
      accessgroupMap.set(item.id, item);
    });

    const hierarchy: Accessgroup[] = [];

    res?.accessgroups?.forEach(item => {
      if (item.parent_id === 0) {
        hierarchy.push(item);
      } else {
        const parent = accessgroupMap.get(item.parent_id);
        if (parent) {
          parent.children.push(item);
        }
      }
    });

    setAccessgroups(hierarchy);
  }

  const clearAccessgroup = () => {
    setEditAccessgroup(newAccessgroup);
    setNameError(false);
  }

  const postAccessgroup = () => {
    if (editAccessgroup.name.trim() === "") {
      setNameError(true);
      return;
    }

    api("/accessgroup", "POST", editAccessgroup).then(res => {
      if (res.statusCode === 200) {
        clearAccessgroup();
      }
    });
  }

  const handleExpandedAccessgroupsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setExpandedAccessgroups(itemIds);
  };

  const handleExpandAccessgroupsClick = () => {
    setExpandedAccessgroups((oldExpanded) =>
      oldExpanded.length === 0
        ? flatAccessgroups.map(v => v.name)
        : []
    );
  };

  const handleSettingsClick = (event, accessgroup) => {
    event.stopPropagation();
    setEditAccessgroup(accessgroup);
    setNameError(false);
    executeScroll();
  }

  const handleDelete = () => {
    api("/accessgroup/" + selected.id, "DELETE").then(() => {
      setSelected({} as Accessgroup);
      setOpenDelete(false);
    });
  }

  const handleDeleteClick = (event, accessgroup) => {
    event.stopPropagation();
    setSelected(accessgroup);
    setOpenDelete(true);
  }

  function recursiveMenuItem(accessgroup: Accessgroup, level = 0): ReactElement[] {
    const name = "- ".repeat(level) + accessgroup.name;
    const items = [
      <MenuItem key={accessgroup.id} value={accessgroup.id} disabled={accessgroup.id === editAccessgroup.id}>
        {name}
      </MenuItem>
    ];

    if (accessgroup.children && accessgroup.children.length > 0) {
      accessgroup.children.forEach(child => {
        items.push(...recursiveMenuItem(child, level + 1));
      });
    }

    return items;
  }

  const flattenTree = (tree, depth = 0, prefix = "-") => {
    let result = [];
  
    for (const node of tree) {
      result.push({
        id: node.id,
        name: `${prefix.repeat(depth)} ${node.name}`,
      });
  
      if (node.children && node.children.length > 0) {
        result = result.concat(flattenTree(node.children, depth + 1, prefix));
      }
    }
  
    return result;
  };

  const getDuplicateRoleNames = (accessgroups: Accessgroup[]) => {
      const roleNames = accessgroups.map(accessgroup => accessgroup.name);
      const duplicates = roleNames.filter((name, index) => roleNames.indexOf(name) !== index);
  
      if (duplicates.length === 0) {
        return <></>;
      }
  
      return (
        duplicates.map((duplicate, index) => (
          <Alert severity='warning' variant='outlined' key={index} sx={{ mt: 1, width: '100%' }}>
            Det finnes flere tilgangsgrupper med navnet <strong>{duplicate}</strong>
          </Alert>
        ))
      )
    }

  function recursiveTree(item: Accessgroup) {
    return (
      <Box key={item.name}>
        <DashedTreeItem
          sx={{ width: '100%' }}
          itemId={item.name}
          onDoubleClick={(event) => handleSettingsClick(event, item)}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant='body1'>{item.name}</Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <ClickableIcon title="Rediger" disableInteractive={true} onClick={(event) => handleSettingsClick(event, item)}>
                  <ManageAccountsIcon />
                </ClickableIcon>
                {/* {item.id > 1 && ( */}
                <ClickableIcon title="Slett" disabled={item.id === 1} disableInteractive={true} onClick={(event) => handleDeleteClick(event, item)}>
                  <DeleteIcon color='error' />
                </ClickableIcon>
                {/* )} */}
              </Box>
            </Box>
          }
        >
          {item.children.map(child => recursiveTree(child))}
        </DashedTreeItem>
      </Box>
    )
  }

  return (
    <>
      <HardDeleteDialog
        open={openDelete}
        bodyText={`Er du sikker på at du vil slette tilgangsgruppen "${selected?.name}"${selected?.children?.length > 0 ? ", dette vil også slette dens undergrupper" : ""}? Trykk på "SLETT" for å bekrefte.`}
        loading={isLoading}
        errorMessage={error}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => handleDelete()}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        <Typography variant='h5' sx={{ mt: 2 }}>Tilgangsgrupper innstillinger</Typography>

        {error && <Alert sx={{ width: "100%" }} severity="error">{error}</Alert>}
        {getDuplicateRoleNames(flattenHierarchy(accessgroups))}

        <Box ref={editRef} sx={{ width: "100%" }}>
          {editAccessgroup && (
            <InfoArea title={editAccessgroup.id > 0 ? "Rediger tilgangsgruppe" : "Opprett tilgangsgruppe"}>
              <TextField
                label="Navn"
                required
                value={editAccessgroup.name}
                onChange={(e) => {
                  setEditAccessgroup({ ...editAccessgroup, name: e.target.value })
                  e.target.value.trim() === "" ? setNameError(true) : setNameError(false);
                }}
                variant="outlined"
                error={nameError}
                helperText={nameError ? "Navn kan ikke være tomt" : ""}
              />
              <SingleSelectChip
                label={'Overgruppe'}
                inputs={flattenTree(accessgroups)}
                disabledItems={[editAccessgroup.id]}
                selectField={'name'}
                selected={editAccessgroup.parent_id}
                onChange={(id: number) => setEditAccessgroup({ ...editAccessgroup, parent_id: id })}
              />
              <FormGroup sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editAccessgroup.employee}
                      onChange={(e) => setEditAccessgroup({ ...editAccessgroup, employee: e.target.checked })}
                    />}
                  label="Ansatt"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editAccessgroup.member}
                      onChange={(e) => setEditAccessgroup({ ...editAccessgroup, member: e.target.checked })}
                    />}
                  label="Medlem"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editAccessgroup.tillitsvalgt}
                      onChange={(e) => setEditAccessgroup({ ...editAccessgroup, tillitsvalgt: e.target.checked })}
                    />}
                  label="Tillitsvalgt"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editAccessgroup.company}
                      onChange={(e) => setEditAccessgroup({ ...editAccessgroup, company: e.target.checked })}
                    />}
                  label="Bedrift"
                />
              </FormGroup>

              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 2, p: 2 }}>
                {editAccessgroup.id > 0 && (
                  <Button variant='contained' color='darkgrey' onClick={clearAccessgroup}>Avbryt</Button>
                )}
                <Button
                  variant='contained'
                  color='info'
                  onClick={postAccessgroup}
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={22} />}
                >
                  {editAccessgroup.id > 0 ? "Rediger" : "Opprett"}
                </Button>
              </Box>
            </InfoArea>
          )}
        </Box>

        <InfoArea title='Tilgangsgrupper'>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Button variant='outlined' onClick={handleExpandAccessgroupsClick} sx={{ width: 150, fontWeight: "bold" }}>
              {expandedAccessgroups.length === 0 ? 'Vis alle' : 'Skjul'}
            </Button>
          </Box>
          <SimpleTreeView
            expandedItems={expandedAccessgroups}
            onExpandedItemsChange={handleExpandedAccessgroupsChange}
          >
            {accessgroups?.map((accessgroup: Accessgroup) => (
              recursiveTree(accessgroup)
            ))}
          </SimpleTreeView>
        </InfoArea>
      </Box>
    </>
  )
}