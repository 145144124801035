import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UseIcon from "@mui/icons-material/TouchApp";
import { ArrowDropDown, GroupRounded } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { TemplateResponse, RoleResponse } from "../pagesNew/interfaces";
import { useTranslation } from "react-i18next";
import { convertHtmlToPdf } from "../lib/convertToPdf";
import { MultiSelect } from "./MultiSelect";
import checkPermission from "./permissions";
import { useDcpAuth } from "../../../../contexts/DcpAuthContext";
import { useDcpApi, availablePaths } from "../../../../services/DcpService";

interface TemplateProps {
  template: TemplateResponse;
  isDownloadPage?: boolean;
  currentTemplate?: TemplateResponse[];
  setCurrentTemplate?: Function;
  layout: string;
}

const TemplateCard: React.FC<TemplateProps> = ({
  template,
  isDownloadPage,
  currentTemplate,
  setCurrentTemplate,
  layout,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(template.title);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [roleObj, setRoleObj] = useState<{ id: number; name: string }[]>([]);
  const [roleID, setRoleID] = useState<number[]>([]);
  const [assignRoleChangeData, setAssignRoleChangeData] = useState<{
    new: number[];
    delete: number[];
  }>(null);
  const [newAssignedRole, setNewAssignedRole] = useState<number[]>([]);
  const [templateRole, settTemplateRole] = useState<string[]>([]);
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const navigate = useNavigate();
  const { role, isAuthenticated, authIsLoading } = useDcpAuth();
  const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();
  const {
    dcpApi: dcpRoleApi,
    dcpResponse: dcpRoleResponse,
    dcpIsLoading: dcpRoleIsLoading,
    dcpError: dcpRoleError,
  } = useDcpApi();
  const {
    dcpApi: dcpAssignApi,
    dcpResponse: dcpAssignResponse,
    dcpIsLoading: dcpAssignIsLoading,
    dcpError: dcpAssignError,
  } = useDcpApi();
  const {
    dcpApi: dcpAssignRoleApi,
    dcpResponse: dcpAssignRoleResponse,
    dcpIsLoading: dcpAssignRoleIsLoading,
    dcpError: dcpAssignRoleError,
  } = useDcpApi();
  const {
    dcpApi: deleteApi,
    dcpResponse: deleteResponse,
    dcpIsLoading: deleteIsLoading,
    dcpError: deleteError,
  } = useDcpApi();

  const { t } = useTranslation("TemplateCardPage");

  useEffect(() => {
    if (isAuthenticated) {
      // fetchUserInfo();
      fetchRoles();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (roleID.length > 0) {
      let temp: string[] = [];
      roleID.map((id) => {
        const op = roleObj.find((option) => option.id === id);
        temp.push(op?.name!);
      });
      settTemplateRole(temp);
    }
  }, [roleID]);

  const fetchRoles = async () => {
    await dcpRoleApi(availablePaths.roles.getRoleList(), "GET");
  };

  useEffect(() => {
    if (dcpRoleResponse) {
      if (!dcpRoleResponse.error && dcpRoleResponse.data.list) {
        let tempRoleObj: { name: string; id: number }[] = [];
        let tempIdObj: number[] = [];
        // let tempTemplateRole: string[] = [];
        dcpRoleResponse.data.list.map((role: RoleResponse) => {
          if (role.identifier === "template") {
            tempRoleObj.push({ id: role.id, name: role.name });
            const templateIndex = role.templates
              ? role.templates.findIndex((t) => t === template.location.id)
              : -1;
            if (templateIndex !== -1) {
              tempIdObj.push(role.id);
              // console.log(role, template.location.content_id);
            }
          }
        });

        setRoleObj(tempRoleObj);
        setRoleID(tempIdObj);
      }
    }
  }, [dcpRoleResponse]);

  const assignRole = async (ID: number, templateID: number) => {
    let templates: string[] = await assignRoleWithId(ID);
    const url = availablePaths.roles.assignRoles(ID);
    const payload = {
      templates: [...templates, templateID],
    };

    await dcpAssignApi(url, "POST", payload);
  };

  const unassignRole = async (ID: number, templateID: number) => {
    let templates: string[] = await assignRoleWithId(ID);
    const url = availablePaths.roles.assignRoles(ID);
    const payload = {
      templates: templates.filter((id) => parseInt(id) !== templateID),
    };

    await dcpAssignApi(url, "POST", payload);
  };

  useEffect(() => {
    if (dcpAssignResponse) {
      if (!dcpAssignResponse.error && dcpAssignResponse.data.list) {
        let tempRoleObj: { name: string; id: number }[] = [];
        let tempIdObj: number[] = [];
        dcpAssignResponse.data.list.map((role: RoleResponse) => {
          if (role.identifier === "template") {
            tempRoleObj.push({ id: role.id, name: role.name });
            tempIdObj.push(role.id);
          }
        });
        setRoleObj(tempRoleObj);
        setRoleID(tempIdObj);
      }
    }
  }, [dcpAssignResponse]);

  const assignRoleWithId = async (ID: number) => {
    try {
      const res = await dcpAssignRoleApi(
        availablePaths.roles.getRoleWithID(ID),
        "GET"
      );

      if (!res.error) {
        let temp = res.data.templates;
        if (temp !== null) {
          return temp;
        } else {
          return [];
        }
      }
    } catch {
      return [];
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUseDialog = () => {
    setEditDialogOpen(true);
  };

  // edit
  const handleEditDialog = () => {
    let data = [];
    if (template.body) data = template.body.data;
    navigate("/poster/edit/template/" + template.id);
    handleClose();
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };
  const handleRoleDailogueOpen = () => {
    setOpenRoleDialog(true);
  };
  const handleRoleDialogClose = () => {
    setOpenRoleDialog(false);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(event.target.value);
  };

  const handleEditSave = async () => {
    navigate(
      `/poster/create/${template.type.value}/${newTitle}/${template.id}`
    );
    handleClose();
  };

  //assign
  const handleAssignSave = async () => {
    setIsClosed(true);
    setAssignDialogOpen(false);

    if (assignRoleChangeData) {
      //new
      for (const element of assignRoleChangeData.new) {
        await assignRole(element, template.location.id);
      }

      //delete
      for (const element of assignRoleChangeData.delete) {
        await unassignRole(element, template.location.id);
      }

      await fetchRoles();
      toast.success(t("Assigned successfully"));
    }
    setAssignRoleChangeData(null);
  };

  const handleAssignCancel = () => {
    let tempIdObj: number[] = [];
    roleObj.map((role) => {
      tempIdObj.push(role.id);
    });

    setAssignRoleChangeData(null);

    // setRoleID(tempIdObj);
    setIsClosed(true);
    setAssignDialogOpen(false);
  };

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(true);
    handleClose();
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteSubmit = async () => {
    const url = availablePaths.templateApi.deleteTemplate(
      template.location.content_id
    );
    const res = await deleteApi(url, "GET");

    if (!res.error) {
      let cp = currentTemplate!.filter(
        (temp) => temp.location.content_id !== template.location.content_id
      );
      setCurrentTemplate!(cp);
      handleDeleteClose();
    }
  };

  return (
    <div
      className={
        layout === "card"
          ? "rounded overflow-hidden shadow-xl hover:shadow-2xl transition-shadow duration-300 ease-in-out"
          : "flex rounded overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out items-center"
      }
    >
      <img
        src={
          (template as TemplateResponse).preview
            ? availablePaths.imageUpload.getImageByPrefix(
                (template as TemplateResponse).preview
              )
            : "https://cdn.pixabay.com/photo/2024/02/24/20/54/books-8594725_1280.jpg"
        }
        alt="cover_image"
        className={
          layout === "card"
            ? "w-full h-64 object-cover object-top"
            : "w-64 h-48 object-cover object-top"
        }
      />
      <div className="p-4 flex-grow">
        <h2 className="text-slate-700 font-bold text-xl">{template.title}</h2>
        <div className="text-slate-600 flex flex-col gap-4 justify-between mt-3 w-full">
          <div className="flex items-center w-full">
            {/* {imageUrl.length > 0 ? (
              <img
                src={apiPath.imageUpload.getImageByPrefix(imageUrl)}
                className=" h-7 w-7 rounded-full"
              />
            ) : ( */}
            <PersonIcon />
            {/* )} */}
            <span className="ml-1">
              {t("By")} - {template.metadata.author_name}
            </span>
          </div>
          <div className="flex flex-row justify-between">
            <div
              className={
                layout === "card"
                  ? "flex flex-row justify-between w-full"
                  : "flex flex-row w-full"
              }
            >
              <div className="flex items-center">
                <DateRangeIcon />
                <span className="ml-1 mr-3">
                  {new Date(template.metadata.modified).toLocaleDateString()}
                </span>
              </div>
              <div className="flex items-center">
                <DescriptionIcon />
                {template.type && template.type?.text && (
                  <span className="ml-1">
                    {template.type === undefined
                      ? t(template.location.content_type)
                      : t(template.type.text)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className=" w-full px-1">
            {/* <p className=" text-sm font-semibold text-start items-start">
              {templateRole.join(", ").substring(0, 37)}
              {templateRole.join(", ").length > 37 && "..."}
            </p>
            
            */}
            {/* {templateRole.length === 1 ? (
              <Typography>Role: {templateRole[0]}</Typography>
            ) : (
              <>
                <Button
                  onClick={handleRoleDailogueOpen}
                  endIcon={<ArrowDropDown />}
                  sx={{ color: "black" }}
                >
                  Roles
                </Button>
                <Dialog open={openRoleDialog} onClose={handleRoleDialogClose}>
                  <DialogTitle>Roles</DialogTitle>
                  <DialogContent sx={{ width: 300 }}>
                    {templateRole.map((role, index) => (
                      <Typography key={index}>{role}</Typography>
                    ))}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleRoleDialogClose}>Close</Button>
                  </DialogActions>
                </Dialog>
              </>
            )} */}
          </div>
        </div>
      </div>
      <div className="px-4 pb-4 flex justify-end items-center">
        {isDownloadPage ? (
          <>
            {" "}
            {checkPermission(role, "download", "download") && (
              <button
                onClick={() => convertHtmlToPdf(template.body, template.title)}
                className="flex items-center text-sm font-bold"
              >
                <DownloadForOfflineIcon />
                <span className="ml-1">{t("Download")}</span>
              </button>
            )}{" "}
            {checkPermission(role, "download", "more_icon") && (
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              {" "}
              {checkPermission(role, "download", "use") && (
                <MenuItem onClick={handleUseDialog}>
                  <UseIcon />
                  <span className="ml-1">{t("Use")}</span>
                </MenuItem>
              )}{" "}
              {checkPermission(role, "download", "edit") && (
                <MenuItem onClick={handleEditDialog}>
                  <EditIcon />
                  <span className="ml-1">{t("Edit")}</span>
                </MenuItem>
              )}{" "}
              {checkPermission(role, "download", "assign_to_group") && (
                <MenuItem
                  onClick={() => {
                    setIsClosed(true);
                    setAssignDialogOpen(true);
                  }}
                >
                  <GroupRounded />
                  <span className="ml-1">{t("Assign")}</span>
                </MenuItem>
              )}{" "}
              {checkPermission(role, "download", "delete") && (
                <MenuItem onClick={handleDeleteDialog}>
                  <DeleteIcon />
                  <span className="ml-1">{t("Delete")}</span>
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <div className=" flex flex-col gap-3">
            <div className=" flex flex-row gap-2 items-center justify-start">
              {checkPermission(role, "template", "use") && (
                <button
                  onClick={handleUseDialog}
                  className="flex items-center text-sm font-bold"
                >
                  <UseIcon />
                  <span className="ml-1">{t("Use")}</span>
                </button>
              )}
              {checkPermission(role, "template", "edit") && (
                // {role.some((r) => permission.template.edit.includes(r)) && (
                <button
                  onClick={handleEditDialog}
                  className="flex items-center text-sm font-bold mr-2"
                >
                  <EditIcon />
                  <span className="ml-1">{t("Edit")}</span>
                </button>
              )}
              {checkPermission(role, "template", "assign_to_group") && (
                // {role.some((r) =>permission.template.assign_to_group.includes(r)) && (
                <button
                  onClick={() => {
                    setAssignDialogOpen(true);
                    setIsClosed(true);
                  }}
                  className="flex items-center text-sm font-bold ml-2"
                >
                  <GroupRounded />
                  <span className="ml-1">{t("Assign")}</span>
                </button>
              )}
              {checkPermission(role, "template", "more_icon") && (
                // {role.some((r) => permission.template.more_icon.includes(r)) && (
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                {" "}
                {checkPermission(role, "template", "download") && (
                  <MenuItem
                    onClick={() =>
                      convertHtmlToPdf(template.body, template.title)
                    }
                  >
                    <DownloadForOfflineIcon />
                    <span className="ml-1">{t("Download")}</span>
                  </MenuItem>
                )}
                {checkPermission(role, "template", "delete") && (
                  <MenuItem onClick={handleDeleteDialog}>
                    <DeleteIcon />
                    <span className="ml-1">{t("Delete")}</span>
                  </MenuItem>
                )}
              </Menu>
            </div>
            <div className="flex flex-row gap-2 flex-wrap items-center justify-end">
              {/* {templateRole.map((role) => {
                return (
                  <div
                    key={role}
                    className="px-3 py-1 rounded-full bg-blue-300"
                  >
                    {role.toUpperCase()}
                  </div>
                );
              })} */}
            </div>
          </div>
        )}
      </div>
      {/* edit dialog  */}
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>
          {t("Enter the new name for")}{" "}
          {template.type === undefined
            ? t(template.location.content_type)
            : t(template.type.text)}
        </DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Poster Title"
            type="text"
            fullWidth
            variant="outlined"
            value={newTitle}
            onChange={handleTitleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>{t("Cancel")}</Button>
          <Button onClick={handleEditSave}>{t("Use")}</Button>
        </DialogActions>
      </Dialog>
      {/* delete dialog  */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle>
          {t("Do you want to delete")} {template.title} ?
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleDeleteClose}>{t("Cancel")}</Button>
          <Button onClick={handleDeleteSubmit}>{t("Delete")}</Button>
        </DialogActions>
      </Dialog>
      {/* assign dialogue */}
      <Dialog
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
      >
        <DialogTitle>{t("Select the roles you want to assign")}</DialogTitle>
        <DialogContent>
          <MultiSelect
            options={roleObj}
            selectedOptions={roleID}
            onChange={setAssignRoleChangeData}
            isClosed={!isClosed}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleAssignCancel();
              setAssignDialogOpen(false);
            }}
          >
            {t("Cancel")}
          </Button>
          <Button onClick={handleAssignSave}>{t("Assign")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TemplateCard;
