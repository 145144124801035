import { Alert, AlertTitle, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Paper, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useApi } from '../../../services/HttpService';
import { InfoArea } from '../../../components/ui/InfoArea';
import { createMenuitemHirarchy, countHierarchyChildren, Menuitem, newMenuitem, createHierarchy, flattenHierarchy, Accessgroup, AccessgroupMenuitem } from '../../../model/System';
import HardDeleteDialog from '../../../components/modals/dialog/HardDeleteDialog';
import { CenterSpinner } from '../../../components/ui/CenterSpinner';
import DeleteIcon from '@mui/icons-material/Delete';
import { MultipleSelectChip, SingleSelectChip } from '../../../components/ui/DropdownSelectChip';
import { AuthRoutes } from '../../../routes/AuthenticatedRoutes';
import { DashedTreeItem } from '../../../components/ui/DashedTreeItem';
import { SimpleTreeView } from '@mui/x-tree-view';
import EditIcon from '@mui/icons-material/Edit';
import { ClickableIcon } from '../../../components/ui/Icon';
import { MenuitemStatus, menuitemStatusOptions } from '../../../model/General';

export const MenuSettings = () => {
  const theme = useTheme();
  const { api, error, isLoading } = useApi();
  const [menuitem, setMenuitem] = useState<Menuitem>(newMenuitem);
  const [selected, setSelected] = useState<Menuitem>({} as Menuitem); // item to be deleted
  const [expandedItems, setExpandedItems] = useState<string[]>([]); // used to expand/collapse menuitems
  const [openDelete, setOpenDelete] = useState(false);
  const [menuitems, setMenuitems] = useState<Menuitem[]>([]);
  const [accessgroups, setAccessgroups] = useState<Accessgroup[]>([]);
  const [accessgroupMenuitems, setAccessgroupMenuitems] = useState<AccessgroupMenuitem[]>([]);
  const [selectedAccessgroups, setSelectedAccessgroups] = useState<number[]>([]);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({}); // track edited fields
  const [submitted, setSubmitted] = useState(false);

  const pageOptions = AuthRoutes.filter(r => !r.autoInclude).map((route, index) => ({
    id: index + 1,
    ...route,
  }));
  const editRef = useRef(null); // scroll to edit on edit click
  const executeScroll = () => editRef.current.scrollIntoView();

  useEffect(() => {
    getMenuitems();
  }, []);

  const getMenuitems = () => {
    api("/settings/menuitems").then(res => {
      if (res && res.statusCode === 200) {
        setMenuitems(createHierarchy(res.settings.menuitems));
        setAccessgroups(createHierarchy(res.settings.accessgroups));
        setAccessgroupMenuitems(res.settings.accessgroupMenuitems);
      }
    });
  }

  const postMenuitem = () => {
    let data = { ...menuitem };
    const hasErrors = Object.keys(data).some(
      (key) => validateField(key as keyof Menuitem) !== ""
    );

    if (hasErrors) {
      setSubmitted(true);
      return
    }

    data.priority = Number(data.priority);

    api("/settings/menuitems", "POST", { menuitem: data, accessgroup_ids: selectedAccessgroups }).then(res => {
      if (res && res.statusCode === 200) {
        handleSetMenuitem(newMenuitem()); // if menuitme should not be reset, remember to update menuitem to the returned object
        setMenuitems(createHierarchy(res.settings.menuitems));
        setAccessgroups(createHierarchy(res.settings.accessgroups));
        setAccessgroupMenuitems(res.settings.accessgroupMenuitems);
        setSubmitted(false);
      }
    });
  }

  const deleteMenuitem = () => {
    api("/settings/deleteMenuitems", "POST", { menuitem: selected }).then(res => {
      if (res && res.statusCode === 200) {
        setSelected({} as Menuitem);
        setOpenDelete(false);
        setMenuitems(createHierarchy(res.menuitems));
      }
    });
  }

  const handleSetMenuitem = (menuitem: Menuitem) => {
    setMenuitem(menuitem);
    setTouched({});
    // whenever menuitem is set set its associated accessgroups
    if (menuitem?.id > 0) {
      let groups = accessgroupMenuitems.filter(am => am.sys_menuitem_id === menuitem.id);
      setSelectedAccessgroups(groups.map(am => am.accessgroup_id));
    } else {
      setSelectedAccessgroups([]);
    }
  }

  const handleEditClick = (event, item) => {
    event.stopPropagation();
    handleSetMenuitem(item);
    executeScroll();
  }

  const handleDeleteClick = (event, item) => {
    event.stopPropagation();
    setSelected(item);
    setOpenDelete(true);
  }

  const handleChange = (field: keyof Menuitem, value: any) => {
    setMenuitem((prev) => ({ ...prev, [field]: value }));

    if (!touched[field]) {
      setTouched((prev) => ({ ...prev, [field]: true }));
    }
  };

  const validateField = (field: keyof Menuitem): string => {
    if (field === "name") {
      if (menuitem.name.trim() === "") return "Navn kan ikke være tom";
    }
    if (field === "priority") {
      if (menuitem.priority === 0) return "";
      if (!menuitem.priority || isNaN(Number(menuitem.priority))) {
        return "Prioritet må være et tall";
      }
    }
    return ""; // No error
  };

  const handleExpandedItemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setExpandedItems(itemIds);
  };

  const handleExpandItemsClick = () => {
    setExpandedItems((oldExpanded) =>
      oldExpanded.length === 0
        ? flattenHierarchy(menuitems)?.map(menuitem => menuitem.id.toString())
        : []
    );
  };

  const getDuplicateMenuNames = (menuitems: Menuitem[]) => {
    const menuNames = menuitems.map(menuitem => menuitem.name);
    const duplicates = menuNames.filter((name, index) => menuNames.indexOf(name) !== index);

    if (duplicates.length === 0) {
      return <></>;
    }

    return (
      duplicates.map((duplicate, index) => (
        <Alert severity='warning' variant='outlined' key={index} sx={{ mt: 1, width: '100%' }}>
          Det finnes flere menyvalg med navnet <strong>{duplicate}</strong>
        </Alert>
      ))
    )
  }

  function recursiveTree(item: any) {
    return (
      <Box key={item.id}>
        <DashedTreeItem
          sx={{ width: '100%' }}
          itemId={item.id.toString()}
          onDoubleClick={(event) => handleEditClick(event, item)}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant='body1'>{item.name}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>

                {/* ADD MORE ACTION ICONS HERE */}
                <Typography variant='body1'>{item.priority}</Typography>

                <ClickableIcon title="Rediger" disableInteractive={true} onClick={(event) => handleEditClick(event, item)}>
                  <EditIcon color='inherit' />
                </ClickableIcon>
                <ClickableIcon title="Slett" disableInteractive={true} onClick={(event) => handleDeleteClick(event, item)}>
                  <DeleteIcon color='error' />
                </ClickableIcon>
              </Box>
            </Box>
          }
        >
          {item.children.map(child => recursiveTree(child))}
        </DashedTreeItem>
      </Box >
    )
  }

  return (
    <>
      <HardDeleteDialog
        open={openDelete}
        bodyText={`Er du sikker på at du vil slette menyen "${selected?.name}"${selected?.children?.length > 0 ? `, dette vil også slette dens ${countHierarchyChildren(selected)} undermenyer` : ""}? Trykk på "SLETT" for å bekrefte.`}
        loading={isLoading}
        errorMessage={error}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => deleteMenuitem()}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        <Typography variant='h5' sx={{ mt: 2 }}>Menyvalg innstillinger</Typography>

        {error && <Alert sx={{ width: "100%" }} severity="error">{error}</Alert>}
        {getDuplicateMenuNames(flattenHierarchy(menuitems))}

        <Box ref={editRef} sx={{ width: "100%" }}>
          <InfoArea title={menuitem.id > 0 ? "Rediger menyvalg" : "Opprett menyvalg"}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  label="Navn"
                  fullWidth
                  required
                  value={menuitem.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  variant="outlined"
                  error={validateField("name") !== "" && (submitted || touched.name)}
                  helperText={(submitted || touched.name) ? validateField("name") : ""}
                  onBlur={() => setTouched((prev) => ({ ...prev, name: true }))}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Prioritet"
                  value={menuitem.priority}
                  onChange={(e) => handleChange("priority", e.target.value)}
                  variant="standard"
                  type="number"
                  error={validateField("priority") !== "" && (submitted || touched.priority)}
                  helperText={(submitted || touched.priority) ? validateField("priority") : ""}
                  onBlur={() => setTouched((prev) => ({ ...prev, priority: true }))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleSelectChip
                  label={'Side'}
                  inputs={pageOptions}
                  selectField={'name'}
                  groupByField={'module'}
                  selected={pageOptions.find(p => p.path === menuitem.url)?.id}
                  onChange={(id: number) => {
                    let url = pageOptions.find(p => p.id === id)?.path;
                    handleChange("url", url);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleSelectChip
                  label={'Overmeny'}
                  inputs={flattenHierarchy(menuitems)}
                  selectField={'name'}
                  selected={menuitem.parent_id}
                  onChange={(id: number) => {
                    handleChange("parent_id", id);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SingleSelectChip
                  label={'Status'}
                  inputs={menuitemStatusOptions}
                  selectField={'name'}
                  selected={menuitem.status}
                  onChange={(id: number) => {
                    handleChange("status", id);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={menuitem.open_new_tab}
                        onChange={(e) => handleChange("open_new_tab", e.target.checked)}
                      />}
                    label="Åpne i ny fane"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={menuitem.license_paid}
                      />}
                    label="Betalte lisens"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <MultipleSelectChip
                  label={'Tilgangsgrupper'}
                  inputs={flattenHierarchy(accessgroups)}
                  selectField={'name'}
                  selected={selectedAccessgroups}
                  // disabledItems={accessgroups.filter(v => v.id === 1 || v.name === "admin").map(v => v.id)}
                  onChange={(ids: number[]) => {
                    setSelectedAccessgroups(flattenHierarchy(accessgroups).filter(v => ids.includes(v.id)).map(v => v.id))
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Beskrivelse"
                  fullWidth
                  multiline
                  rows={4}
                  value={menuitem.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  variant="filled"
                  error={validateField("description") !== "" && (submitted || touched.description)}
                  helperText={(submitted || touched.description) ? validateField("description") : ""}
                  onBlur={() => setTouched((prev) => ({ ...prev, description: true }))}
                />
              </Grid>
              {/* MISSING:
              validated_at
              sys_form_id
              */}
            </Grid>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 2, p: 2 }}>
              <Button
                variant='contained'
                color='darkgrey'
                onClick={() => {
                  handleSetMenuitem(newMenuitem());
                  setTouched({});
                  setSubmitted(false);
                }}
              >
                Avbryt
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={postMenuitem}
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={22} />}
              >
                {menuitem.id > 0 ? "Rediger" : "Opprett"}
              </Button>
            </Box>
          </InfoArea>
        </Box>

        <InfoArea title='Tilgjengelige menyer'>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Button variant='outlined' onClick={handleExpandItemsClick} sx={{ width: 150, fontWeight: "bold" }}>
              {expandedItems.length === 0 ? 'Vis alle' : 'Skjul'}
            </Button>
            <CenterSpinner loading={isLoading} cover={false} />
          </Box>
          <Typography variant='h6' sx={{ mt: 2, mb: 0 }}>Hovedmeny</Typography>
          <SimpleTreeView
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
          >
            {menuitems?.filter(v => v.status === MenuitemStatus.Main).map((menuitem: Menuitem) => (
              recursiveTree(menuitem)
            ))}
          </SimpleTreeView>
          <Typography variant='h6' sx={{ mt: 2 }}>Profilmeny</Typography>
          <SimpleTreeView
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
          >
            {menuitems?.filter(v => v.status === MenuitemStatus.Profile).map((menuitem: Menuitem) => (
              recursiveTree(menuitem)
            ))}
          </SimpleTreeView>
          <Typography variant='h6' sx={{ mt: 2 }}>Skjulte menyvalg</Typography>
          <SimpleTreeView
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
          >
            {menuitems?.filter(v => v.status === MenuitemStatus.Hidden).map((menuitem: Menuitem) => (
              recursiveTree(menuitem)
            ))}
          </SimpleTreeView>
        </InfoArea>
      </Box>
    </>
  )
}