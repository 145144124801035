import { ProjectReport, Transaction} from '../../model/Hour';
import { CenterSpinner } from '../../components/ui/CenterSpinner';
import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  IconButton,
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useApi } from "../../services/HttpService";
import { FirstPage, LastPage } from "@mui/icons-material";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { NumberFormat, PercentFormat } from '../../components/ui/NumberFormat';

export const ProjectReports = () => {
  const { api: projectReportApi, response: projectReportsResponse, isLoading: projectReportsIsLoading, error: hourRegistrationsError } = useApi();
  const { api: transactionDataApi, response: transactionDataResponse, isLoading: transactionDataIsLoading, error: transactionDataError } = useApi();

  const [data, setData] = useState<ProjectReport[]>([]);
  const [filteredData, setFilteredData] = useState<ProjectReport[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [transactionData, setTransactionData] = useState<Transaction[]>([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  

  const fetchTransactionData = (type: "adk" | "doc" | "salary" | "purchase" | "sale", projectNo: number) => {
    transactionDataApi("/hour/getTransactionData", "POST", { "projectNo": projectNo, "type": type })
      .then((res) => {
        if (res && res.statusCode === 200) {
          setTransactionData(res.data);
          setPopupOpen(true);
          setTransactionType(type);
        }
      });
  };


  // Downloads transaction documents
  const fetchDocument = (documentURL: string) => {
    transactionDataApi("/hour/getTransactionDocument", "POST", { "documentURL": documentURL, "type": transactionType })
      .then((res) => {
        if (res && res.statusCode === 200 && res.data) {
          // Determine the filename
          let filename = "";
          if (documentURL.includes("invoice")) {
            filename = documentURL.replace("@InvoiceNo:", "") + ".pdf";
          } else {
            filename = documentURL.replace(/\\/g, "-"); // Replace backslashes with hyphens
          }
  
          // Decode the base64 string
          const byteCharacters = atob(res.data);
          const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
  
          // Create a URL for the Blob
          const blobURL = URL.createObjectURL(blob);
  
          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = blobURL;
          a.download = filename;
          a.target = "_blank"; // Open in new tab
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
  
          // Cleanup: Revoke the blob URL after some time to free memory
          setTimeout(() => URL.revokeObjectURL(blobURL), 5000);
        }
      })
      .catch((error) => console.error("Error fetching document:", error));
  };
  
  // Function to fetch data for a given year
  const getProjects = () => {
    projectReportApi("/hour/getProjectReportData", "GET",)
      .then((res) => {
        if (res && res.statusCode === 200) {
          setData(res.data);
          setFilteredData(res.data); // Reset filtered data on new fetch
        }
      });
  };

  // Fetch data on component mount
  useEffect(() => {
    getProjects();
  }, []);

  // Handle search filtering
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredData(data);
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      const filtered = data.filter((entry) =>
        entry.projectName.toLowerCase().includes(lowerQuery) ||
        entry.employeeName.toLowerCase().includes(lowerQuery) ||
        entry.projectNo.toString().includes(lowerQuery) // Convert number to string for search
      );
      setFilteredData(filtered);
    }
  }, [searchQuery, data]);

  const handleFirstPage = () => {
    setPage(0);
  };

  const handleLastPage = () => {
    setPage(Math.ceil(filteredData.length / rowsPerPage) - 1);
  };

  const paginatedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  const TransactionPopup = () => {
    return (
      <Dialog open={popupOpen} onClose={() => setPopupOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Transaction Details</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Voucher No</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Document</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionData.map((transaction, index) => (
                <TableRow key={index}>
                  <TableCell>{transaction.voucherNo}</TableCell>
                  <TableCell>{new Date(transaction.voucherDate).toLocaleDateString()}</TableCell>
                  <TableCell>{transaction.voucherType}</TableCell>
                  <TableCell>{transaction.supplier}</TableCell>
                  <TableCell>{transaction.voucherText}</TableCell>
                  <TableCell>{NumberFormat(transaction.amount)}</TableCell>
                  {transaction.documentURL ? (
                  <TableCell onClick={() =>fetchDocument(transaction.documentURL)} sx={{ cursor: "pointer", color: "blue" }}>
                        Last ned
                  </TableCell>
                ) : (<TableCell>-</TableCell>)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <Button onClick={() => setPopupOpen(false)} color="primary">
          Close
        </Button>
      </Dialog>
    );
  };

  return (
    <>
    <TransactionPopup />

    <TableContainer component={Paper} sx={{ mt: 2, p: 2 }}>
      {/* Year Selector & Search */}
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <TextField
          label="Søk på prosjekt/ansatt"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(_event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0); // Reset to first page when changing rows per page
        }}
        rowsPerPageOptions={[10, 20, 50, 100]}
        ActionsComponent={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleFirstPage} disabled={page === 0}>
              <FirstPage />
            </IconButton>
            <IconButton onClick={() => setPage(page - 1)} disabled={page === 0}>
              <ArrowBackIos></ArrowBackIos>
            </IconButton>
            <IconButton onClick={() => setPage(page + 1)} disabled={page >= Math.ceil(filteredData.length / rowsPerPage) - 1}>
              <ArrowForwardIos></ArrowForwardIos>
            </IconButton>
            <IconButton onClick={handleLastPage} disabled={page >= Math.ceil(filteredData.length / rowsPerPage) - 1}>
              <LastPage />
            </IconButton>
          </div>
        )}
      />
      </Box>
      {/* Table */}
      <CenterSpinner loading={projectReportsIsLoading} />
            {!projectReportsIsLoading && (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>No</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Prosjektleder</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Prosjekt</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Salg</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Kjøp</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Lønn</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>ADK</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Doksenter</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Resultat</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Resultat%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {paginatedData.map((entry, index) => {
          const resultat =
            entry.salesAmount -
            entry.purchaseAmount -
            entry.salaryAmount -
            entry.administrationCostAmount;
        
          const resultatPercent =
            entry.salesAmount !== 0 ? ((resultat / entry.salesAmount) * 100).toFixed(2) : "0.00";
        
          return (
            <TableRow key={index}>
              <TableCell>{entry.projectNo}</TableCell>
              <TableCell>{entry.employeeName}</TableCell>
              <TableCell>{entry.projectName}</TableCell>

              {entry.salesAmount != 0 ?(
                <TableCell onClick={() => fetchTransactionData("sale", entry.projectNo)} sx={{ cursor: "pointer", color: "blue" }}>
                {NumberFormat(entry.salesAmount)}
              </TableCell>
              ):<TableCell>-</TableCell> }
              {entry.purchaseAmount != 0 ?(
                <TableCell onClick={() => fetchTransactionData("purchase", entry.projectNo)} sx={{ cursor: "pointer", color: "blue" }}>
                {NumberFormat(entry.purchaseAmount)}
              </TableCell>
              ):<TableCell>-</TableCell> }
              {entry.salaryAmount != 0 ?(
                <TableCell onClick={() => fetchTransactionData("salary", entry.projectNo)} sx={{ cursor: "pointer", color: "blue" }}>
                {NumberFormat(entry.salaryAmount)}
              </TableCell>
              ):<TableCell>-</TableCell> }
              {entry.administrationCostAmount != 0 ?(
                <TableCell onClick={() => fetchTransactionData("adk", entry.projectNo)} sx={{ cursor: "pointer", color: "blue" }}>
                {NumberFormat(entry.administrationCostAmount)}
              </TableCell>
              ):<TableCell>-</TableCell> }
              {entry.documentCenter != 0 ?(
                <TableCell onClick={() => fetchTransactionData("doc", entry.projectNo)} sx={{ cursor: "pointer", color: "blue" }}>
                {NumberFormat(entry.documentCenter)}
              </TableCell>
              ):<TableCell>-</TableCell> }
              <TableCell>{NumberFormat(resultat)}</TableCell>
              <TableCell>{PercentFormat(resultatPercent)}%</TableCell>
            </TableRow>
          );
        })}
        </TableBody>
      </Table>
      )}
    </TableContainer>
  </>
  );
};