import { ProjectContactData, HourYearRegistration} from '../../model/Hour';
import { CenterSpinner } from '../../components/ui/CenterSpinner';
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
} from "@mui/material";
import { useApi } from "../../services/HttpService";
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { NumberFormat } from '../../components/ui/NumberFormat';

export const HourOverview = () => {
  const { api: hourRegistrationsApi, response: hourRegistrationsResponse, isLoading: hourRegistrationsIsLoading, error: hourRegistrationsError } = useApi();
  const [data, setData] = useState<ProjectContactData[]>([]);
  const [filteredData, setFilteredData] = useState<ProjectContactData[]>([]);
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedHours, setSelectedHours] = useState<HourYearRegistration[]>([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  
const months = [
  "Januar", "Februar", "Mars", "April", "Mai", "Juni",
  "Juli", "August", "September", "Oktober", "November", "Desember"
];

  // Function to fetch data for a given year
  const getHours = (year: number) => {
    hourRegistrationsApi("/hour/getHourOverviewData", "POST", { year })
      .then((res) => {
        if (res && res.statusCode === 200) {
          setData(res.data);
          setFilteredData(res.data); // Reset filtered data on new fetch
        }
      });
  };

  // Fetch data on component mount
  useEffect(() => {
    getHours(currentYear);
  }, [currentYear]);

  // Change year and fetch data
  const handleYearChange = (change: number) => {
    const newYear = currentYear + change;
    setCurrentYear(newYear);
    getHours(newYear);
  };

  // Open modal with detailed hour registrations
  const handleOpen = (registrations: HourYearRegistration[], project: string, contact: string) => {
    setSelectedHours(registrations);
    setSelectedProject(project);
    setSelectedContact(contact);
    setOpen(true);
  };

  // Close modal
  const handleClose = () => {
    setOpen(false);
  };

  // Handle search filtering
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredData(data);
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      const filtered = data.filter(
        (entry) =>
          entry.project.toLowerCase().includes(lowerQuery) ||
          entry.contact.toLowerCase().includes(lowerQuery)
      );
      setFilteredData(filtered);
    }
  }, [searchQuery, data]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2, p: 2 }}>
      {/* Year Selector & Search */}
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => handleYearChange(-1)} color="primary">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" sx={{ mx: 2 }}>
            {currentYear}
          </Typography>
          <IconButton onClick={() => handleYearChange(1)} color="primary">
            <ArrowForward />
          </IconButton>
        </Box>
        <TextField
          label="Søk på prosjekt/ansatt"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      {/* Table */}

      <CenterSpinner loading={hourRegistrationsIsLoading} />
            {!hourRegistrationsIsLoading && (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Prosjekt</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Ansatt</TableCell>
            {months.map((month) => (
              <TableCell key={month} sx={{ fontWeight: "bold" }}>{month}</TableCell>
            ))}
            <TableCell sx={{ fontWeight: "bold" }}>Sum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((entry, index) => {
            const totalHours = months.reduce(
              (sum, month) => sum + (entry.months[month]?.total_hours || 0),
              0
            );

            const allRegistrations = months.flatMap(
              (month) => entry.months[month]?.hour_registrations || []
            );

            return (
              <TableRow key={index}>
                <TableCell>{entry.project}</TableCell>
                <TableCell>{entry.contact}</TableCell>
                {months.map((month) => (
                  <TableCell key={month} align="center">
                    {entry.months[month]?.total_hours > 0 ? (
                      <Button
                        variant="text"
                        color="inherit"
                        size="medium"
                        onClick={() => handleOpen(entry.months[month].hour_registrations, entry.project, entry.contact)}
                      >
                        {NumberFormat(entry.months[month].total_hours)}
                      </Button>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                ))}
                <TableCell align="center">
                  {totalHours > 0 ? (
                    <Button
                      variant="text"
                      color="primary"
                      size="medium"
                      onClick={() => handleOpen(allRegistrations, entry.project, entry.contact)}
                    >
                      {NumberFormat(totalHours)}
                    </Button>
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
            )}

      {/* MUI Dialog for Hour Registrations */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        {/* <DialogTitle>Time registreringer</DialogTitle> */}
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            {selectedProject}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {selectedContact}
          </Typography>

          {selectedHours.length > 0 ? (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Dato</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Timer</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Beskrivelse</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedHours.map((hour) => (
                    <TableRow key={hour.id}>
                      <TableCell>{new Date(hour.hour_date).toLocaleDateString()}</TableCell>
                      <TableCell>{hour.type}</TableCell>
                      <TableCell>{NumberFormat(hour.hour)}</TableCell>
                      <TableCell>{hour.description || "-"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>Ingen timeregistrering tilgjengelig.</Typography>
          )}
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
};
