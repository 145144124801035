export class Tree<T extends { id: number; parent_id: number; children?: T[]; priority?: number }> {
  private itemMap: Map<number, T>;
  private roots: T[];

  constructor(items: T[]) {
    this.itemMap = new Map<number, T>();
    this.roots = this.buildTree(items);
  }

  // Builds the hierarchy and populates the itemMap
  private buildTree(items: T[]): T[] {
    const itemMap = this.itemMap;

    // Initialize the map and set the children array for each item
    items?.forEach((item) => {
      item.children = []; // Ensure `children` exists
      itemMap.set(item.id, item);
    });

    const roots: T[] = [];

    items?.forEach((item) => {
      const parent = itemMap.get(item.parent_id);
      if (item.parent_id === 0 || !parent) {
        // No parent
        roots.push(item);
      } else {
        // add the item to its children
        if (parent) {
          parent.children?.push(item);
        }
      }
    });

    // Sort each level by priority, if it exists
    function sortHierarchy(items: T[]) {
      if (items.length === 0) return;

      if ("priority" in items[0]) {
        items.sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0));
      }

      items.forEach((item) => sortHierarchy(item.children || []));
    }

    // Only sort if priority exists on the first item
    if (roots.length > 0) {
      if ("priority" in roots[0]) {
        sortHierarchy(roots);
      }
    }

    return roots;
  }

  // Get the hierarchy
  public getTree(): T[] {
    return this.roots;
  }

  // Lookup a node by ID
  public getNodeById(id: number): T | undefined {
    return this.itemMap.get(id);
  }

  // Get the root node of a given node
  public getRootOfNode(id: number): T | undefined {
    let currentNode = this.itemMap.get(id);
    while (currentNode && currentNode.parent_id && currentNode.parent_id !== currentNode.id) {
      if (!this.contains(currentNode.parent_id)) {
        break;
      }

      currentNode = this.itemMap.get(currentNode.parent_id);
    }
    return currentNode;
  }

  // Check if the tree contains a node with the given ID
  public contains(id: number): boolean {
    return this.itemMap.has(id);
  }

  public flatten(): T[] {
    const flattened: T[] = [];
  
    function traverse(items: T[]) {
      items.forEach((item) => {
        flattened.push(item);
        if (item.children) {
          traverse(item.children);
        }
      });
    }
  
    traverse(this.roots);
    return flattened;
  }
  
  public countChildren(item: T): number {
    let count = item.children.length; // Count immediate children
    item.children?.forEach((child) => {
      count += this.countChildren(child); // Recursively count their children
    });
    return count;
  }
}