// This files contains constructors for table status fields.
// when creating or editing, make sure they are the same as in its respective repository.

export interface Status {
  id: number; // status value
  name: string; // status description
}

// Function to generate array of { id, name } objects, useful for the dropdown component
export const enumStatusToOptions = <T extends Record<string, number | string>>(enumObj: T): Status[] => {
  return Object.entries(enumObj)
    .filter(([key, value]) => typeof value === "number")
    .map(([key, value]) => ({ id: value as number, name: key }));
};

export enum MenuitemStatus {
  Hidden = 0,
  Main = 1,
  Profile = 2,
}

export enum OidcClientStatus {
  Default = 1,
}

export const menuitemStatusOptions = enumStatusToOptions(MenuitemStatus);