import React, { useEffect, useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import { getValidToken, useApi } from '../services/HttpService';
import localStorageService, { LocalStorageKeys } from '../services/LocalStorageService';
import { Typography } from '@mui/material';
import { authStore } from '../App';

export const Home = () => {
  const [token, setToken] = useState("");
  const [checksum, setChecksum] = useState("");
  const [contact, setContact] = useState<any>({});
  const [roles, setRoles] = useState<string[]>([]);
  const [expiresIn, setExpiresIn] = useState<number>(0);
  const [disExpire, setDisExpire] = useState<string>("");
  const { api } = useApi();
  const { username } = authStore();

  useEffect(() => {
    setToken(localStorageService.getItem(LocalStorageKeys.OIDC_TOKEN));
  }, []);

  useEffect(() => {
    async function Checksum(str) {
      setChecksum(await generateChecksum(str));
    }

    Checksum((token));
  }, [token]);

  async function generateChecksum(str: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  const testOidcToken = async () => {
    const token = await getValidToken(false);

    let check = await generateChecksum(token)

    setToken(token);
    // const token = localStorageService.getItem(LocalStorageKeys.OIDC_TOKEN);

    api("/api/me", 'GET').then(res => {
      console.log("get me with token checksum:", check, "status:", res.statusCode);

      if (res.statusCode === 200) {
        setContact(res?.contact);
        setRoles(res?.roles);
      }
    })
  }

  function parseJwt(token) {
    if (!token) {
      return null;
    }
    try {
      const base64Url = token.split('.')[1]; // Get payload part
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  useEffect(() => {
    if (!token) {
      setDisExpire("No token");
      return;
    }

    const decoded = parseJwt(token);
    if (!decoded) {
      setDisExpire("Invalid token");
      return;
    }

    if (!decoded.exp) {
      setDisExpire("The token does not have an 'exp' field.");
      return;
    }

    const expirationTime = decoded.exp * 1000; // Convert to milliseconds
    const updateTimer = () => {
      const currentTime = Date.now();
      const remainingTime = Math.max(0, Math.floor((expirationTime - currentTime) / 1000));
      setExpiresIn(remainingTime);
      return remainingTime;
    };

    if (updateTimer() <= 0) {
      return;
    }

    const intervalId = setInterval(() => {
      if (updateTimer() <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, [token]);

  useEffect(() => {
    if (expiresIn <= 0) {
      setDisExpire("expired");
      return;
    }
    setDisExpire(`expires in ${expiresIn} seconds`);
  }, [expiresIn]);

  return (
    <>
      <br />
      <h4>
        Velkommen {username}
      </h4>
      {/* <br />
      <Button onClick={testOidcToken}>Test oidc token</Button>
      <br />
      <Typography variant="h5">Token info</Typography>
      <Typography variant="body1">checksum: {checksum}</Typography>
      <Typography variant="body1">Parsed: {JSON.stringify(parseJwt(token))}</Typography>
      <Typography variant="body1">Status: {disExpire}</Typography>
      <Typography variant="body1">Me: {contact?.name}, roles: {roles.join(", ")}</Typography> */}
    </>
  )
}