import { ReactNode } from "react";
import { Paper, Theme, Typography, useTheme } from "@mui/material";

interface InfoAreaProps {
  title?: string;
  children?: ReactNode;
  theme?: Theme;
}

export function InfoArea(props: InfoAreaProps) {
  const { title, children } = props;
  let theme = useTheme();
  if (props.theme) {
    theme = props.theme;
  }

  return (
    <Paper elevation={2} sx={{ width: '100%', backgroundColor: theme.palette.mode === 'dark' ? 'inherit' : '#efefef', display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      {title && <Typography variant='h5' sx={{ textDecoration: 'underline' }}>{title}</Typography>}
      {children}
    </Paper>
  )
}