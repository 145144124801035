export interface HourYearRegistration {
  id: number;
  project: string;
  contact: string;
  description: string;
  type: number;
  hour_date: string; // ISO format (e.g., "2025-01-10T00:00:00Z")
  hour: number;
}

export interface MonthData {
  total_hours: number;
  hour_registrations: HourYearRegistration[];
}

export interface ProjectContactData {
  project: string;
  contact: string;
  months: Record<string, MonthData>; // Months mapped as keys (January, February, etc.)
}

  export interface HourRegistration {
    id: number;
    hour_project_id: number;
    type: number;
    hour_date: Date;
    hour: number;
    name: string;
    contact_id: number;
  }

  export interface Project {
    id: number;
    type: number;
    name: string;
    project_number: string;
  }

  export interface HourRegWeek {
    hour_project: string;
    hour: number;
    name: string;
    days: HourRegDay[];
  }

  export interface HourRegDay{
    id: number;
    Date: string;
    Hours: number;
  }

  export interface DayHours {
    id: number;
    Day: string; // Format: "YYYY-MM-DD"
    Hours: number;
    Name: string;
  }
  
  export interface ContactData {
    Contact: number;
    ContactName : string;
    Days: DayHours[];
  }
  
  export interface ProjectData {
    Project: number;
    ProjectName: string;
    Contacts: ContactData[];
  }
  
  export interface WeekData {
    Week: number;
    Year: number;
    Projects: ProjectData[];
  }

  export interface ProjectReport {
    projectNo: number;
    projectName: string;
    employeeName: string;
    salesAmount: number;
    purchaseAmount: number;
    salaryAmount: number;
    administrationCostAmount: number;
    documentCenter: number;
    projectVisibleOrInVisible: string;
  }

  export interface Transaction {
    voucherNo: number;
    voucherDate: string;
    voucherType: string;
    depNo: number;
    voucherText: string | null;
    amount: number;
    supplier: string | null;
    documentURL: string;
  }
  
  
  export const newHourRegistration = (): HourRegistration => ({
    id: 0,
    hour_project_id: 0,
    type: 0,
    hour_date: new Date(),
    hour: 0.0,
    name: "",
    contact_id: 0,
  });

  export const createHourRegistrionsList = (
    data: any[]
  ): HourRegistration[] => {
    return data.map((item) => ({
      id: item.id,
      hour_project_id: item.hour_project_id,
      type: 1,
      hour_date: new Date(item.hour_date), 
      hour: item.hour,
      name: item.name,
      contact_id: 0,
    }));
  };
  

  export const createProjectList = (
    data: any[]
  ): Project[] => {
    return data.map((item) => ({
      id: item.id,
      type: item.type,
      name: item.name,
      project_number: item.project_number,
    }));
  };


  

  export interface ProjectContact {
    hour_project: string;
    contact: string;
    year_week: YearWeek[];
  }
  
  export interface YearWeek {
    hour_year: number;
    hour_week: number;
    days: DayHours[];
  }
  
  export interface DayHours {
    id: number;
    hour_date: string;
    hours: number;
  }