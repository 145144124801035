import { Alert, Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import { useDcpAuth } from "../../../../contexts/DcpAuthContext";
import { availablePaths, useDcpApi } from "../../../../services/DcpService";
import { useApi } from "../../../../services/HttpService";
import FilterComponent from "../components/FilterComponent";
import NewsletterCard from "../components/NewsletterCard";
import SearchFilterView from "../components/SearchFilterView";
import { NewsletterResponse } from "./interfaces";

export const PosterNewsletter = () => {
  const { uid } = useParams(); // send with request to authenticate user
  const {
    initialLoad,
    authIsLoading,
    authError,
    isAuthenticated,
    authenticate,
  } = useDcpAuth();
  const { dcpApi, dcpResponse, dcpIsLoading, dcpError } = useDcpApi();

  const [newsletters, setNewsletters] = useState<NewsletterResponse[]>([]);
  const [mutableNewsletter, setMutableNewsletter] = useState<
    NewsletterResponse[]
  >([]);
  const [searchString, setSearchString] = useState<string>("");
  const [date, setDate] = useState<{ startDate: string; endDate: string }>({
    startDate: "",
    endDate: "",
  });
  const [currentNewsletters, setCurrentNewsletters] = useState<
    NewsletterResponse[]
  >([]);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const itemsPerPage = 6;
  const [layout, setLayout] = useState<string>("card");

  useEffect(() => {
    if (initialLoad && uid) {
      authenticate(uid, "");
    }
  }, [initialLoad]);

  useEffect(() => {
    const fetchNewsletters = async () => {
      await dcpApi(availablePaths.newsletterApi.getNewsletterList(), "GET");
    };

    if (isAuthenticated) {
      fetchNewsletters();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (dcpResponse) {
      if (!dcpResponse.error && dcpResponse.data.list) {
        let list: any[] = dcpResponse.data.list;
        // list.reverse(); // @todo: fix in request parameters
        setNewsletters(list);
        setMutableNewsletter(list);
      }
    }
  }, [dcpResponse]);

  useEffect(() => {
    const filteredArray = newsletters.filter((obj) =>
      obj.title.toLowerCase().includes(searchString.toLowerCase())
    );

    setMutableNewsletter(filteredArray);
  }, [searchString]);

  useEffect(() => {
    if (date.startDate.length > 0 && date.endDate.length > 0) {
      let filteredArray: NewsletterResponse[] = [];
      if (date.startDate === date.endDate) {
        newsletters.map((news) => {
          if (
            new Date(news.metadata.modified).toLocaleDateString() ===
            date.startDate
          ) {
            filteredArray.push(news);
          }
        });
      } else {
        newsletters.map((news) => {
          if (
            new Date(news.metadata.modified) >= new Date(date.startDate) &&
            new Date(news.metadata.modified) <= new Date(date.endDate)
          ) {
            filteredArray.push(news);
          }
        });
      }
      setMutableNewsletter(filteredArray);
    }
  }, [date]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = mutableNewsletter.slice(itemOffset, endOffset);
    const tempPageCount = Math.ceil(mutableNewsletter.length / itemsPerPage);
    setCurrentNewsletters(currentItems);
    setPageCount(tempPageCount);
  }, [mutableNewsletter, itemOffset]);

  const handlePageClick = (event: any) => {
    const newOffset =
      (event.selected * itemsPerPage) % mutableNewsletter.length;
    setItemOffset(newOffset);
  };

  return (
    <Box sx={{ maxWidth: "1050px", margin: "0 auto" }}>
      {authIsLoading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress color="info" />
        </Box>
      ) : authError ? (
        <Alert severity="error">{authError}</Alert>
      ) : !isAuthenticated ? (
        <Alert severity="error">Missing permission</Alert>
      ) : (
        <div className="w-full">
          <div className="w-full flex flex-row items-start justify-between">
            <FilterComponent
              setDate={setDate}
              isTemplate={false}
              isDownloadPage={false}
            />
            <SearchFilterView
              setSerachString={setSearchString}
              setLayout={setLayout}
              // date={date}
              // setDate={setDate}
            />
          </div>
          {dcpIsLoading ? (
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <CircularProgress color="info" />
            </Box>
          ) : dcpError ? (
            <Alert severity="error">{dcpError}</Alert>
          ) : dcpResponse ? (
            <div
              className={
                layout === "list"
                  ? "grid grid-cols-1 mb-5 gap-4"
                  : "grid-container"
              }
            >
              {currentNewsletters.map((newsletter) => (
                <NewsletterCard
                  key={newsletter.id}
                  newsletter={newsletter}
                  isDownloadPage={false}
                  currentNewsletters={mutableNewsletter}
                  setCurrentNewsletters={setMutableNewsletter}
                  layout={layout}
                />
              ))}
            </div>
          ) : null}
          <div className="w-full flex flex-row items-center justify-center mx-auto list-none">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              breakClassName="bg-gray-200 rounded-full mx-1 py-1 pb-2 px-2"
              breakLinkClassName="text-gray-600"
              containerClassName="flex"
              pageClassName="mx-2"
              pageLinkClassName="text-black font-semibold border border-black rounded-full px-3 py-2 "
              activeLinkClassName="text-black bg-[#F97316]"
              previousClassName="mx-1"
              previousLinkClassName="text-black font-semibold bg-white border border-black rounded-full px-3 py-2 "
              nextClassName="mx-1"
              nextLinkClassName="text-black font-semibold bg-white border border-black rounded-full px-3 py-2 "
            />
          </div>
        </div>
      )}
      ;
    </Box>
  );
};
