import * as React from 'react';
import { alpha, Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { forwardRef, useMemo, useState } from 'react';
import { Autocomplete, ListSubheader, Menu, Popper, TextField, Typography } from '@mui/material';
import { FixedSizeList } from "react-window";
import { SearchBox } from './SearchBox';
import { grey } from '@mui/material/colors';

interface InputItem {
  id: number;
  [key: string]: any; // Allows dynamic fields
}

const groupeMenuItemsByField = (
  inputs: InputItem[],
  groupByField: string,
  selectField: string,
  disabledItems: number[],
  theme: Theme,
): JSX.Element[] => {
  const elements: JSX.Element[] = [];
  const seenGroups = new Set<string>(); // Track which groups have been added

  inputs.forEach((input) => {
    const group = input[groupByField];

    // Add ListSubheader once per group
    if (!seenGroups.has(group)) {
      elements.push(<ListSubheader sx={{ backgroundColor: theme.palette.mode === "light" ? grey[200] : "" }} key={`header-${group}`}>{group}</ListSubheader>);
      seenGroups.add(group);
    }

    // Add MenuItem
    elements.push(
      <MenuItem key={input.id} value={input.id} disabled={disabledItems.includes(input.id)}>
        {input[selectField]}
      </MenuItem>
    );
  });

  return elements;
};

interface multiSelectProps {
  label: string;
  inputs: InputItem[];
  selectField: string;
  selected: number[];
  disabled?: boolean;
  disabledItems?: number[];
  onChange: (ids: number[]) => void
}

export const MultipleSelectChip: React.FC<multiSelectProps> = ({ label, inputs, selectField, selected, disabled, disabledItems, onChange }) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const { value } = event.target;
    const selectedIds = typeof value === 'string' ? value.split(',').map(Number) : value;
    onChange(selectedIds);
  };

  const handleItemClick = (id: number) => {
    const newSelected = selected.includes(id)
      ? selected.filter((itemId) => itemId !== id) // Remove if already selected
      : [...selected, id]; // Add if not selected

    onChange(newSelected);
  };

  const handleClear = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    onChange([]);
  };

  const getStyles = (id: number, selected: number[], theme: Theme) => {
    const isSelected = selected.includes(id);
    if (!isSelected) return {};

    return {
      fontWeight: isSelected
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
      // backgroundColor: isSelected
      //   ? theme.palette.primary.main
      //   : 'inherit',
      color: isSelected
        ? theme.palette.primary.contrastText
        : 'inherit',
      backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.1) : 'inherit',
      "&:hover": { backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.15) : '' }
    };
  }

  return (
    <FormControl variant='outlined' sx={{ width: '100%' }}>
      <InputLabel id="multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={selected}
        // onChange={handleChange}
        disabled={disabled}
        sx={{ "& .MuiSelect-icon": { color: theme.palette.mode === "dark" ? "white" : "black" } }}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={(selectedIds) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedIds.map((id) => {
              const selectedItem = inputs.find((input) => input.id === id);
              return selectedItem ? (
                <Chip key={id} label={selectedItem[selectField]} />
              ) : null;
            })}
          </Box>
        )}
      // MenuProps={MenuProps}
      >
        <MenuItem
          value={null}
          onClick={handleClear}
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.2)
            },
          }}
        >
          <em>Fjern alle</em>
        </MenuItem>
        {inputs?.map((input) => {
          const isSelected = selected.includes(input.id);

          return (
            <MenuItem
              key={input.id}
              value={input.id}
              onClick={() => handleItemClick(input.id)}
              disabled={disabledItems?.includes(input.id)}
              sx={{
                "&:hover": {
                  backgroundColor: !isSelected ? alpha(theme.palette.primary.main, 0.2) : ''
                },
              }}
            // style={getStyles(input.id, selected, theme)}
            >
              {input.name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
}

interface singleSelectChipProps {
  label: string;
  inputs: InputItem[];
  selectField: string;
  selectedVariant?: 'standard' | 'chip';
  groupByField?: string; // groups the inputs by this field
  selected: number;
  disabledItems?: number[];
  required?: boolean;
  error?: boolean;
  onChange: (id: number) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const SingleSelectChip: React.FC<singleSelectChipProps> = (props: singleSelectChipProps) => {
  const theme = useTheme();
  const {
    label, inputs, selectField,
    selectedVariant = 'standard',
    groupByField = "",
    selected,
    disabledItems = [],
    required = false,
    error = false,
    onChange,
    onBlur = undefined
  } = props;

  const handleChange = (event: SelectChangeEvent<number>) => {
    const { value } = event.target;
    console.log("singleSelectChipProps change:", value)
    const selectedId = typeof value === 'string' ? Number(value) : value;
    onChange(selectedId);
  };

  const displaySelected = (selectedId) => {
    let content = null;
    if (selectedId > 0) {
      const selectedItem = inputs.find((input) => input.id === selectedId);
      if (selectedItem) {
        selectedVariant === 'standard' ?
          content = selectedItem[selectField] :
          content = <Chip key={selectedId} label={selectedItem[selectField]} />;
      }
    }

    return <>{content}</>;
  }

  const handleNoneSelect = () => {
    onChange(0);
  };

  return (
    <FormControl variant='outlined' sx={{ width: '100%' }}>
      <InputLabel id="single-chip-label" error={error}>{label}{required ? " *" : ""}</InputLabel>
      <Select
        labelId="single-chip-label"
        id="single-chip"
        value={selected > 0 ? selected : ''}
        onChange={handleChange}
        onBlur={onBlur}
        error={error}
        sx={{ "& .MuiSelect-icon": { color: theme.palette.mode === "dark" ? "white" : "black" } }}
        input={<OutlinedInput id="single-select-chip" label={`${label}${required ? " *" : ""}`} />}
        renderValue={(selectedId) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {displaySelected(selectedId)}
          </Box>
        )}
      >
        <MenuItem
          value={0}
          onClick={handleNoneSelect}
        >
          <em>Ingen</em>
        </MenuItem>
        {groupByField !== "" ? (
          groupeMenuItemsByField(inputs, groupByField, selectField, disabledItems, theme)
        ) : (
          inputs?.map((input) => (
            <MenuItem
              key={input.id}
              value={input.id}
              disabled={disabledItems?.includes(input.id)}
            >
              {input[selectField]}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
}

interface singleSelectSearchProps {
  label: string;
  inputs: InputItem[];
  selectField: string;
  selected: number;
  selectedVariant?: 'standard' | 'chip';
  required?: boolean;
  error?: boolean;
  onChange: (id: number) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

/**
 * Dropdown select with search. Use this when there are a lot of options.
 *  - It uses react-window FixedSizeList for fast render and open and close animation.
 */
export const SingleSelectSearch: React.FC<singleSelectSearchProps> = (props: singleSelectSearchProps) => {
  const theme = useTheme();
  const {
    label, selectField, selected,
    selectedVariant = 'standard',
    required = false,
    error = false,
    onChange,
    onBlur = undefined
  } = props;

  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null); // For controlling menu open/close
  const searchFieldRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const noneOption = { id: 0 };
    noneOption[selectField] = 'Ingen';
    setOptions([noneOption, ...props.inputs]);
  }, [props.inputs])

  const filteredInputs = useMemo(
    () => options.filter((input) =>
      input[selectField].toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [options, searchTerm, selectField]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const displaySelected = (selectedId) => {
    let content = null;
    if (selectedId > 0) {
      const selectedItem = options.find((input) => input.id === selectedId);
      if (selectedItem) {
        selectedVariant === 'standard' ?
          content = selectedItem[selectField] :
          content = <Chip key={selectedId} label={selectedItem[selectField]} />;
      }
    }

    return <>{content}</>;
  }

  const handleChange = (value: number) => {
    onChange(value);
    handleClose();
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
    setTimeout(() => {
      if (searchFieldRef.current) {
        searchFieldRef.current.focus();
        searchFieldRef.current.select();
      }
    }, 0);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const renderRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const item = filteredInputs[index];
    const isSelected = item.id === selected;

    return (
      <MenuItem
        key={item.id}
        value={item.id}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.1) : 'inherit',
          "&:hover": { backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.15) : '' }
        }}
        style={style}
        onClick={() => handleChange(item.id)}
      >
        {item[selectField]}
      </MenuItem>
    );
  };

  return (
    <FormControl variant='outlined' sx={{ width: '100%' }}>
      <InputLabel id="single-chip-label" error={error}>{label}{required ? " *" : ""}</InputLabel>
      <Select
        labelId="single-chip-label"
        id="single-chip"
        value={selected > 0 ? selected : ''}
        open={Boolean(menuAnchor)}
        onChange={(event) => event.preventDefault()}
        onOpen={handleOpen}
        onClose={handleClose}
        onBlur={onBlur}
        error={error}
        sx={{ "& .MuiSelect-icon": { color: theme.palette.mode === "dark" ? "white" : "black" } }}
        input={<OutlinedInput id="single-select-chip" label={`${label}${required ? " *" : ""}`} />}
        renderValue={(selectedId) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {displaySelected(selectedId)}
          </Box>
        )}
        MenuProps={{
          disablePortal: true,
        }} // Disable default dropdown behavior
      >
        <MenuItem disabled value="" sx={{ display: "Ingen" }} />
      </Select>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: menuAnchor ? menuAnchor.clientWidth : undefined, // Match the width of the anchor element (Select)
              // maxHeight: 400,
            },
          }
        }}
      >
        <Box sx={{ padding: "8px 16px" }}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Søk..."
            value={searchTerm}
            onChange={handleSearchChange}
            inputRef={searchFieldRef}
            onClick={(e) => e.stopPropagation()} // Prevent menu from closing when clicking inside TextField
          />
        </Box>
        {filteredInputs.length > 0 ? (
          <FixedSizeList
            height={300}
            width="100%"
            itemSize={36}
            itemCount={filteredInputs.length}
            overscanCount={5}
          >
            {renderRow}
          </FixedSizeList>
        ) : (
          <Box sx={{ padding: 2, textAlign: "center" }}>
            <Typography variant="body2" color="textSecondary">
              No results found
            </Typography>
          </Box>
        )}
      </Menu>
    </FormControl>
  );
}

interface VirtualizedListProps extends React.HTMLAttributes<HTMLElement> {
  options: any[];
  getOptionLabel: (option: any) => string;
  onSelect: (option: any) => void;
}

const VirtualizedList = forwardRef<HTMLDivElement, VirtualizedListProps>(
  ({ options, getOptionLabel, onSelect, className, role, id, "aria-labelledby": ariaLabelledby, ...otherProps }, ref) => {
    return (
      <div
        ref={ref}
        className={className}
        role={role}
        id={id}
        aria-labelledby={ariaLabelledby}
        {...otherProps} // Pass additional attributes required by Material-UI
      >
        <FixedSizeList
          height={300}
          width="100%"
          itemSize={36}
          itemCount={options.length}
          overscanCount={5}
        >
          {({ index, style }) => (
            <MenuItem
              style={style}
              onClick={() => onSelect(options[index])}
              role="option"
              aria-selected="false"
            >
              {getOptionLabel(options[index])}
            </MenuItem>
          )}
        </FixedSizeList>
      </div>
    );
  }
);

interface virtualizedAutocompleteProps {
  label: string;
  options: any[];
  selectField: string;
  selected: number;
  required?: boolean;
  error?: boolean;
  onSelect: (id: number) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const VirtualizedAutocomplete: React.FC<virtualizedAutocompleteProps> = (props: virtualizedAutocompleteProps) => {
  const [inputValue, setInputValue] = React.useState("");

  const {
    label,
    options,
    selectField,
    selected,
    required = false,
    error = false,
    onSelect,
    onBlur = undefined,
  } = props;

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option[selectField].toLowerCase().includes(inputValue.toLowerCase())
      ),
    [inputValue, options, selectField]
  );

  return (
    <Autocomplete
      options={filteredOptions}
      getOptionLabel={(option) => option[selectField]}
      value={selected > 0 ? options.find(item => item.id === selected) : null}
      onChange={(_, value) => {
        if (value) onSelect(value.id);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onChange={(e) => setInputValue(e.target.value)}
        />
      )}
      renderOption={() => null} // Disable default rendering
      ListboxComponent={(props) => ( // missing forwardRef! but cannot get it to work
        <VirtualizedList
          {...props}
          options={filteredOptions}
          getOptionLabel={(option) => option[selectField]}
          onSelect={(option) => onSelect(option.id)}
        />
      )}
    />
  );
}