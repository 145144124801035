import { Alert, Box, Button, CircularProgress, Paper, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useApi } from '../../../../services/HttpService';
import { InfoArea } from '../../../../components/ui/InfoArea';
import { Member, newMember } from '../../../../model/User';
import { MemberForm, MemberFormRef } from './MemberForm';

interface MemberOverviewProps {
  member: Member;
  getError?: string;
}

export const MemberOverview = (props: MemberOverviewProps) => {
  const { getError } = props
  const { api, error, isLoading } = useApi();
  const addMemberRef = useRef<MemberFormRef>(null);
  const [member, setMember] = useState<Member>(props.member);

  useEffect(() => {
    setMember(props.member);
  }, [props.member]);

  const setDefault = () => {
  }

  const reset = () => {
    setMember({...member}); // triggers props.member change in MemberForm
  }

  const saveSettings = () => {
    const member = addMemberRef.current.getMember();
    console.log("submitting", member, "valid", addMemberRef.current.valid());

    if (addMemberRef.current.valid()) {
      api("/manage/member/postMember", "POST", { ...member }).then(res => {
        console.log("res:", res)
        if (res?.statusCode === 200) {
          setMember(res.member);
        }
      });
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
      <Paper elevation={6} sx={{ width: '100%', maxWidth: { xs: 512, md: 768 }, p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {getError && <Alert sx={{ width: '100%' }} severity="error">{getError}</Alert>}
          <Typography variant='h5' sx={{ mt: 2, textDecoration: 'underline' }}>Kontaktinformasjon</Typography>
          <MemberForm member={member} ref={addMemberRef} error={error} autocomplete={false} />
        </Box>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Button variant='contained' color='info' onClick={setDefault}>Default</Button> */}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <Button variant='contained' color='darkgrey' onClick={reset}>Tilbakestill</Button>
            <Button
              variant='contained'
              color='info'
              onClick={saveSettings}
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={22} />}
            >
              Lagre
            </Button>
          </Box>
        </Box>
      </Paper>

    </Box>
  )
}