import { createTheme, PaletteColor, PaletteColorOptions, PaletteOptions, ThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import { Theme, lighten, darken } from '@mui/material/styles'
import React from 'react';
import { create } from 'zustand';
import { useApi } from '../../services/HttpService';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import localStorageService, { LocalStorageKeys } from '../../services/LocalStorageService';
import { DataGrid } from "@mui/x-data-grid";
import type { } from "@mui/x-data-grid/themeAugmentation";

/**
 * Global theme that is always applied
 */

declare module '@mui/material/styles' {
  interface Palette {
    darkgrey: Palette['primary'];
  }

  interface PaletteOptions {
    darkgrey?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    darkgrey: true;
  }
}

let defaultTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: "#fff",
    },
    darkgrey: {
      light: '#878787',
      dark: '#424242',
      main: '#646464',
      contrastText: '#fff',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTablePagination: {
      // remove margin of labels from TabelPagination
      styleOverrides: {
        selectLabel: {
          margin: 0,
        },
        displayedRows: {
          margin: 0,
        }
      },
    },
    // icon color on list items for DataGrid
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: "inherit",
        }),
      },
    },
    // MuiSvgIcon: { // Makes mui icons responsive to the theme (makes every white or black)
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       color: theme.palette.mode === 'dark' ? 'white' : 'black',
    //     }),
    //   },
    // },
  },
});

type ColorMode = "light" | "dark";

type ThemeState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  setMode: (colorMode: ColorMode) => void;
  updateColors: (primary: string | undefined, secondary: string | undefined, success: string | undefined) => void;
};

// Global theme store, can be used to updated theme from anywhere
export const themeStore = create<ThemeState>((setState) => ({
  theme: defaultTheme,
  setTheme: (theme) => setState((state) => ({ ...state, theme })),
  setMode: (colorMode) => {
    const currentState = themeStore.getState();
    const updatedTheme = createTheme({
      ...currentState.theme,
      palette: {
        ...currentState.theme.palette,
        background: {
          default: colorMode === 'light' ? '#f7f7f7' : '#282c34',
        },
        text: {
          primary: colorMode === 'light' ? '#000' : '#fff',
        },
        mode: colorMode,
      },
    });

    themeStore.setState({ ...currentState, theme: updatedTheme });
    localStorageService.setItem(LocalStorageKeys.COLOR_MODE, colorMode);
  },
  updateColors: (primary, secondary, success) => {
    const currentState = themeStore.getState();
    const updatedTheme = createTheme({
      ...currentState.theme,
      palette: {
        ...currentState.theme.palette,
        primary: {
          main: primary ? primary : currentState.theme.palette.primary.main,
        },
        secondary: {
          main: secondary ? secondary : currentState.theme.palette.secondary.main,
        },
        success: {
          main: success ? success : currentState.theme.palette.success.main,
        },
      },
    });

    themeStore.setState({ ...currentState, theme: updatedTheme });
    localStorageService.setItem(LocalStorageKeys.THEME, { "primary": primary, "secondary": secondary, "success": success });
  }
}));

interface Props { children: JSX.Element | JSX.Element[] }
const BaseTheme = ({ children }: Props) => {
  const [theme, setTheme] = React.useState(themeStore.getState().theme); // ensures rerender of theme when changed

  React.useEffect(() => {
    const unsubscribe = themeStore.subscribe(
      (newState) => {
        setTheme(newState.theme);
      }
    );

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    const theme = localStorageService.getItem(LocalStorageKeys.THEME);
    if (theme) {
      themeStore.getState().updateColors(theme.primary, theme.secondary, theme.success);
    }
  }, []);

  React.useEffect(() => {
    const colorMode = localStorageService.getItem(LocalStorageKeys.COLOR_MODE);
    if (colorMode) {
      themeStore.getState().setMode(colorMode);
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  )
}

export default BaseTheme;