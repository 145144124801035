
import { InfoArea } from '../../components/ui/InfoArea';
import { WeekData, DayHours} from '../../model/Hour';
import HardDeleteDialog from '../../components/modals/dialog/HardDeleteDialog';
import { CenterSpinner } from '../../components/ui/CenterSpinner';
import DeleteIcon from '@mui/icons-material/Delete';
import { SingleDatePicker } from '../../components/ui/SingleDatePicker';
import { ClickableIcon } from '../../components/ui/Icon';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  AlertColor,
  TextField,
  Tooltip,
} from "@mui/material";
import { startOfISOWeek, addDays, format } from "date-fns";
import { useApi } from "../../services/HttpService";
import { nb } from "date-fns/locale";
import { NumberFormat } from '../../components/ui/NumberFormat';

export const HourApproval = () => {
  const [hourRegWeek, setHourRegWeek] = useState<WeekData[]>([]);
  const [currentWeek, setCurrentWeek] = useState<WeekData>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // const { api: hourRegistrationsApi } = useApi();
  const { api: hourRegistrationsApi, response: hourRegistrationsResponse, isLoading: hourRegistrationsIsLoading, error: hourRegistrationsError } = useApi();
  const { api: acceptHourApi } = useApi();
  const { api: saveHourRegApi, response: saveHourRegResponse, isLoading: saveHourRegIsLoading, error: saveHourRegError } = useApi();
  const { api: deleteHourRegApi, response: deleteHourRegResponse, isLoading: deleteHourRegIsLoading, error: deleteHourRegError } = useApi();  
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<AlertColor>("error");
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false); // Dialog open state

  const [editingDayHours, setEditingDayHours] = useState<{
    id: number,
    date: string;
    description: string;
    contactName: string;
    project: string;
    hour: number;
  } | null>(null);

  // Fetch data from the API
  const getHours = () => {
    hourRegistrationsApi("/hour/getHourApprovalData").then((res) => {
      if (res && res.statusCode === 200 && res.data.Weeks) {
        const sortedWeeks = [...(res.data.Weeks || [])].sort((a: WeekData, b: WeekData) => {
          if (b.Year === a.Year) {
            return b.Week - a.Week;
          }
          return b.Year - a.Year;
        });
    
        setHourRegWeek(sortedWeeks);
        if (sortedWeeks.length > 0) {
          setCurrentIndex(0);
          setCurrentWeek(sortedWeeks[0]);
        }
      }
    });
  };

  const deleteHourRegistration = () => {
    deleteHourRegApi("/hour/deleteHourRegistration", "POST", { ...editingDayHours }).then(res => {
      if (res && res.statusCode === 200) {
        getHours();
      }else{
        if (res.error) {
          if (res.error.code === "1") {
            setDisplayMessage("Det er skjedd en feil under oppdatering av timer, prøv igjen");
          } else if (res.error.code === "2") {
            setDisplayMessage("Det er skjedd en feil under oppdatering av timer, prøv igjen");
          } else {
            setDisplayMessage("Feil under oppdatering, prøv igjen");
          }
        }
      }
    });
  }

  const editHourRegistration = () => {
    saveHourRegApi("/hour/editRegistrationHours", "POST", { ...editingDayHours }).then(res => {
      if (res && res.statusCode === 200) {
        getHours();
      }else{
        if (res.error) {
          if (res.error.code === "1") {
            setDisplayMessage("Det er skjedd en feil under oppdatering av timer, prøv igjen");
          } else if (res.error.code === "2") {
            setDisplayMessage("Det er skjedd en feil under oppdatering av timer, prøv igjen");
          } else {
            setDisplayMessage("Feil under oppdatering, prøv igjen");
          }
        }
      }
    });
  }

  useEffect(() => {
    getHours();
  }, []);

  const handlePreviousWeek = () => {
    if (currentIndex < hourRegWeek.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentWeek(hourRegWeek[currentIndex + 1]);
    }
  };

  const handleNextWeek = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentWeek(hourRegWeek[currentIndex - 1]);
    }
  };

  const acceptHours = async () => {
    setConfirmOpen(false); // Close confirmation dialog
    await acceptHourApi("/hour/postHourApproval", "POST", { ...currentWeek }).then((res) => {
      if (res && res.statusCode === 200) {
        setDisplayMessage("Hours approved successfully!", "success");
        setCurrentWeek(null);
        getHours();
      } else {
        setDisplayMessage("Error while approving hours. Please try again.", "error");
      }
    });
  };

  const setDisplayMessage = (message: string, type: AlertColor = "error") => {
    setMessage(message);
    setMessageType(type);
    setTimeout(() => setMessage(""), 5000); // Clear message after 5 seconds
  };

  const getISOWeekDates = (year: number, week: number) => {
    const startOfWeek = startOfISOWeek(new Date(year, 0, 1));
    const firstISODate = addDays(startOfWeek, (week - 1) * 7);
    return Array.from({ length: 7 }, (_, i) =>
      format(addDays(firstISODate, i), "yyyy-MM-dd")
    );
  };

  const handleEdit = (id, date, description ,contactName, project, hour) => {
    setEditingDayHours({id, date, description ,contactName, project, hour });
  };

  const handleIncrement = (amount) => {
    setEditingDayHours((prev) => ({
        ...prev,
        hour: Math.max(0, parseFloat((prev.hour + amount).toFixed(1))),
    }));
  };

  // Handle closing the edit form
  const handleClose = () => {
    setEditingDayHours(null);
  };

  const handleDelete = () => {
    if (editingDayHours) {
      const confirmed = window.confirm("Are you sure you want to delete this entry?");
      if (confirmed){
        deleteHourRegistration()
      } 
    }
    setEditingDayHours(null);
  };

  // Handle saving the changes
  const handleSave = (updatedHours) => {
    if (editingDayHours) {
      // Update the data (you'd also send this to the server here)
      editHourRegistration()
      const { date, contactName, project } = editingDayHours;
      console.log(
        `Saved updated hours for ${contactName} in project ${project} on ${date}:`,
        updatedHours
      );
    }
    setEditingDayHours(null);
  };

  const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);


  return (
    <Box p={3}>
      {/* Week Navigation */}
      <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePreviousWeek}
          disabled={currentIndex === hourRegWeek.length - 1}
        >
          Forrige uke
        </Button>
        {currentWeek && (
          <Typography variant="h6" mx={3}>
            Uke {currentWeek.Week}, {currentWeek.Year}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextWeek}
          disabled={currentIndex === 0}
        >
          Neste uke
        </Button>
      </Box>

      {/* Success/Error Message */}
      {message && <Alert severity={messageType}>{message}</Alert>}

      {/* Table */}
      <CenterSpinner loading={hourRegistrationsIsLoading} />
      {!hourRegistrationsIsLoading && (
      currentWeek ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Prosjekt</strong></TableCell>
                <TableCell><strong>Ansatt</strong></TableCell>
                {getISOWeekDates(currentWeek.Year, currentWeek.Week).map((date) => (
                  <TableCell key={date}>
                    <strong>{capitalizeFirstLetter(format(new Date(date), "EEEE", { locale: nb }))}</strong> {/* Capitalize the first letter */}
                    <br />
                    {date}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {currentWeek.Projects.map((project) =>
              project.Contacts.map((contact) => (
                <TableRow key={`${project.Project}-${contact.ContactName}`}>
                  <TableCell>{project.ProjectName}</TableCell>
                  <TableCell>{contact.ContactName}</TableCell>
                  {getISOWeekDates(currentWeek.Year, currentWeek.Week).map(
                    (date) => {
                      const dayEntry = contact.Days.find(
                        (day) => day.Day === date
                      );
                      const dayName = dayEntry ? dayEntry. Name : null;
                      const hoursForDay = dayEntry ? dayEntry.Hours : null;
                      const idForDay = dayEntry ? dayEntry.id: null;

                      return (
                        <Tooltip title={dayName}>
                        <TableCell
                          key={date}
                          onClick={() =>
                            handleEdit(
                              idForDay || 0,
                              date,
                              dayName,
                              contact.ContactName,
                              project.ProjectName,
                              hoursForDay || 0
                            )
                          }
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          {hoursForDay !== null ? NumberFormat(hoursForDay) : ""}
                        </TableCell>
                        </Tooltip>
                      );
                    }
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" align="center">
          Ingen timer til godkjenning.
        </Typography>
      ))}

      {/* Approve Hours Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setConfirmOpen(true)}
        disabled={!currentWeek}
        sx={{ mt: 2 }}
      >
        Godkjenn timer
      </Button>

      {editingDayHours && (
        <Dialog open onClose={handleClose}>
          <DialogContent>
            <Typography>
              Prosjekt: {editingDayHours.project}
            </Typography>
            <Typography sx={{marginTop:"3px"}}>
              Ansatt: {editingDayHours.contactName}
            </Typography>
            <Typography sx={{marginTop:"3px"}}>
              Beskrivelse: {editingDayHours.description}
            </Typography>
            <Typography sx={{marginBottom:"20px", marginTop:"3px"}}>
              Dato: {editingDayHours.date}
            </Typography>
            <TextField
              fullWidth
              label="Timer"
              type="number"
              value={editingDayHours.hour}
              onChange={(e) =>
                setEditingDayHours({
                  ...editingDayHours,
                  hour: parseFloat(e.target.value) || 0,
                })
              }
              margin="dense"
              sx={{width:"85px"}}
            />
            <Button
              variant="text"
              onClick={() => handleIncrement(1)}
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" }, 
                padding: "20px 8px",
                minWidth: "auto",
                color: "primary", 
                "&:hover": { backgroundColor: "transparent", textDecoration: "underline" } // Optional hover effect
              }}
            >
              +1
            </Button>
            <Button
              variant="text"
              onClick={() => handleIncrement(0.5)}
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" }, 
                padding: "20px 8px",
                minWidth: "auto",
                color: "primary", 
                "&:hover": { backgroundColor: "transparent", textDecoration: "underline" } // Optional hover effect
              }}
            >
              +0,5
            </Button>
            |
            <Button
              variant="text"
              onClick={() => handleIncrement(-0.5)}
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" }, 
                padding: "20px 8px",
                minWidth: "auto",
                color: "primary", 
                "&:hover": { backgroundColor: "transparent", textDecoration: "underline" } // Optional hover effect
              }}
            >
              -0,5
            </Button>
            <Button
              variant="text"
              onClick={() => handleIncrement(-1)}
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" }, 
                padding: "20px 8px",
                minWidth: "auto",
                color: "primary", 
                "&:hover": { backgroundColor: "transparent", textDecoration: "underline" } // Optional hover effect
              }}
            >
              -1
            </Button>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Avbryt
            </Button>
            <Button onClick={() => handleDelete()} 
              color="error"
            >
              Slett
            </Button>
            <Button
              onClick={() => handleSave(editingDayHours.hour)}
              color="primary"
            >
              Lagre
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      >
        <DialogTitle>Bekreft godkjenning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Er du sikker du vil godkjenne timer for uke: {currentWeek?.Week}, {currentWeek?.Year}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="secondary">
            Avbryt
          </Button>
          <Button onClick={acceptHours} color="primary" autoFocus>
            Bekreft
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};
