
export const NumberFormat = (number) => {
    return new Intl.NumberFormat("nb-NO", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

export const PercentFormat = (number) => {
  return new Intl.NumberFormat("nb-NO", {
    maximumFractionDigits: 1
  }).format(number);
};
