import * as React from 'react';
import { DataGrid, DataGridProps, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { styled, SxProps, Theme, ThemeProvider, useTheme } from '@mui/material/styles';
import { MUIStyledCommonProps } from '@mui/system';
import { useRef } from 'react';

const paginationModel = { page: 0, pageSize: 25 };

const StyledDataGrid = styled(DataGrid)(({ theme }: { theme: Theme }) => ({
  "& .MuiDataGrid-sortIcon, \
  & .MuiDataGrid-menuIconButton, \
  & .MuiDataGrid-columnSeparator": {
    color: theme.palette.mode === "dark" ? "white" : "inherit",
  },
  "& .MuiDataGrid-columnHeader, \
  & .MuiDataGrid-scrollbarFiller, \
  & .MuiDataGrid-columnHeaders .MuiDataGrid-filler": {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.light,
  },
}));

interface BaseDataGridTableProps {
  theme?: Theme;
  rows: any[];
  columns: GridColDef[];
  loading?: boolean;
  checkboxSelection?: boolean;
  disableMultipleRowSelection?: boolean;
  onRowClick?: (row: any) => void;
  onRowSelectionModelChange?: (ids: GridRowSelectionModel) => void;
}

// NB:
// Will probably be changed to implement: https://www.material-react-table.com/docs/examples/infinite-scrolling

const BaseDataGridTable = (props: BaseDataGridTableProps) => {
  let theme: Theme = useTheme();
  if (props.theme) {
    theme = props.theme;
  }
  const gridContainerRef = useRef(null);

  return (
    <Paper elevation={4} sx={{height: 400}} ref={gridContainerRef}>
      <ThemeProvider theme={theme}>
        <StyledDataGrid
          sx={{ border: 0 }}
          rows={props.rows}
          columns={props.columns}
          initialState={{ pagination: { paginationModel } }}
          // hideFooterPagination
          // hideFooter
          checkboxSelection={props.checkboxSelection ? true : false}
          disableMultipleRowSelection={props.disableMultipleRowSelection ? true : false}
          density='compact'
          rowHeight={35}
          // getRowHeight={() => 'auto'}

          loading={props.loading}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'linear-progress',
            },
            pagination: {
              labelRowsPerPage: 'Rader per side',
            },
          }}

          onRowClick={(row) => {
            if (props.onRowClick) {
              props.onRowClick(row);
            }
          }}
          onRowSelectionModelChange={(ids: GridRowSelectionModel) => {
            if (props.onRowSelectionModelChange) {
              props.onRowSelectionModelChange(ids);
            }
          }}


        />
      </ThemeProvider>
    </Paper>
  );
}

export default BaseDataGridTable