import React, { useEffect, useRef, useState } from 'react';
import BaseDataGridTable from '../../../../components/tables/BaseDataGridTable';
import { GridColDef } from '@mui/x-data-grid';
import { AppBar, Box, Button, createTheme, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useApi } from '../../../../services/HttpService';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { themeStore } from '../../../../components/themes/BaseTheme';
import { TabPanel, TabProps } from '../../../../components/ui/TabPanel';
import { UserContact } from './UserContact';
import { MemberAccess } from './MemberAccess';
import { MemberOverview } from './MemberOverview';
import ScrollableDataGrid from '../../../../components/tables/ScrollableDataGrid';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import BaseModal from '../../../../components/modals/BaseModal';
import { MemberForm, MemberFormRef } from './MemberForm';
import AddIcon from '@mui/icons-material/Add';
import { SearchBox } from '../../../../components/ui/SearchBox';
import { Member, newMember } from '../../../../model/User';
import { MembershipOverview } from './MembershipOverview';
import { StickyAlert } from '../../../../components/ui/StickyAlert';
import VirtualizedDataGrid from '../../../../components/tables/VirtualizedDataGrid';
import { highlightedGridColumns } from '../../../../components/tables/BaseTable';

const mainColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'member_number', headerName: 'Medlemsnummer', width: 130 },
  { field: 'first_name', headerName: 'Fornavn', width: 130 },
  { field: 'last_name', headerName: 'Etternavn', width: 130 },
  { field: 'name', headerName: 'Fullt navn', width: 160 },
  {
    field: 'email',
    headerName: 'e-post',
    width: 250,
    valueGetter: (_, row) => {
      const emails = row.emails || [];
      const primaryEmail = emails.find((email) => email.primary);
      return primaryEmail ? primaryEmail.name : emails[0]?.name || '';
    },
  },
  {
    field: 'phone',
    headerName: 'Telefon',
    width: 150,
    valueGetter: (_, row) => {
      const phones = row.phones || [];
      const primaryPhone = phones.find((phone) => phone.primary);
      return primaryPhone ? primaryPhone.name : phones[0]?.name || '';
    },
  },
  {
    field: 'address',
    headerName: 'Adresse',
    width: 250,
    valueGetter: (_, row) => {
      const addresses = row.addresses || [];
      const primaryAddress = addresses.find((address) => address.primary);
      return primaryAddress ? primaryAddress.addressline1 : addresses[0]?.addressline1 || '';
    },
  },
];

export const ManageMembers = () => {
  const theme = useTheme();
  const { api, error } = useApi();
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<Member[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  const [searchText, setSearchText] = useState("");
  const [detailedMember, setDetailedMember] = useState<Member>({} as Member);
  const [selectedMember, setSelectedMember] = useState<Member>({} as Member);
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [tabIndex, setTabIndex] = useState(0);
  const [openEditMember, setOpenEditMember] = useState(false);
  const apiRef = useRef<GridApiCommunity>(null);
  const addMemberRef = useRef<MemberFormRef>(null);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    getContacts()
  }, []);

  const getContacts = () => {
    setLoading(true);
    api("/getContacts", "POST", { "primaries": true }).then((res) => {
      if (res) {
        if (res.statusCode === 200) {
          setMembers(res.contacts);
        }
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    handleSearch(searchText);
  }, [members]);

  const handleSearch = (queryText: string) => {
    setLoading(true);
    const query = queryText.toLowerCase();
    setSearchText(query);

    const filteredData = members.filter((row) =>
      mainColumns.some((column) => {

        // Use valueGetter if defined, otherwise use the field directly
        const value = column.valueGetter
          ? column.valueGetter(undefined as never, row, column, apiRef)
          : row[column.field];

        return String(value).toLowerCase().includes(query);
      })
    );

    setFilteredMembers(filteredData);
    setLoading(false);
  };

  const getPageMembers = (page: number, pageSize: number) => {
    if (members.length === 0) {
      return [];
    }
    const sublist = members.slice(page * pageSize, (page + 1) * pageSize);
    console.log("returning members from index", page * pageSize, "-", (page + 1) * pageSize, "count", sublist.length)
    return sublist;
  }

  React.useEffect(() => {
    const selected = members.filter(row => selectedIds.has(row.id));
    console.log("selected member", selected);

    if (selected.length > 0) {
      setSelectedMember(selected[0]);

      api("/getContact", "POST", { "id": selected[0].id, "detailed": true }).then(res => {
        if (res?.statusCode === 200) {
          setDetailedMember(res.contact);
        }
      })
    }
  }, [selectedIds]);

  const handleRowSelectionModelChange = (ids: any) => {
    const newSelectedSet = new Set(ids);
    setSelectedIds(newSelectedSet);
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSubmit = () => {
    const member = addMemberRef.current.getMember();
    console.log("submitting", member, "valid", addMemberRef.current.valid());

    if (addMemberRef.current.valid()) {
      api("/manage/member/postMember", "POST", { ...member }).then(res => {
        console.log("res:", res)
        if (res.statusCode === 200) {
          setOpenEditMember(false);
          getContacts();
        }
      });
    }

  }

  return (
    <>
      <BaseModal
        open={openEditMember}
        title="Ny kontakt"
        onClose={() => { setOpenEditMember(false) }}
        onSubmit={handleSubmit}
        closeOnBackdropClick={false}
      >
        <MemberForm member={newMember()} ref={addMemberRef} error={error} autocomplete={false} />
      </BaseModal>
      {/* <Box sx={{ m: 1, p: 3, pt: 1, height: 400, width: "100%" }}>
        <BaseDataGridTable
          rows={members}
          columns={mainColumns}
          loading={isLoading}
          checkboxSelection
          disableMultipleRowSelection
          onRowSelectionModelChange={(ids) => { handleRowSelectionModelChange(ids) }}
        />
      </Box> */}
      <StickyAlert message={error} severity="error" />
      <Box sx={{ width: '100%', mt: 2, pl: 3, pr: 3, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <SearchBox handleSearch={handleSearch} useDebounce={true} />
        <Button
          variant='contained'
          color='primary'
          sx={{ height: 40 }}
          onClick={() => setOpenEditMember(true)}
          endIcon={<AddIcon />}
        >
          Ny kontakt
        </Button>
        {/* <Button
          variant='contained'
          color='primary'
          sx={{ height: 40 }}
          onClick={() => api("/updateOsloentrepPassword", "POST", {})}
        >
          Encrypt password
        </Button> */}
      </Box>
      <Box sx={{ m: 1, p: 3, pt: 0, height: 400, width: "100%" }}>
        <ScrollableDataGrid
          rows={filteredMembers}
          columns={mainColumns ? highlightedGridColumns(mainColumns, searchText, apiRef) : []}
          loading={loading}
          onPageRequest={getPageMembers}
          checkboxSelection
          disableMultipleRowSelection
          onRowSelectionModelChange={(ids) => { handleRowSelectionModelChange(ids) }}
        />
      </Box>

      {/* THIS MIGHT BE A SOLUTION TO INFINITE SCROLLING  */}
      {/* <Box sx={{ m: 1, p: 3, pt: 0, height: 400, width: "100%" }}>
        <VirtualizedDataGrid
          rows={filteredMembers}
          columns={mainColumns ? highlightedGridColumns(mainColumns, searchText, apiRef) : []}
          loading={loading}
          theme={theme}
        />
      </Box> */}

      <Box sx={{ width: '100%', mt: 2 }}>
        <AppBar position="static"
          sx={{
            backgroundColor: theme.palette.mode === 'dark' ? "inherit" : theme.palette.grey[300],
            color: theme.palette.mode === 'dark' ? "white" : "black"
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            centered={!isSmallScreen}
            variant={isSmallScreen ? "scrollable" : "standard"}
            textColor="inherit"
            indicatorColor="primary"
            aria-label="manage member tabs"
          >
            {["Bruker", "Medlemskap", "Tilgang", "Kontaktinformasjon"].map((name, index) => (
              <Tab key={index} label={name} sx={{ fontWeight: "bold" }} {...TabProps(index)} />
            ))}
          </Tabs>
        </AppBar>
        <Box sx={{ p: 3, mb: "50vh", minHeight: 400 }}>
          {selectedMember?.id ? (
            <Box sx={{ minHeight: 300 }}>
              <Box sx={{ width: '100%', mt: 1, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h6'>Valgt kontakt: {`(${selectedMember.member_number}) ${selectedMember.name}`}</Typography>
              </Box>
              <TabPanel value={tabIndex} index={0} sx={{ minHeight: 200 }}>
                <UserContact contact={selectedMember} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1} sx={{ minHeight: 200 }}>
                <MembershipOverview member={detailedMember} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2} sx={{ minHeight: 200 }}>
                <MemberAccess />
              </TabPanel>
              <TabPanel value={tabIndex} index={3} sx={{ minHeight: 200 }}>
                <MemberOverview member={detailedMember} getError={error} />
              </TabPanel>
            </Box>
          ) : (
            <>
              <Paper elevation={4} sx={{ width: '100%', height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h6'>Ingen kontakt valgt</Typography>
              </Paper>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}